import {FC, createContext, useContext, useState} from 'react'
import {WithChildren} from '../../../../general/helpers'

const initDashboardContextPropsState = {
  filtersType: undefined,
  saveFiltersType: () => {},
  selectedFilterType: undefined,
}
const DashboardContext = createContext<any>(initDashboardContextPropsState)

const useDash = () => {
  return useContext(DashboardContext)
}

const DashboardProvider: FC<WithChildren> = ({children}) => {
  const [filtersType, setFiltersType] = useState()
  const [selectedFilterType, setSelectedFilterType] = useState()
  const [querylistings, setQuerylistings] = useState()
  const [labels, setLabels] = useState()
  const saveSelectedFilterType = (value) => {
    setSelectedFilterType(value)
  }
  const saveFiltersType = (filters_types) => {
    setFiltersType(filters_types)
  }
  const saveQueryListings = (listings) => {
    setQuerylistings(listings)
  }
  const saveLabels = (label) => {
    setLabels(label)
  }

  return (
    <>
      <DashboardContext.Provider
        value={{
          filtersType,
          saveFiltersType,
          selectedFilterType,
          saveSelectedFilterType,
          querylistings,
          saveQueryListings,
          labels,
          saveLabels,
        }}
      >
        <div className='position-relative'>{children}</div>
      </DashboardContext.Provider>
    </>
  )
}
export {useDash, DashboardProvider}
