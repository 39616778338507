import React, {useEffect, useState} from 'react'
import {PageTitle} from '../../../../general/layout/core'
import UsersWrapper from '../users/UsersWrapper'
import {useParams} from 'react-router-dom'
import {get_role_details} from '../core/_request'
import {Role} from '../core/_models'
import RoleCard from './components/RoleCard'
import {useRoles} from './core/Roles'
import Modal from '../components/Modal'
import EditRoleForm from './components/EditRoleForm'
import {useIntl} from 'react-intl'
import {Card} from 'react-bootstrap'
import {getBreadcrumbs} from '../../../routing/Breadcrumbs'

const ViewRole = () => {
  const {roleId} = useParams()
  const intl = useIntl()
  const {itemIdForUpdate, saveItemIdForUpdate} = useRoles()
  const [roleDetails, setRoleDetails] = useState<Role>()
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    fetchRoleDetails()
    //eslint-disable-next-line
  }, [])
  /**
   * fetches details of a role
   */
  const fetchRoleDetails = async () => {
    setLoading(true)
    if (roleId) {
      try {
        const roleObj = await get_role_details(roleId)
        setRoleDetails(roleObj)
      } catch {
      } finally {
        setLoading(false)
      }
    }
  }

  return (
    <>
      <PageTitle breadcrumbs={getBreadcrumbs(intl, 'viewRole')}>
        {intl.formatMessage({id: 'ROLE.DETAILS'})}
      </PageTitle>
      <div className='d-flex flex-column flex-lg-row align-items-start'>
        <div className='flex-column flex-lg-row-auto w-100 w-lg-200px w-xl-300px mb-10'>
          {loading ? (
            <Card>
              <Card.Body>
                <div className='d-flex justify-content-center align-items-center'>
                  <span className='me-2'>Please wait...</span>
                  <span className='spinner-border spinner-border-md align-middle text-primary' />
                </div>
              </Card.Body>
            </Card>
          ) : (
            roleDetails && <RoleCard role={roleDetails} detailView={false} />
          )}
        </div>
        <div className='flex-lg-row-fluid ms-lg-10'>
          <UsersWrapper />
        </div>
      </div>
      {itemIdForUpdate !== undefined && (
        <Modal
          heading={
            itemIdForUpdate === null
              ? intl.formatMessage({id: 'ROLE.ADD'})
              : intl.formatMessage({id: 'ROLE.UPDATE'})
          }
          onClose={() => {
            saveItemIdForUpdate(undefined)
          }}
          size='mw-750px'
        >
          <EditRoleForm onClose={fetchRoleDetails} roleDetails={roleDetails} />
        </Modal>
      )}
    </>
  )
}

export default ViewRole
