import React, {useEffect, useState} from 'react'
import {PageTitle} from '../../../../general/layout/core'
import {FormattedMessage, useIntl} from 'react-intl'
import StoreCard from './components/StoreCard'
import Tabs from '../components/Tabs'
import {useStoresContext} from './core/Stores'
import EditDevicesForm from './components/EditDevicesForm'
import PropertyCard from './components/PropertyCard'
import Modal from '../components/Modal'
import {
  get_store_details,
  get_store_devices,
  get_store_labels,
  get_store_properties,
} from '../core/_request'
import {useNavigate, useParams} from 'react-router-dom'
import CardLoading from '../components/CardLoading'
import EditPropertyForm from './components/EditPropertyForm'
import {Card} from 'react-bootstrap'
import {LabelsArrOfObj, Store, StoreDevice, StoreProperty} from '../core/_models'
import DeleteDevicePropertyForm from './components/DeleteDevicePropertyForm'
import moment from 'moment'
import {getBreadcrumbs} from '../../../routing/Breadcrumbs'
import {useAuth} from '../../auth'
import {PERMISSION_ARRKEY} from '../../auth/core/constant'

const ViewStore = () => {
  const [selectedTab, setSelectedTab] = useState('store_properties')
  const [storeState, setStoreState] = useState<{loading: boolean; details: Store | null}>({
    loading: false,
    details: null,
  })
  const [propertyState, setPropertyState] = useState<{
    loading: boolean
    properties: Array<StoreProperty>
  }>({
    loading: false,
    properties: [],
  })
  const [deviceState, setDeviceState] = useState<{
    loading: boolean
    devices: Array<StoreDevice>
  }>({
    loading: false,
    devices: [],
  })
  const [labelsState, setLabelsState] = useState<{
    loading: boolean
    labels: {[key: string]: string | LabelsArrOfObj}
  }>({loading: false, labels: {}})
  const intl = useIntl()
  const {
    itemDetailsForUpdate,
    saveItemDetailsForUpdate,
    itemDetailsForDelete,
    saveItemDetailsForDelete,
  } = useStoresContext()
  const {storeId} = useParams()
  const {getAllowedPermissionValue} = useAuth()
  const navigate = useNavigate()
  /**\
   * fetches store details
   */
  const fetchStoreDetails = async () => {
    setStoreState((prev) => ({...prev, loading: true}))
    setPropertyState((prev) => ({...prev, loading: true}))
    try {
      const stores = await get_store_details(storeId ?? '')
      setStoreState((prev) => ({...prev, details: stores?.[0]}))
      fetchStoreProperties(stores?.[0]?.refNumber)
      fetchStoreDevices(stores?.[0]?.refNumber)
    } catch {
    } finally {
      setStoreState((prev) => ({...prev, loading: false}))
    }
  }
  /**
   * fetches properties of a store
   * @param refNumber refnumber associated with the store
   */
  const fetchStoreProperties = async (refNumber?: string) => {
    setPropertyState((prev) => ({...prev, loading: true}))
    try {
      const properties = await get_store_properties(
        refNumber ?? storeState?.details?.refNumber ?? ''
      )
      if (Array.isArray(properties)) setPropertyState((prev) => ({...prev, properties}))
    } catch {
    } finally {
      setPropertyState((prev) => ({...prev, loading: false}))
    }
  }
  /**
   * fetches devices of a store
   * @param refNumber refnumber associated with the store
   */
  const fetchStoreDevices = async (refNumber?: string) => {
    setDeviceState((prev) => ({...prev, loading: true}))
    try {
      const devices = await get_store_devices(refNumber ?? storeState?.details?.refNumber ?? '')
      if (Array.isArray(devices)) setDeviceState((prev) => ({...prev, devices}))
    } catch (e) {
    } finally {
      setDeviceState((prev) => ({...prev, loading: false}))
    }
  }
  /**
   * fetching labels for showing store details
   */
  const fetchStoreLabels = async () => {
    setLabelsState((prev) => ({...prev, loading: true}))
    try {
      const response = await get_store_labels()
      setLabelsState((prev) => ({...prev, labels: response?.[0].labels}))
    } catch (e) {
    } finally {
      setLabelsState((prev) => ({...prev, loading: false}))
    }
  }
  const tabsList = [
    {
      id: 'store_properties',
      title: <FormattedMessage id='PROPERTIES' />,
      component: (
        <div className='d-flex flex-column gap-6'>
          {getAllowedPermissionValue(PERMISSION_ARRKEY.ADD_STORE_PROPERTY) && (
            <Card>
              <Card.Body className='d-flex justify-content-center'>
                <button
                  type='button'
                  className='btn btn-clear fw-bold fs-3 text-gray-600 text-hover-primary'
                  onClick={() =>
                    saveItemDetailsForUpdate({
                      name: 'property',
                      values: undefined,
                    })
                  }
                >
                  <FormattedMessage id='ADD.PROPERTY' />
                </button>
              </Card.Body>
            </Card>
          )}
          {propertyState.loading ? (
            <CardLoading />
          ) : (
            propertyState?.properties?.map((property) => (
              <PropertyCard
                details={property}
                type='property'
                key={property.id}
                labels={labelsState.labels?.['properties']?.[0]}
              />
            ))
          )}
        </div>
      ),
    },
    {
      id: 'devices',
      title: <FormattedMessage id='DEVICES' />,
      component: (
        <div className='d-flex flex-column gap-6'>
          {getAllowedPermissionValue(PERMISSION_ARRKEY.ADD_DEVICE) && (
            <Card>
              <Card.Body className='d-flex justify-content-center'>
                <button
                  type='button'
                  className='btn btn-clear fw-bold fs-3 text-gray-600 text-hover-primary'
                  onClick={() =>
                    saveItemDetailsForUpdate({
                      name: 'device',
                      values: undefined,
                    })
                  }
                >
                  <FormattedMessage id='ADD.DEVICE' />
                </button>
              </Card.Body>
            </Card>
          )}
          {deviceState?.loading ? (
            <CardLoading />
          ) : (
            deviceState?.devices?.map((device) => (
              <PropertyCard
                details={device}
                type='device'
                key={device.id}
                labels={labelsState.labels?.['devices']?.[0]}
              />
            ))
          )}
        </div>
      ),
    },
  ]

  const modalTitelId = itemDetailsForUpdate?.values
    ? `EDIT.${itemDetailsForUpdate?.name?.toUpperCase()}`
    : `ADD.${itemDetailsForUpdate?.name?.toUpperCase()}`

  useEffect(() => {
    fetchStoreDetails()
    fetchStoreLabels()
    //eslint-disable-next-line
  }, [])

  return (
    <>
      <PageTitle breadcrumbs={getBreadcrumbs(intl, 'viewStore')}>
        {intl.formatMessage({id: 'STORE.DETAILS'})}
      </PageTitle>

      <div className='d-flex flex-column flex-xl-row'>
        <div className='flex-column flex-lg-row-auto w-100 w-xl-350px mb-10'>
          {storeState.loading ? (
            <CardLoading />
          ) : (
            storeState?.details && (
              <StoreCard storeDetails={storeState.details} labels={labelsState.labels} />
            )
          )}
        </div>
        <div className='flex-lg-row-fluid ms-lg-15'>
          <div className='d-flex justify-content-between align-items-start'>
            <Tabs
              tabsList={tabsList}
              selectedTab={selectedTab}
              onChange={(id: string) => {
                setSelectedTab(id)
              }}
            />
            <button
              className='btn btn-primary'
              onClick={() => {
                const fromStr = moment().utc().startOf('day').toISOString()
                const toStr = moment().utc().endOf('day').toISOString()
                sessionStorage.setItem(
                  'filterQueryStr',
                  `?page=0&size=20&storeRefNumber.equals=${
                    storeState.details?.refNumber
                  }&createdAt.greaterThanOrEqual=${fromStr.substring(
                    0,
                    fromStr.length - 5
                  )}Z&createdAt.lessThan=${toStr.substring(0, fromStr.length - 5)}Z`
                )
                navigate(`/transactions`)
              }}
            >
              {intl.formatMessage({id: 'VIEW.TRANSACTIONS'})}
            </button>
          </div>
          {tabsList?.find((tab) => tab.id === selectedTab)?.component}
        </div>
      </div>
      {itemDetailsForUpdate ? (
        <Modal
          onClose={() => {
            saveItemDetailsForUpdate(null)
          }}
          heading={intl.formatMessage({
            id: modalTitelId,
          })}
        >
          {itemDetailsForUpdate.name === 'property' ? (
            <EditPropertyForm fetchProperties={fetchStoreProperties} />
          ) : (
            <EditDevicesForm fetchDevices={fetchStoreDevices} />
          )}
        </Modal>
      ) : (
        <></>
      )}
      {itemDetailsForDelete ? (
        <Modal
          onClose={() => {
            saveItemDetailsForDelete(null)
          }}
          heading={intl.formatMessage({
            id: `DELETE.${itemDetailsForDelete?.name?.toUpperCase()}`,
          })}
          size='modal-md '
          bodyClasses='p-0'
        >
          <DeleteDevicePropertyForm
            fetchProperties={fetchStoreProperties}
            fetchDevices={fetchStoreDevices}
          />
        </Modal>
      ) : (
        <></>
      )}
    </>
  )
}

export default ViewStore
