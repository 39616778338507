import React from 'react'
import StoresLayout from './StoresLayout'
import {StoresQueryRequestProvider} from './core/StoresRequestProvider'
import {StoresQueryResponseProvider} from './core/StoresResponseProvider'

const StoresWrapper = () => {
  return (
    <StoresQueryRequestProvider>
      <StoresQueryResponseProvider>
        <StoresLayout />
      </StoresQueryResponseProvider>
    </StoresQueryRequestProvider>
  )
}

export default StoresWrapper
