import React from 'react'
import {PageTitle} from '../../../../general/layout/core'
import {useIntl} from 'react-intl'
import ActivitiesWrapper from './ActivitiesWrapper'
import WithPermissionRestricted from '../../../../general/hoc/WithPermissionRestricted'

const ActivitiesPage = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle>{intl.formatMessage({id: 'ACTIVITIES.LOG'})}</PageTitle>
      <ActivitiesWrapper />
    </>
  )
}

export default WithPermissionRestricted(ActivitiesPage, 'ACTIVITIES.LOG', [
  'activity-log',
  'GetAllActivityLogs',
])
