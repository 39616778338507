import React, {useEffect, useMemo, useState} from 'react'
import {PageTitle, usePageData} from '../../../../general/layout/core'
import {useIntl} from 'react-intl'
import {keysforDateFormatValue, separator} from '../core/globals'
import {useParams} from 'react-router-dom'
import {Logistic} from '../core/_models'
import {get_logistic_details, get_logistics_labels, mark_logistics} from '../core/_request'
import {KTCard, KTCardBody} from '../../../../general/helpers'
import CardLoading from '../components/CardLoading'
import {Card} from 'react-bootstrap'
import Select from '../../auth/components/Select'
import Modal from '../components/Modal'
import {useAuth} from '../../auth'
import {getFormattedDateTime, parseJwt} from '../core/helper'
import {PERMISSION_ARRKEY} from '../../auth/core/constant'

const ViewLogistics = () => {
  const intl = useIntl()
  const {provider, logisticId} = useParams()
  const {providers} = usePageData()
  const {auth, currentUser, getAllowedPermissionValue} = useAuth()
  const {activeRetailer} = parseJwt(auth?.accessToken ?? '')
  const [logisticState, setLogisticState] = useState<{loading: boolean; details: Logistic | null}>({
    loading: false,
    details: null,
  })
  const [labelsState, setLabelsState] = useState<{
    loading: boolean
    labels: {[key: string]: string}
  }>({loading: false, labels: {}})
  const [actionLoading, setActionLoading] = useState({pickup: false, return: false})
  const [returnReasonState, setReturnReasonState] = useState({reason: '', isModalOpen: false})
  const [pickupConfirmModal, setPickupConfirmModal] = useState(false)
  const activeProvider = providers?.find((providerItem) => providerItem.apiProperty === provider)
  const breadcrumbs = useMemo(
    () => [
      {
        title: `${activeProvider?.name} - ${intl.formatMessage({id: 'PARCEL.LIST'})}`,
        path: `/logistics/${provider}`,
        isActive: false,
      },
      separator,
    ],
    // eslint-disable-next-line
    [activeProvider]
  )

  /**\
   * fetches logistics details
   */
  const fetchLogisticsDetails = async () => {
    setLogisticState((prev) => ({...prev, loading: true}))
    try {
      const logistics = await get_logistic_details(provider ?? '', logisticId ?? '')
      const obj = logistics?.[0]
      keysforDateFormatValue.logistics.forEach((key) => {
        if (obj?.[key]) {
          obj[key] = getFormattedDateTime(
            activeRetailer?.locale,
            activeRetailer?.timeZone,
            obj[key]
          )
        }
      })
      setLogisticState((prev) => ({...prev, details: logistics?.[0]}))
    } catch {
    } finally {
      setLogisticState((prev) => ({...prev, loading: false}))
    }
  }

  /**
   * fetching labels for showing logistics details
   */
  const fetchLogisticsLabels = async () => {
    setLabelsState((prev) => ({...prev, loading: true}))
    try {
      const response = await get_logistics_labels()
      setLabelsState((prev) => ({...prev, labels: response?.[0].labels}))
    } catch (e) {
    } finally {
      setLabelsState((prev) => ({...prev, loading: false}))
    }
  }

  /**
   * initiates API call for action to be applied
   * @param key key to identify action
   */
  const applyAction = async (key: 'pickup' | 'return') => {
    if (key === 'return' && returnReasonState.reason === '') {
      setReturnReasonState((prev) => ({...prev, isModalOpen: true}))
      return
    }
    setActionLoading((prev) => ({...prev, [key]: true}))
    try {
      let parcel = {
        locationId: logisticState.details?.locationId ?? '',
        parcelBarcode: logisticState.details?.parcelBarcode ?? '',
        orderNumber: logisticState.details?.orderNumber ?? '',
        username: currentUser?.name ?? '',
      }
      if (key === 'return') {
        Object.assign(parcel, {returnReason: returnReasonState.reason})
      }
      await mark_logistics(provider ?? '', key, [parcel])
      fetchLogisticsDetails()
      if (key === 'pickup') {
        setPickupConfirmModal(false)
      } else {
        setReturnReasonState((prev) => ({...prev, reason: '', isModalOpen: false}))
      }
    } catch (e) {
    } finally {
      setActionLoading((prev) => ({...prev, [key]: false}))
    }
  }

  useEffect(() => {
    fetchLogisticsDetails()
    fetchLogisticsLabels()
    //eslint-disable-next-line
  }, [])

  const keysToShow = [
    'locationId',
    'parcelBarcode',
    'status',
    'orderNumber',
    'createdDateTime',
    'deliveryDateTime',
    'checkinDateTime',
    'pickupDateTime',
    'returnDateTime',
    'dropOffDateTime',
    'returnReason',
    'transporterErrorMessage',
  ]

  const returnReasons = [
    'DAMAGED',
    'REFUSED_BY_CUSTOMER',
    'REFUSED_BY_MERCHANT',
    'WRONG_STORE',
    'EXPIRED',
  ]
  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs}>{intl.formatMessage({id: 'PARCEL.DETAILS'})}</PageTitle>
      {logisticState.loading || labelsState.loading ? (
        <CardLoading />
      ) : (
        <KTCard rounded='rounded' border shadow>
          {activeProvider?.name === 'Swisscom Pickup' && (
            <Card.Header>
              <div className='w-100 d-flex justify-content-end align-items-center gap-2'>
                {getAllowedPermissionValue(PERMISSION_ARRKEY.LOGISTICS_PICKUP) && (
                  <button
                    type='button'
                    className='btn btn-md btn-light-primary'
                    onClick={() => {
                      setPickupConfirmModal(true)
                    }}
                  >
                    {intl.formatMessage({id: 'MARK.AS.PICKED.UP'})}
                    {actionLoading.pickup && (
                      <span className='spinner-border spinner-border-sm align-middle ms-2' />
                    )}
                  </button>
                )}
                {getAllowedPermissionValue(PERMISSION_ARRKEY.LOGISTICS_RETURN) && (
                  <button
                    type='button'
                    className='btn btn-md btn-light-primary'
                    onClick={() => {
                      applyAction('return')
                    }}
                  >
                    {intl.formatMessage({id: 'MARK.AS.RETURNED'})}
                    {actionLoading.return && (
                      <span className='spinner-border spinner-border-sm align-middle ms-2' />
                    )}
                  </button>
                )}
              </div>
            </Card.Header>
          )}
          <KTCardBody>
            <div className='d-flex gap-7 flex-wrap'>
              {keysToShow.map((key) => (
                <div className='d-flex flex-column min-w-350px gap-1' key={key}>
                  <div className='fw-bold fs-5'>
                    {labelsState?.labels?.[key]
                      ? intl.formatMessage({
                          id: labelsState?.labels?.[key] ?? '',
                        })
                      : ''}
                  </div>
                  <div className='text-gray-600 fs-6'>{logisticState.details?.[key] ?? 'N/A'}</div>
                </div>
              ))}
            </div>
          </KTCardBody>
        </KTCard>
      )}
      {returnReasonState.isModalOpen && (
        <Modal
          heading={intl.formatMessage({id: 'SELECT.RETURN.REASON'})}
          onClose={() => {
            setReturnReasonState((prev) => ({...prev, reason: '', isModalOpen: false}))
          }}
          primaryAction={
            <button
              type='button'
              className='btn btn-light-primary me-3'
              onClick={() => {
                returnReasonState.reason !== '' && applyAction('return')
              }}
              disabled={returnReasonState.reason === ''}
            >
              {intl.formatMessage({id: 'APPLY'})}
              {actionLoading.return && (
                <span className='spinner-border spinner-border-sm align-middle ms-2' />
              )}
            </button>
          }
        >
          <Select
            attrs={{
              name: intl.formatMessage({id: 'RETURN.REASON'}),
              value: returnReasonState.reason,
            }}
            options={returnReasons.map((reason) => ({
              label: intl.formatMessage({id: reason.replaceAll('_', '.')}),
              value: reason,
            }))}
            onChange={(e) => {
              setReturnReasonState((prev) => ({...prev, reason: e.target.value}))
            }}
            disabled={actionLoading.return}
            placeHolder=''
          />
        </Modal>
      )}
      {pickupConfirmModal && (
        <Modal
          heading={intl.formatMessage({id: 'MARK.AS.PICKED.UP'})}
          onClose={() => {
            setPickupConfirmModal(false)
          }}
          primaryAction={
            <button
              type='button'
              className='btn btn-light me-3'
              onClick={() => {
                setPickupConfirmModal(false)
              }}
            >
              {intl.formatMessage({id: 'NO'})}
            </button>
          }
          secondaryAction={
            <button
              type='button'
              className='btn btn-primary'
              onClick={() => {
                applyAction('pickup')
              }}
            >
              {intl.formatMessage({id: 'YES'})}
              {actionLoading.pickup && (
                <span className='spinner-border spinner-border-sm align-middle ms-2' />
              )}
            </button>
          }
          size='mw-500px'
        >
          <div className='fs-6'>{intl.formatMessage({id: 'PARCEL.PICKUP.CONFIRMATION'})}</div>
        </Modal>
      )}
    </>
  )
}

export default ViewLogistics
