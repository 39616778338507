import React, {useEffect} from 'react'
import {PageTitle, usePageData} from '../../../../general/layout/core'
import {useIntl} from 'react-intl'
import LogisticsWrapper from './LogisticsWrapper'
import {useNavigate, useParams} from 'react-router-dom'
import CardLoading from '../components/CardLoading'
import WithPermissionRestricted from '../../../../general/hoc/WithPermissionRestricted'

const LogisticsPage = () => {
  const intl = useIntl()
  const {provider} = useParams()
  const {providers} = usePageData()
  const navigate = useNavigate()
  const activeProvider = providers?.find((providerItem) => providerItem.apiProperty === provider)
  // navigating back to the first available provider in case active retailer is not in list of providers' retailers
  useEffect(() => {
    if (!activeProvider) {
      if (providers?.[0]) {
        navigate(`/logistics/${providers?.[0]?.apiProperty}`)
      }
    }
    // eslint-disable-next-line
  }, [activeProvider, providers])
  return (
    <>
      <PageTitle>
        {`${activeProvider ? activeProvider?.name + ' - ' : ''}${intl.formatMessage({
          id: 'PARCEL.LIST',
        })}`}
      </PageTitle>
      {activeProvider ? <LogisticsWrapper /> : <CardLoading />}
    </>
  )
}

export default WithPermissionRestricted(LogisticsPage, 'PARCEL.LIST', [
  'logistics',
  'GetAllLogistics',
])
