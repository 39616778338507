// @ts-nocheck
import CustomHeader from '../../../components/header/CustomHeader'
import {Store} from '../../../core/_models'
import {CreatedAtCell} from '../../../users/table/columns/CreatedAtCell'
import LogisticsActionCell from './LogisticsActionCell'

export const logisticsColumns: ReadonlyArray<Column<Store>> = [
  {
    Header: (props) => (
      <CustomHeader tableProps={props} titleId='STORE.NUMBER' className='min-w-125px' />
    ),
    id: 'locationId',
    accessor: 'locationId',
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} titleId='PARCEL.BARCODE' className='min-w-125px' />
    ),
    id: 'parcelBarcode',
    Cell: ({...props}) => <LogisticsActionCell parcel={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <CustomHeader tableProps={props} titleId='STATUS' className='min-w-125px' />,
    id: 'status',
    accessor: 'status',
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} titleId='CREATED.AT' className='min-w-125px' />
    ),
    id: 'createdDateTime',
    Cell: ({...props}) => (
      <CreatedAtCell createdAt={props.data[props.row.index]?.createdDateTime} />
    ),
  },
]
