/* eslint-disable react-hooks/exhaustive-deps */
import {FC, useContext, useState, useEffect, useMemo} from 'react'
import {useQuery} from 'react-query'
import {
  createResponseContext,
  initialQueryResponse,
  QUERIES,
  WithChildren,
} from '../../../../../general/helpers'
import {extractQueryParams, get_activities} from '../../core/_request'
import {ActivitiesResponse} from '../../core/_models'
import {useParams} from 'react-router-dom'
import {useActivitiesQueryRequest} from './ActivitiesRequestProvider'

const QueryResponseContext = createResponseContext<ActivitiesResponse>(initialQueryResponse)
const ActivitiesQueryResponseProvider: FC<WithChildren> = ({children}) => {
  const {state, loading, toggleLoading} = useActivitiesQueryRequest()
  // below state object is altered to maintain the page indexing starting from 0 rather than 1
  const [query, setQuery] = useState<string>(extractQueryParams({...state, page: state.page - 1}))
  const updatedQuery = useMemo(() => extractQueryParams({...state, page: state.page - 1}), [state])
  const {provider} = useParams()

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery)
    }
  }, [updatedQuery])

  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    [`${QUERIES.LOGISTICS_LIST}-${query}`, provider],
    async () => {
      return get_activities(query)
    },
    {
      cacheTime: 0,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      onSettled: () => {
        loading && toggleLoading?.()
      },
      retry: false,
    }
  )

  return (
    <QueryResponseContext.Provider value={{isLoading: isFetching, refetch, response, query}}>
      {children}
    </QueryResponseContext.Provider>
  )
}

const useActivitiesQueryResponse = () => useContext(QueryResponseContext)

const useActivitiesQueryResponseData = () => {
  const {response} = useActivitiesQueryResponse()
  if (!response) {
    return []
  }

  return response.data || []
}

const useActivitiesQueryResponseLoading = (): boolean => {
  const {isLoading} = useActivitiesQueryResponse()
  return isLoading
}

const useActivitiesQueryResponseTotalCount = (): number => {
  const {response} = useActivitiesQueryResponse()
  return response?.totalCounts ?? 0
}

export {
  ActivitiesQueryResponseProvider,
  useActivitiesQueryResponse,
  useActivitiesQueryResponseData,
  useActivitiesQueryResponseLoading,
  useActivitiesQueryResponseTotalCount,
}
