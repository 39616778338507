import React from 'react'
import {RolesProvider} from './core/Roles'
import ViewRole from './ViewRole'

const ViewRoleWrapper = () => {
  return (
    <RolesProvider>
      <ViewRole />
    </RolesProvider>
  )
}

export default ViewRoleWrapper
