import React, {useState} from 'react'
import {KTIcon} from '../../../../../general/helpers'
import {useIntl} from 'react-intl'
import {useActivitiesQueryResponseLoading} from '../core/ActivitiesResponseProvider'
import {useActivitiesQueryRequest} from '../core/ActivitiesRequestProvider'
import Modal from '../../components/Modal'
import Select from '../../../auth/components/Select'
import DatePicker from '../../components/DatePicker'

const ActivitiesListFilters = () => {
  const intl = useIntl()
  const activitiesLoading = useActivitiesQueryResponseLoading()
  const [filtersOpen, setFiltersOpen] = useState<boolean>(false)
  const [filters, setFilters] = useState<{[key: string]: string}>({})
  const {updateState, state} = useActivitiesQueryRequest()

  const getFilterComponent = (filterItem) => {
    if (dateTimeFilterKeys.includes(filterItem.slug)) {
      return (
        <DatePicker
          onChangeFrom={(value) => {
            setFilters((prev) => ({...prev, [`startDate`]: value}))
          }}
          onChangeTo={(value) => {
            setFilters((prev) => ({...prev, [`endDate`]: value}))
          }}
          label={filterItem.name}
          valueFrom={filters?.[`startDate`]}
          valueTo={filters?.[`endDate`]}
        />
      )
    } else if (filterItem.data?.length > 0) {
      return (
        <Select
          label={filterItem.name}
          placeHolder={filterItem.name}
          attrs={{name: filterItem.name, value: filters?.[filterItem.slug] ?? ''}}
          onChange={(e) => setFilters((prev) => ({...prev, [filterItem.slug]: e.target.value}))}
          options={filterItem?.data.map((option) => ({label: option, value: option}))}
          isClearable
          onClear={() => {
            setFilters((prev) => ({...prev, [filterItem.slug]: ''}))
          }}
        />
      )
    }
  }

  /**
   * creates required filter object to fetch filtered activities
   */
  const applyFilters = () => {
    const tempFilters = Object.entries(filters).reduce((prev, curr) => {
      let key = curr?.[0]
      return {
        ...prev,
        [key]: curr?.[1],
      }
    }, {})
    updateState(tempFilters)
    setFiltersOpen(false)
  }
  /**
   * resets all the filters applied
   */
  const resetFilters = () => {
    const tempFilters = {
      page: 1,
      size: state.size,
    }
    updateState({...tempFilters}, false)
    setFilters({})
    setFiltersOpen(false)
  }

  const filterData = [
    {
      name: 'Method',
      slug: 'method',
      data: ['DELETE', 'GET', 'PATCH', 'POST', 'PUT'],
    },
    {
      name: 'Result',
      slug: 'result',
      data: ['failed', 'success'],
    },
    {
      name: 'Action',
      slug: 'action',
      data: ['CREATE', 'DELETE', 'READ', 'UPDATE'],
    },
    {
      name: 'Created At',
      slug: 'createdAt',
      data: null,
    },
  ]
  const dateTimeFilterKeys = ['createdAt']
  return (
    <>
      <button
        disabled={activitiesLoading}
        type='button'
        className='btn btn-light-primary me-3'
        onClick={() => setFiltersOpen((prev) => !prev)}
      >
        <KTIcon iconName='filter' className='fs-2' />
        {intl.formatMessage({id: 'FILTERS'})}
      </button>
      {filtersOpen ? (
        <Modal
          heading={intl.formatMessage({id: 'FILTERS'})}
          onClose={() => {
            setFiltersOpen(false)
          }}
          size='mw-750px'
        >
          <div className='row align-items-center'>
            {filterData.map((filterItem) => (
              <div className='col-md-6 col-sm-12 col-xs-12 mb-8' key={filterItem?.slug}>
                {getFilterComponent(filterItem)}
              </div>
            ))}
            <div className='row gap-5 justify-content-end'>
              <button className={'col-md-2 btn btn-light'} onClick={resetFilters}>
                <span className='indicator-label'>{intl.formatMessage({id: 'RESET'})}</span>
              </button>
              <button
                className={' col-md-2 btn btn-primary'}
                style={{
                  height: '100%',
                  minHeight: 'min-content',
                }}
                onClick={applyFilters}
              >
                {intl.formatMessage({id: 'APPLY'})}
              </button>
            </div>
          </div>
        </Modal>
      ) : (
        <></>
      )}
    </>
  )
}

export default ActivitiesListFilters
