import React, {useEffect, useState} from 'react'
import {PageTitle} from '../../general/layout/core'
import {useIntl} from 'react-intl'

interface Props {
  pageName?: string
  pageTitleId?: string
}

const Report = (props: Props) => {
  const {pageName, pageTitleId} = props
  const intl = useIntl()
  const [modulePresent, setModulePresent] = useState(false)
  const ReportComponent = React.lazy(() => import(`../analytics-setup/pages/${pageName}`))

  useEffect(() => {
    try {
      import(`../analytics-setup/pages/${pageName}`)
        .then(() => {
          setModulePresent(true)
        })
        .catch((error) => {
          setModulePresent(false)
        })
    } catch (error) {
      setModulePresent(false)
    }
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <PageTitle>{pageTitleId ? intl.formatMessage({id: pageTitleId}) : ''}</PageTitle>
      {pageName && (
        <React.Suspense fallback={<></>}>{modulePresent && <ReportComponent />}</React.Suspense>
      )}
    </>
  )
}

export default Report
