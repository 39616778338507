import WithPermissionRestricted from '../../../../general/hoc/WithPermissionRestricted'
import {DashboardProvider} from './Dashboard'
import DashboardLayout from './DashboardLayout'

function DashboardWrapper() {

  return (
    <DashboardProvider>
      <DashboardLayout />
    </DashboardProvider>
  )
}

export default WithPermissionRestricted(DashboardWrapper, 'FILTERS', [
  'transactions',
  'GetAllTransactions',
])
