// @ts-nocheck
import {NavigateFunction} from 'react-router-dom'
import CustomHeader from '../../../components/header/CustomHeader'
import {Store} from '../../../core/_models'
import StoreStatusCell from './StoreStatusCell'
import StoresRefActionCell from './StoresRefActionCell'

export const storeColumns: Column<Store> = (navigate: NavigateFunction) => [
  {
    Header: (props) => (
      <CustomHeader tableProps={props} titleId='STORE.ID' className='min-w-125px' />
    ),
    id: 'id',
    accessor: 'id',
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} titleId='CUSTOMER.STORE.NUMBER' className='min-w-125px' />
    ),
    id: 'refNumber',
    Cell: ({...props}) => <StoresRefActionCell store={props.data[props.row.index]}/>,
  },
  {
    Header: (props) => <CustomHeader tableProps={props} titleId='GLN' className='min-w-125px' />,
    id: 'storeGln',
    accessor: 'storeGln',
  },
  {
    Header: (props) => <CustomHeader tableProps={props} titleId='NAME' className='min-w-125px' />,
    id: 'name',
    accessor: 'name',
  },
  {
    Header: (props) => <CustomHeader tableProps={props} titleId='CITY' className='min-w-125px' />,
    id: 'city',
    accessor: 'city',
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} titleId='STORE.FORMAT' className='min-w-125px' />
    ),
    id: 'storeFormat',
    accessor: 'storeFormat',
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} titleId='OPERATOR.MODEL' className='min-w-125px' />
    ),
    id: 'operatorModel',
    accessor: 'operatorModel',
  },
  {
    Header: (props) => <CustomHeader tableProps={props} titleId='STATUS' className='min-w-125px' />,
    id: 'status',
    Cell: ({...props}) => <StoreStatusCell store={props.data[props.row.index]} />,
  },
]
