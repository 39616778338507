import {FC, useState, createContext, useContext} from 'react'
import {WithChildren} from '../../../../../general/helpers'
import {StoreDevice, StoreProperty} from '../../core/_models'

type EditItemDetails = null | {
  name: 'property' | 'device' | 'deviceSecret'
  values: StoreProperty | StoreDevice | undefined
}

type DeleteItemDetails = null | {
  name: 'property' | 'device' | 'deviceSecret'
  values: StoreProperty | StoreDevice | undefined
}

const StoresContext = createContext<{
  itemDetailsForUpdate: EditItemDetails
  saveItemDetailsForUpdate: (param: EditItemDetails) => void
  itemDetailsForDelete: DeleteItemDetails
  saveItemDetailsForDelete: (param: DeleteItemDetails) => void
}>({
  itemDetailsForUpdate: null,
  saveItemDetailsForUpdate: () => {},
  itemDetailsForDelete: null,
  saveItemDetailsForDelete: () => {},
})

const StoresContextProvider: FC<WithChildren> = ({children}) => {
  const [itemDetailsForUpdate, setItemDetailsForUpdate] = useState<EditItemDetails>(null)
  const [itemDetailsForDelete, setItemDetailsForDelete] = useState<DeleteItemDetails>(null)
  const saveItemDetailsForUpdate = (details: EditItemDetails) => {
    setItemDetailsForUpdate(details)
  }

  const saveItemDetailsForDelete = (details: DeleteItemDetails) => {
    setItemDetailsForDelete(details)
  }

  return (
    <StoresContext.Provider
      value={{
        itemDetailsForUpdate,
        saveItemDetailsForUpdate,
        itemDetailsForDelete,
        saveItemDetailsForDelete,
      }}
    >
      {children}
    </StoresContext.Provider>
  )
}

const useStoresContext = () => useContext(StoresContext)
export {useStoresContext, StoresContextProvider}
