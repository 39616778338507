export const providersSetup = [
  {
    name: 'DPD',
    apiProperty: 'dpdhub',
    allowedRetailers: [1, 2, 3],
  },
  {
    name: 'Pudo (7days)',
    apiProperty: 'pudohub',
    allowedRetailers: [1, 2, 3],
  },
  {
    name: 'Swisscom Pickup',
    apiProperty: 'logisticshub',
    allowedRetailers: [1, 2, 3],
  },
  {
    name: 'UPS',
    apiProperty: 'upshub',
    allowedRetailers: [1, 2, 3],
  },
]
