import React, {useState} from 'react'
import {useStoresContext} from '../core/Stores'
import {
  delete_store_device,
  delete_store_devicesecret,
  delete_store_property,
  get_store_devicessecret,
} from '../../core/_request'
import {useIntl} from 'react-intl'

interface Props {
  fetchProperties: () => void
  fetchDevices: () => void
}

const DeleteDevicePropertyForm = ({fetchProperties, fetchDevices}: Props) => {
  const {itemDetailsForDelete, saveItemDetailsForDelete} = useStoresContext()
  const [loading, setLoading] = useState<boolean>(false)
  const intl = useIntl()
  /**
   * initaites API to delete device, deviceSecret or property
   */
  const deletePropertyDevice = async () => {
    setLoading(true)
    try {
      if (itemDetailsForDelete?.name === 'device') {
        await delete_store_device(itemDetailsForDelete?.values?.id?.toString() ?? '')
        fetchDevices()
      } else if (itemDetailsForDelete?.name === 'deviceSecret') {
        const {id} = await get_store_devicessecret(itemDetailsForDelete?.values?.name ?? '')
        await delete_store_devicesecret(id?.toString() ?? '')
        fetchDevices()
      } else {
        await delete_store_property(itemDetailsForDelete?.values?.id?.toString() ?? '')
        fetchProperties()
      }
      saveItemDetailsForDelete(null)
    } catch {
    } finally {
      setLoading(false)
    }
  }

  return (
    <div>
      <p className='mb-8 text-center h6 fw-normal'>
        {intl.formatMessage(
          {
            id:
              itemDetailsForDelete?.name === 'deviceSecret'
                ? 'STORE.DEVICESECRET.DELETE.CONFIRMATION'
                : 'STORE.PD.DELETE.CONFIRMATION',
          },
          {
            item:
              itemDetailsForDelete?.name === 'deviceSecret'
                ? 'device secret'
                : itemDetailsForDelete?.name,
          }
        )}{' '}
        <b>{itemDetailsForDelete?.values?.name}</b> ?
      </p>
      <div className='d-flex gap-4 justify-content-center'>
        <button
          type='button'
          onClick={() => saveItemDetailsForDelete(null)}
          className='btn btn-light'
          disabled={loading}
        >
          {intl.formatMessage({id: 'NO'})}
        </button>
        <button
          type='button'
          className='btn btn-danger ms-4 d-flex align-items-center'
          onClick={deletePropertyDevice}
          disabled={loading}
        >
          {intl.formatMessage({id: 'DELETE'})}
          {loading ? (
            <span>
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          ) : (
            <></>
          )}
        </button>
      </div>
    </div>
  )
}

export default DeleteDevicePropertyForm
