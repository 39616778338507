/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {ChangeEvent, useMemo, useState} from 'react'
import {perPageArr} from '../core/globals'
import {QueryStateI} from '../../../../general/helpers'
import {FormattedMessage} from 'react-intl'

interface Props {
  totalItems: number
  state: QueryStateI
  updateState: (params: Partial<QueryStateI>, triggerLoading?: boolean) => void
}

const Pagination = (props: Props) => {
  const {totalItems, state, updateState} = props
  const [paginationArray, setPaginationArray] = useState<Array<number>>([1, 2, 3])
  /**
   * handles pagination changes
   * @param pageNum count of page to be fetched
   */
  const handlePagination = (pageNum: number) => {
    updateState?.({...state, page: pageNum})
    if (pageNum > 2) {
      if (Math.ceil(totalItems / state.size) === pageNum) {
        setPaginationArray([pageNum - 2, pageNum - 1, pageNum])
      } else setPaginationArray([pageNum - 1, pageNum, pageNum + 1])
    } else {
      setPaginationArray([1, 2, 3])
    }
  }

  const handleSizeChange = (e: ChangeEvent<HTMLSelectElement>) => {
    updateState?.({...state, page: 1, size: Number(e.target.value)})
    setPaginationArray([1, 2, 3])
  }

  // object for values to display pagination string
  const pageStrObj = useMemo(
    () => ({
      fromIndex: (state.page - 1) * state.size + 1,
      toIndex: totalItems > state.page * state.size ? state.page * state.size : totalItems,
      totalCount: totalItems,
    }),
    [state.page, state.size, totalItems]
  )

  return (
    <div className='row mt-3 gap-4 justify-content-between'>
      <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'>
        <div className='d-flex gap-1 align-items-center'>
          <select
            className='form-select form-select-sm form-select-solid cursor-pointer w-auto'
            onChange={handleSizeChange}
            value={state.size}
          >
            {perPageArr.map((item) => (
              <option className='cursor-pointer' key={item} value={item}>
                {item}
              </option>
            ))}
          </select>
          <span>
            <FormattedMessage id='PAGINATION.STR' values={pageStrObj} />
          </span>
        </div>
      </div>
      <div className='col-sm-12 col-md-6 d-flex align-items-center justify-content-center justify-content-md-end'>
        <div id='kt_table_users_paginate'>
          <ul className='pagination'>
            <li
              className={clsx('page-item', {
                previous: true,
              })}
            >
              <span
                className={clsx('page-link', {
                  disabled: state.page <= 1,
                  'page-text': true,
                  'me-5': true,
                })}
                onClick={() => handlePagination(state.page - 1)}
                style={{cursor: 'pointer'}}
              >
                <i className={clsx('previous', {'bg-secondary': state.page <= 1})}></i>
              </span>
            </li>
            {paginationArray
              .filter((pageListNo) => Math.ceil(totalItems / state.size) >= pageListNo)
              .map((pageListNo: any) => (
                <li
                  className={clsx('page-item', {
                    active: state.page === pageListNo,
                  })}
                  key={pageListNo}
                >
                  <span
                    className={clsx('page-link')}
                    onClick={() => handlePagination(pageListNo)}
                    style={{cursor: 'pointer'}}
                  >
                    {pageListNo}
                  </span>
                </li>
              ))}
            <li
              className={clsx('page-item', {
                next: true,
              })}
            >
              <span
                className={clsx('page-link', {
                  'page-text': true,
                  disabled: Math.ceil(totalItems / state.size) === state.page,
                })}
                onClick={() => handlePagination(state.page + 1)}
                style={{cursor: 'pointer'}}
              >
                <i
                  className={clsx('next', {
                    'bg-secondary': Math.ceil(totalItems / state.size) === state.page,
                  })}
                ></i>
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Pagination
