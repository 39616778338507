import {ReactNode, useEffect} from 'react'
import {KTIcon} from '../../../../general/helpers'

interface ModalProps {
  children: ReactNode
  heading?: string
  onClose: () => void
  primaryAction?: ReactNode
  secondaryAction?: ReactNode
  size?: string
  bodyClasses?: string
}

const Modal = (props: ModalProps) => {
  const {heading, children, onClose, primaryAction, secondaryAction, size, bodyClasses} = props
  useEffect(() => {
    document.body.classList.add('modal-open')
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])

  return (
    <>
      <div
        className='modal fade show d-block'
        id='kt_modal_add_user'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        <div className={`modal-dialog modal-dialog-centered ${size ?? 'mw-650px'}`}>
          <div className='modal-content'>
            <div className='modal-header'>
              <h2 className='fw-bolder'>{heading}</h2>
              <div
                className='btn btn-icon btn-sm btn-active-icon-primary'
                data-kt-users-modal-action='close'
                onClick={onClose}
                style={{cursor: 'pointer'}}
              >
                <KTIcon iconName='cross' className='fs-1' />
              </div>
            </div>
            <div className={`modal-body scroll-y mx-5 ${bodyClasses} my-7`}>{children}</div>
            {(primaryAction || secondaryAction) && (
              <div className='modal-footer'>
                {secondaryAction}
                {primaryAction}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className='modal-backdrop fade show'/>
    </>
  )
}

export default Modal
