import React, {useEffect, useRef, useState} from 'react'
import {useAuth} from '../../auth/core/Auth'
import {useDash} from './Dashboard'
import {get_filters, get_filters_types} from '../core/_request'
import {Card, Dropdown} from 'react-bootstrap'
import FilterComp from './FilterComp'
import {KTCard, KTCardBody, KTIcon, QueryStateI} from '../../../../general/helpers'
import {TransactionsTable} from './table/TransactionsTable'
import clsx from 'clsx'
import ListLoading from './components/loading/ListLoading'
import {PageTitle} from '../../../../general/layout/core'
import Pagination from '../components/Pagination'
import {PaginationI} from '../core/_models'
import {useIntl} from 'react-intl'

function DashboardLayout() {
  const [filter, setFilter] = useState<any>(undefined)
  const [filterQueryStr, setFilterQueryStr] = useState<string>('')
  const [filtersFromSession, setFiltersFromSession] = useState<{
    [name: string]: any
  }>({})
  const [filterLoad, setFilterLoad] = useState<boolean>(false)
  const [totalItems, setTotalItems] = useState(100)
  const [pagination, setPagination] = useState<PaginationI>({
    size: 10,
    page: 1,
  })
  const [tableLoad, setTableLoad] = useState(false)
  const refTransactionsList = useRef(null)
  const {auth, logout} = useAuth()
  const {
    filtersType,
    saveFiltersType,
    saveSelectedFilterType,
    selectedFilterType,
    querylistings,
    saveQueryListings,
  } = useDash()
  const intl = useIntl()

  const GetFiltersType = async () => {
    setFilterLoad(true)
    try {
      const {data} = await get_filters_types(auth?.accessToken)
      saveFiltersType(data)
      saveSelectedFilterType(data.find((_type) => _type.name === 'Advanced'))
    } catch (error) {
      console.log(error)
    }
  }

  const GetFiltersbyId = async () => {
    try {
      const {data} = await get_filters(auth?.accessToken, {type: selectedFilterType?._id})
      data.sort((a, b) => a?.order - b?.order)
      setFilter(data)
      setFilterLoad(false)
    } catch (error) {
      console.log(error)
    }
  }
  const getSelectedFilter = () => {
    if (filter) {
      return filter.filter((e) => e.type._id === selectedFilterType?._id)
    }
    return undefined
  }

  useEffect(() => {
    if (!auth?.accessToken) {
      logout()
      return
    }
    filtersType ?? GetFiltersType()

    let tempQuery = sessionStorage.getItem('filterQueryStr')
    if (tempQuery && tempQuery !== '') {
      setFilterLoad(true)
      setFilterQueryStr(tempQuery)
      const tempFilters: {[name: string]: any} = {}
      tempQuery = tempQuery.slice(1)
      if (tempQuery.includes('&')) {
        const queries = tempQuery.split('&')
        queries.forEach((query: string) => {
          const attributeQuery = query.split('=')?.[0]
          const attribute =
            attributeQuery.includes('.') && !attributeQuery.includes('createdAt')
              ? attributeQuery?.split('.')?.[0]
              : attributeQuery
          if (!tempFilters?.[attribute]) {
            const val = query.split('=')?.[1]
            const decodedString = decodeURIComponent(val)
            tempFilters[attribute] = decodedString ?? ''
          }
        })
      } else {
        const val = tempQuery.split('=')?.[1]
        const attribute = tempQuery.split('=')?.[0]?.split('.')?.[0] ?? ''
        tempFilters[attribute] = decodeURIComponent(val) ?? ''
      }
      setFiltersFromSession({...tempFilters})
    }
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    selectedFilterType?._id && GetFiltersbyId()
    //eslint-disable-next-line
  }, [selectedFilterType])

  return (
    <>
      <PageTitle>{intl.formatMessage({id: 'FILTERS'})}</PageTitle>
      <KTCard>
        <div className='card-header pt-6 border-0 justify-content-end'>
          {filtersType && (
            <Dropdown
              onSelect={(e) => {
                if (selectedFilterType._id === e) return
                setFilterLoad(true)
                saveSelectedFilterType(filtersType.find((_type) => _type._id === e))
                saveQueryListings([])
              }}
              className='w-200px'
            >
              <Dropdown.Toggle
                className='btn btn-light-primary w-100 d-flex align-items-center justify-content-between'
                id='dropdown-basic'
              >
                <span className='d-flex align-items-center'>
                  <KTIcon iconName='filter' className='me-1 fs-2' />
                  {selectedFilterType?.name ?? 'Select'}
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu className='min-w-100'>
                {filtersType.map((single_filter: {slug: string; name: string; _id: string}) => {
                  const {slug, name, _id} = single_filter
                  return (
                    <Dropdown.Item
                      eventKey={_id}
                      key={slug}
                      className={clsx({active: selectedFilterType._id === _id})}
                    >
                      {name}
                    </Dropdown.Item>
                  )
                })}
              </Dropdown.Menu>
            </Dropdown>
          )}
        </div>

        {getSelectedFilter() && !filterLoad ? (
          <KTCardBody className='pt-2'>
            <FilterComp
              filters={getSelectedFilter()}
              setFilterQueryStr={setFilterQueryStr}
              filtersFromSession={filtersFromSession}
              setFiltersFromSession={setFiltersFromSession}
              filterLoad={filterLoad}
              setFilterLoad={setFilterLoad}
              pagination={pagination}
              setPagination={setPagination}
              setTotalItems={setTotalItems}
              setTableLoad={setTableLoad}
              tableLoad={tableLoad}
              listRef={refTransactionsList}
            />
          </KTCardBody>
        ) : (
          <Card>
            <Card.Body>
              <div className='d-flex justify-content-center align-items-center'>
                <span className='me-2'>Please wait...</span>
                <span className='spinner-border spinner-border-md align-middle text-primary' />
              </div>
            </Card.Body>
          </Card>
        )}

        <div ref={refTransactionsList} />
      </KTCard>
      {querylistings?.length > 0 ? (
        <KTCard className='mt-5'>
          <KTCardBody>
            <TransactionsTable filterQueryStr={filterQueryStr} />
            <Pagination
              totalItems={totalItems}
              state={pagination}
              updateState={(obj: Partial<QueryStateI>) => {
                setPagination({page: obj?.page ?? 1, size: obj?.size ?? 10})
              }}
            />
            {tableLoad ? <ListLoading /> : <></>}
          </KTCardBody>
        </KTCard>
      ) : (
        <></>
      )}
    </>
  )
}

export default DashboardLayout
