import {useIntl} from 'react-intl'
import {KTIcon} from '../../../../../../general/helpers'
import {useAuth} from '../../../../auth'
import {useUsers} from '../../Users'
import {UsersListFilter} from './UsersListFilter'
import {PERMISSION_ARRKEY} from '../../../../auth/core/constant'

interface Props {
  loading: boolean
}

const UsersListToolbar = (props: Props) => {
  const {saveItemIdForUpdate} = useUsers()
  const {getAllowedPermissionValue} = useAuth()
  const intl = useIntl()

  const openAddUserModal = () => {
    saveItemIdForUpdate(null)
  }

  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      <UsersListFilter loading={props.loading} />

      {/* begin::Add user */}
      {getAllowedPermissionValue(PERMISSION_ARRKEY.ADD_USER_BTN) && (
        <button type='button' className='btn btn-primary' onClick={openAddUserModal}>
          <KTIcon iconName='plus' className='fs-2' />
          {intl.formatMessage({id: 'ADD.USER'})}
        </button>
      )}
      {/* end::Add user */}
    </div>
  )
}

export {UsersListToolbar}
