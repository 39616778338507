import {useLayoutEffect, useState} from 'react'
import {useFormik} from 'formik'
import {toAbsoluteUrl} from '../../../../general/helpers'
import {useAuth} from '../core/Auth'
import {useNavigate} from 'react-router-dom'
import {resend, validate} from '../core/_requests'

const OTP_LENGTH = 6
const initialValues = {}
export function TwoStepVerification() {
  const navigate = useNavigate()
  const {auth, saveAuth} = useAuth()
  const {email} = auth ?? {}
  const [loading, setLoading] = useState(false)
  const [resendState, setResendState] = useState({loading: false})

  const formik = useFormik({
    initialValues,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setStatus(undefined)
      let otp = ''
      setLoading(true)
      for (let i = 0; i < OTP_LENGTH; i++) {
        const currOtp = values[`code_${i}`]
        otp = otp + (currOtp ?? '')
      }
      if (otp.length === OTP_LENGTH) {
        try {
          const {data: validateotp} = await validate(
            email ?? '',
            parseInt(otp),
            auth?.loginToken ?? ''
          )
          if (validateotp.accessToken) {
            saveAuth(validateotp ?? '')
            navigate(`/transactions`)
          }
        } catch (error: any) {
          for (let index = 0; index < OTP_LENGTH; index++) {
            formik.setFieldValue(`code_${index}`, '')
          }
          const initialInput = document.getElementsByName(`code_0`)[0]
          initialInput?.focus()
        } finally {
          setLoading(false)
          setSubmitting(false)
        }
      } else {
        setStatus('Invalid OTP')
        setLoading(false)
      }
    },
  })

  useLayoutEffect(() => {
    const initialInput = document.getElementsByName(`code_0`)[0]
    initialInput?.focus()
  }, [])

  const handleResend = async () => {
    setResendState({...resendState, loading: true})
    try {
      await resend(email ?? '', auth?.loginToken ?? '')
    } finally {
      setTimeout(() => {
        setResendState({...resendState, loading: false})
      }, 500)
    }
  }
  /**
   * Handles the change event for OTP (One-Time Password) input fields,
   * moving focus to the next input field when the maximum length is reached.
   *
   * @param {number} index - The index of the current input field.
   * @param {Event} event - The change event object.
   */
  const handleInputChange = (index, event) => {
    if (/^[0-9]*$/.test(event.target.value)) {
      formik.handleChange(event)
      const input = event.target
      if (input.value.length === input.maxLength) {
        const nextInputName = `code_${index + 1}`
        const nextInput = document.getElementsByName(nextInputName)[0]

        if (nextInput) {
          nextInput.focus()
        }
      }
    }
  }

  /**
   * Encrypts an email address by masking parts of the username and domain.
   *
   * @param {string} string - The email address to be encrypted.
   * @returns {string} The encrypted email address with masked username and domain.
   */
  const encryptEmail = (string: string) => {
    if (string.trim() === '') {
      return ''
    }
    const [username, domain] = string.split('@')
    const formattedUsername = username.substring(0, 3) + '*'.repeat(username.length - 3)
    const formattedDomain =
      domain.substring(0, 2) + '*'.repeat(domain.length - 5) + domain.slice(-3)
    return formattedUsername + '@' + formattedDomain
  }
  const handlePaste = (e) => {
    const patesDate = e.clipboardData.getData('text')
    if (/^[0-9]*$/.test(patesDate) && patesDate.length === OTP_LENGTH) {
      const value = patesDate.split('')
      for (let index = 0; index < OTP_LENGTH; index++) {
        formik.setFieldValue(`code_${index}`, value[index])
      }
    }
  }
  const keyDownInput = (index, e) => {
    if (e.key === 'Backspace' && e.target.value === '') {
      const prevInputName = `code_${index - 1}`
      const prevInput = document.getElementsByName(prevInputName)[0]

      if (prevInput) {
        prevInput.focus()
      }
    }
  }
  /**
   * Generates input fields for OTP (One-Time Password).
   *
   * @returns {JSX.Element[]} An array of JSX elements representing the OTP input fields.
   */
  const otpSection = (): JSX.Element[] => {
    return [...Array(OTP_LENGTH)].map((e, index) => (
      <div key={index} onPaste={handlePaste}>
        <input
          type='text'
          data-inputmask="'mask': '9', 'placeholder': ''"
          onChange={(e) => handleInputChange(index, e)}
          name={`code_${index}`}
          value={formik.values[`code_${index}`] ?? ''}
          maxLength={1}
          autoComplete='off'
          onKeyDown={(e) => keyDownInput(index, e)}
          className='form-control bg-transparent h-60px w-60px fs-2qx text-center mx-1 my-2'
        />
      </div>
    ))
  }
  const handleDisable = () => {
    let otp = ''
    for (let i = 0; i < OTP_LENGTH; i++) {
      const currOtp = formik.values[`code_${i}`]
      otp = otp + (currOtp ?? '')
    }
    if (otp.length > 5) return false
    else return true
  }

  const otpFragments = otpSection()
  return (
    <form
      className='form w-100 mb-13'
      onSubmit={formik.handleSubmit}
      data-kt-redirect-url='../../demo6/dist/index.html'
      id='kt_sing_in_two_factor_form'
    >
      <div className='text-center mb-10'>
        <img alt='Logo' className='mh-125px' src={toAbsoluteUrl('/media/misc/smartphone-2.svg')} />
      </div>

      <div className='text-center mb-10'>
        <h1 className='text-dark mb-3'>Two-Factor Verification</h1>

        <div className='text-muted fw-semibold fs-5 mb-5'>
          Enter the verification code we sent to
        </div>
        <div className='fw-bold text-dark fs-3'>{encryptEmail(email ?? '')}</div>
      </div>

      <div className='mb-10'>
        <div className='fw-bold text-start text-dark text-center fs-6 mb-1 ms-1'>
          Type your {OTP_LENGTH} digit security code
        </div>

        <div className='d-flex flex-wrap justify-content-center'>
          <div className='d-flex'>{otpFragments.slice(0, 3)}</div>
          <div className='d-flex'>{otpFragments.slice(3)}</div>
        </div>
      </div>
      <div className='mb-10'>
        <div className='d-flex flex-center'>
          <button
            type='submit'
            className='btn btn-primary'
            disabled={handleDisable()}
            id='kt_sing_in_two_factor_submit'
          >
            {!loading && <span className='indicator-label'>Submit</span>}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </div>
      <div className='text-center fw-semibold fs-5'>
        <span className='text-muted me-1'>Didn’t get the code ?</span>
        <span onClick={handleResend} className='link-primary fs-5 me-1' role='button'>
          Resend
        </span>
        {resendState.loading && (
          <span className='spinner-border spinner-border-sm align-middle ms-2 text-primary'></span>
        )}
      </div>
    </form>
  )
}
