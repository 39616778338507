import React from 'react'
import {KTCard, KTCardBody} from '../../../../general/helpers'
import {NoInternetConnection} from '../../../../general/assets/Illustrations/SvgFallbacks'
import {useIntl} from 'react-intl'

const NoInternet = () => {
  const intl = useIntl()
  return (
    <KTCard>
      <KTCardBody>
        <div className='text-center'>
          <NoInternetConnection />
          <h2 className='fs-2x fw-bold mb-0'>{intl.formatMessage({id: 'INTERNET.INTERRUPTED'})}</h2>
          <p className='text-gray-400 fs-4 fw-semibold py-7'>
            {intl.formatMessage({id: 'NO.INTERNET.MESSAGE'})}
          </p>
        </div>
      </KTCardBody>
    </KTCard>
  )
}

export default NoInternet
