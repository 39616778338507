/* eslint-disable react-hooks/exhaustive-deps */
import clsx from 'clsx'
import {KTIcon} from '../../helpers'
import {useLayout} from '../core'
import {Button} from 'react-bootstrap'
import {useAuth} from '../../../app/modules/auth'
import {Languages} from './Languages'
import RetailerSwitch from './RetailerSwitch'

export function HeaderWrapper() {
  const {classes, attributes} = useLayout()
  const {logout} = useAuth()

  return (
    <div
      id='kt_header'
      className={clsx('header', classes.header.join(' '), 'align-items-stretch')}
      {...attributes.headerMenu}
    >
      <div
        className={clsx(
          classes.headerContainer.join(' '),
          'd-flex align-items-stretch justify-content-between'
        )}
      >
        <div className='d-flex align-items-center d-lg-none ms-n1 me-2' title='Show aside menu'>
          <div
            className='btn btn-icon btn-active-color-primary w-30px h-30px w-md-40px h-md-40px'
            id='kt_aside_mobile_toggle'
          >
            <KTIcon iconName='abstract-14' className='fs-1' />
          </div>
        </div>

        <div className={'d-flex align-items-center justify-content-end flex-lg-grow-1 gap-2'}>
          <RetailerSwitch />
          <Languages />
          <Button
            variant='plain'
            size='sm'
            onClick={async () => {
              logout(true)
            }}
            className='p-2'
          >
            Log Out <i className='bi bi-box-arrow-right ms-2' style={{fontSize: '22px'}}></i>
          </Button>
        </div>
      </div>
    </div>
  )
}
