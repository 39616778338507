// @ts-nocheck
import CustomHeader from '../../../components/header/CustomHeader'
import {Activity} from '../../../core/_models'

export const activitiesColumns: Column<Activity> = [
  {
    Header: (props) => <CustomHeader tableProps={props} titleId='NAME' className='min-w-125px' />,
    id: 'name',
    accessor: 'name',
  },
  {
    Header: (props) => <CustomHeader tableProps={props} titleId='PATH' className='min-w-125px' />,
    id: 'path',
    accessor: 'path',
  },
  {
    Header: (props) => <CustomHeader tableProps={props} titleId='RESULT' className='mw-100px' />,
    id: 'result',
    accessor: 'result',
  },

  {
    Header: (props) => (
      <CustomHeader tableProps={props} titleId='CREATED.AT' className='min-w-180px' />
    ),
    id: 'createdAt',
    Cell: ({...props}) => (
      <div>
        {moment(props.data[props.row.index]?.createdAt).utc().format('DD MMM YYYY, hh:mm:ss A')}
      </div>
    ),
  },
]
