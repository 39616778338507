import React from 'react'
import {Logistic} from '../../../core/_models'
import {useNavigate} from 'react-router-dom'
import {useLogisticsQueryRequest} from '../../core/LogisticsRequestProvider'
import {SELECTED_STORAGE_KEYS} from '../../../../auth/core/constant'
interface Props {
  parcel: Logistic
}

const LogisticsActionCell = (props: Props) => {
  const navigate = useNavigate()
  const {state} = useLogisticsQueryRequest()
  return (
    <button
      onClick={() => {
        sessionStorage.setItem(SELECTED_STORAGE_KEYS.LOGISTICS_FILTERS_KEY, JSON.stringify(state))
        navigate(`view/${props.parcel.id}`)
      }}
      className='p-0 border-0 bg-white text-primary fw-bold'
    >
      {props.parcel.parcelBarcode}
    </button>
  )
}

export default LogisticsActionCell
