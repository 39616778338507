import React, {useState} from 'react'
import {useIntl} from 'react-intl'
import {KTIcon} from '../../../../../general/helpers'
import Select from '../../../auth/components/Select'
import {usePermissions} from '../core/Permissions'
import {useQueryRequest} from '../core/PermissionsRequestProvider'
import {sortObjArr} from '../../core/helper'

const PermissionsListFilter = () => {
  const intl = useIntl()
  const {roles} = usePermissions()
  const {state, updateState, loading} = useQueryRequest()
  const [filters, setFilters] = useState<{[key: string]: string}>({})
  /**
   * to apply filters
   */
  const applyFilters = () => {
    updateState({...filters})
  }
  /**
   * to reset filters
   */
  const resetFilters = () => {
    setFilters({})
    const tempFilters = {
      page: 1,
      size: state.size,
    }
    updateState({...tempFilters}, false)
  }
  return (
    <>
      <button
        disabled={loading}
        type='button'
        className='btn btn-light-primary me-3'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        <KTIcon iconName='filter' className='fs-2' />
        {intl.formatMessage({id: 'FILTERS'})}
      </button>
      <div className='menu menu-sub menu-sub-dropdown w-300px w-md-325px' data-kt-menu='true'>
        <div className='px-7 py-5'>
          <div className='fs-5 text-dark fw-bolder'>
            {intl.formatMessage({id: 'FILTER.OPTIONS'})}
          </div>
        </div>
        <div className='separator border-gray-200'></div>
        <div className='px-7 py-5' data-kt-user-table-filter='form'>
          <div className='mb-10'>
            <Select
              labelId='ROLE'
              placeHolderId='ROLE'
              attrs={{name: intl.formatMessage({id: 'ROLE'}), value: filters?.['role'] ?? ''}}
              onChange={(e) => {
                setFilters((prev) => ({...prev, role: e.target.value}))
              }}
              options={sortObjArr(roles, 'name').map((role) => ({
                label: role.name,
                value: role.name,
              }))}
            />
          </div>
          <div className='d-flex justify-content-end'>
            <button
              type='button'
              disabled={loading}
              onClick={resetFilters}
              className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='reset'
            >
              {intl.formatMessage({id: 'RESET'})}
            </button>
            <button
              disabled={loading}
              type='button'
              onClick={applyFilters}
              className='btn btn-primary fw-bold px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='filter'
            >
              {intl.formatMessage({id: 'APPLY'})}
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default PermissionsListFilter
