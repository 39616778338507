/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useRef, useState} from 'react'
import {KTIcon, useDebounce} from '../../../../../../general/helpers'
import {useUsers} from '../../Users'
import ClearCross from '../../../components/ClearCross'

interface Props {
  loading: boolean
}

const UsersListSearchComponent = (props: Props) => {
  const {usersLoading, filters, saveFilters, usersList} = useUsers()
  const [searchTerm, setSearchTerm] = useState<string>()
  const [changePending, setChangePending] = useState<boolean>(false)
  const refSearch = useRef<HTMLInputElement>(null)
  // Debounce search term so that it only gives us latest value ...
  // ... if searchTerm has not been updated within last 500ms.
  // The goal is to only have the API call fire when user stops typing ...
  // ... so that we aren't hitting our API rapidly.
  const debouncedSearchTerm = useDebounce(searchTerm, 500)
  // Effect for API call
  useEffect(
    () => {
      if (debouncedSearchTerm !== undefined && searchTerm !== undefined) {
        setChangePending(true)
        const filtersObj = {...filters}
        if (searchTerm === '') {
          delete filtersObj.search
        } else {
          Object.assign(filtersObj, {search: searchTerm})
        }
        saveFilters({...filtersObj})
      }
    },
    [debouncedSearchTerm] // Only call effect if debounced search term changes
    // More details about useDebounce: https://usehooks.com/useDebounce/
  )

  useEffect(() => {
    if (changePending) {
      refSearch?.current?.focus()
      setChangePending(false)
    }
  }, [usersList])

  useEffect(() => {
    if (filters?.search !== searchTerm) {
      setSearchTerm(filters?.search ? (filters?.search as string) : '')
    }
  }, [filters])

  return (
    <div className='card-title'>
      {/* begin::Search */}
      <div className='d-flex align-items-center position-relative my-1'>
        <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
        <input
          type='text'
          data-kt-user-table-filter='search'
          className='form-control form-control-solid w-250px ps-14'
          placeholder='Search user'
          value={searchTerm ?? ''}
          onChange={(e) => setSearchTerm(e.target.value)}
          disabled={usersLoading || props.loading}
          ref={refSearch}
        />
        {searchTerm && <ClearCross onClick={() => setSearchTerm('')} />}
      </div>
      {/* end::Search */}
    </div>
  )
}

export {UsersListSearchComponent}
