/* eslint-disable react-hooks/exhaustive-deps */
import {FC, useContext, useState, useEffect, useMemo} from 'react'
import {useQuery} from 'react-query'
import {
  createResponseContext,
  initialQueryResponse,
  QUERIES,
  WithChildren,
} from '../../../../../general/helpers'
import {extractQueryParams, get_logistics} from '../../core/_request'
import {LogisticsResponse} from '../../core/_models'
import {useParams} from 'react-router-dom'
import { useLogisticsQueryRequest } from './LogisticsRequestProvider'

const QueryResponseContext = createResponseContext<LogisticsResponse>(initialQueryResponse)
const LogisticsQueryResponseProvider: FC<WithChildren> = ({children}) => {
  const {state, loading, toggleLoading} = useLogisticsQueryRequest()
  // below state object is altered to maintain the page indexing starting from 0 rather than 1
  const [query, setQuery] = useState<string>(extractQueryParams({...state, page: state.page - 1}))
  const updatedQuery = useMemo(() => extractQueryParams({...state, page: state.page - 1}), [state])
  const {provider} = useParams()

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery)
    }
  }, [updatedQuery])

  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    [`${QUERIES.LOGISTICS_LIST}-${query}`,provider],
    async () => {
      return get_logistics(provider ?? '', query)
    },
    {
      cacheTime: 0,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      onSettled: () => {
        loading && toggleLoading?.()
      },
      retry: false,
    }
  )

  return (
    <QueryResponseContext.Provider value={{isLoading: isFetching, refetch, response, query}}>
      {children}
    </QueryResponseContext.Provider>
  )
}

const useLogisticsQueryResponse = () => useContext(QueryResponseContext)

const useLogisticsQueryResponseData = () => {
  const {response} = useLogisticsQueryResponse()
  if (!response) {
    return []
  }

  return response.logistics || []
}

const useLogisticsQueryResponseLoading = (): boolean => {
  const {isLoading} = useLogisticsQueryResponse()
  return isLoading
}

const useLogisticsQueryResponseTotalCount = (): number => {
  const {response} = useLogisticsQueryResponse()
  return response?.totalCounts ?? 0
}

export {
  LogisticsQueryResponseProvider,
  useLogisticsQueryResponse,
  useLogisticsQueryResponseData,
  useLogisticsQueryResponseLoading,
  useLogisticsQueryResponseTotalCount,
}
