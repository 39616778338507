import clsx from 'clsx'
import {useFormik} from 'formik'
import React, {useState} from 'react'
import {useIntl} from 'react-intl'
import * as Yup from 'yup'
import {discardForm} from '../../core/helper'
import {usePermissions} from '../core/Permissions'
import {useQueryResponse, useQueryResponseData} from '../core/PermissionsResponseProvider'
import {update_permission} from '../../core/_request'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'

interface PermissionForm {
  name: string
}

const editPermissionSchema = Yup.object().shape({
  name: Yup.string().min(3, 'Enter minimum 3 characters').required('Permission name is required'),
})

const EditPermissionsForm = () => {
  const intl = useIntl()
  const {itemIdForUpdate, saveItemIdForUpdate} = usePermissions()
  const permissionsList = useQueryResponseData()
  const {refetch} = useQueryResponse()
  const permissionObj = permissionsList.find((ele) => ele.value === itemIdForUpdate)
  const [permissionForEdit] = useState<PermissionForm>({
    ...permissionObj,
    name: permissionObj?.name ?? '',
  })
  const formik = useFormik({
    initialValues: permissionForEdit,
    validationSchema: editPermissionSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        const payload = {
          name: values.name,
          value: permissionObj?.value,
        }
        try {
          await update_permission(permissionObj?.id as string, payload)
        } catch {
        } finally {
          closeModal(true)
        }
      } finally {
        setSubmitting(false)
      }
    },
    validateOnChange: true,
    validateOnMount: true,
  })

  /**
   * closes this modal
   */
  const closeModal = (refresh?: boolean) => {
    if (refresh) {
      refetch()
    }
    saveItemIdForUpdate(undefined)
  }

  /**
   * handles discard action for form
   */
  const discardModal = () => {
    if (formik.dirty) {
      discardForm(() => {
        saveItemIdForUpdate(undefined)
        formik.resetForm()
      })
    } else {
      closeModal()
    }
  }

  return (
    <form
      id='kt_modal_edit_permission_form'
      className='form'
      onSubmit={formik.handleSubmit}
      noValidate
    >
      <div className='d-flex flex-column scroll-y me-n7 ps-1 pe-7'>
        <div className='fv-row mb-7'>
          <label className='fs-6 fw-semibold form-label mb-2'>
            <span className='required'>{intl.formatMessage({id: 'PERMISSION.NAME'})}</span>
            <OverlayTrigger
              placement='right'
              overlay={<Tooltip>Permission name is required to be unique.</Tooltip>}
            >
              <i className='ki-outline ki-information-5 fs-7 ms-2'></i>
            </OverlayTrigger>
          </label>

          <input
            placeholder='Enter a permission name'
            className={clsx(
              'form-control form-control-solid',
              {'is-invalid': formik.touched.name && formik.errors.name},
              {
                'is-valid': formik.touched.name && !formik.errors.name,
              }
            )}
            {...formik.getFieldProps('name')}
            onChange={formik.handleChange}
            value={formik.values.name}
            disabled={formik.isSubmitting}
          />
          {formik.touched.name && formik.errors.name && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.name}</span>
              </div>
            </div>
          )}
        </div>

        <div className='text-center pt-15'>
          <button
            type='button'
            className='btn btn-light me-3'
            data-kt-roles-modal-action='cancel'
            onClick={() => {
              discardModal()
            }}
          >
            {intl.formatMessage({id: 'DISCARD'})}
          </button>
          <button
            type='submit'
            className='btn btn-primary'
            data-kt-roles-modal-action='submit'
            disabled={formik.isSubmitting || !formik.isValid}
          >
            {formik.isSubmitting ? (
              <span>
                {intl.formatMessage({id: 'PLEASE.WAIT'})}{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            ) : (
              <span className='indicator-label'>{intl.formatMessage({id: 'SUBMIT'})}</span>
            )}
          </button>
        </div>
      </div>
    </form>
  )
}

export default EditPermissionsForm
