import {FC, useState, createContext, useContext} from 'react'
import {QueryState, WithChildren, QueryStateI} from '../../../../../general/helpers'

interface QueryRequestContextI {
  state: QueryStateI
  updateState: (updates: Partial<QueryStateI>, persistOldValues?: boolean) => void
  loading?: boolean
  toggleLoading?: () => void
}

const QueryRequestContext = createContext<QueryRequestContextI>({
  state: {page: 1, size: 10},
  updateState: () => {},
  loading: false,
  toggleLoading: () => {},
})

const ActivitiesQueryRequestProvider: FC<WithChildren> = ({children}) => {
  const [state, setState] = useState<QueryStateI & {[key: string]: string | number}>({
    page: 1,
    size: 10,
  })
  const [loading, setLoading] = useState<boolean>(true)

  const updateState = (updates: Partial<QueryState>, persistOldValues: boolean = true) => {
    let updatedState = {...state, ...updates} as QueryStateI & {[key: string]: string | number}
    if (!persistOldValues) {
      updatedState = {...updates} as QueryStateI & {[key: string]: string | number}
    }
    setState(updatedState)
  }

  const toggleLoading = () => setLoading((prev) => !prev)

  return (
    <QueryRequestContext.Provider value={{state, updateState, loading, toggleLoading}}>
      {children}
    </QueryRequestContext.Provider>
  )
}

const useActivitiesQueryRequest = () => useContext(QueryRequestContext)
export {ActivitiesQueryRequestProvider, useActivitiesQueryRequest}
