/* eslint-disable react-hooks/exhaustive-deps */
import {FC, useContext, useState, useEffect, useMemo} from 'react'
import {useQuery} from 'react-query'
import {
  createResponseContext,
  initialQueryResponse,
  QUERIES,
  WithChildren,
} from '../../../../../general/helpers'
import {useQueryRequest} from './PermissionsRequestProvider'
import {extractQueryParams, get_permissions} from '../../core/_request'
import {PermissionsResponse} from '../../core/_models'

const QueryResponseContext = createResponseContext<PermissionsResponse>(initialQueryResponse)
const QueryResponseProvider: FC<WithChildren> = ({children}) => {
  const {state, loading, toggleLoading} = useQueryRequest()
  const [query, setQuery] = useState<string>(extractQueryParams(state))
  const updatedQuery = useMemo(() => extractQueryParams(state), [state])

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery)
    }
  }, [updatedQuery])

  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    `${QUERIES.PERMISSIONS_LIST}-${query}`,
    () => {
      return get_permissions(query)
    },
    {
      cacheTime: 0,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      onSettled: () => {
        loading && toggleLoading?.()
      },
      retry: false,
    }
  )

  return (
    <QueryResponseContext.Provider value={{isLoading: isFetching, refetch, response, query}}>
      {children}
    </QueryResponseContext.Provider>
  )
}

const useQueryResponse = () => useContext(QueryResponseContext)

const useQueryResponseData = () => {
  const {response} = useQueryResponse()
  if (!response) {
    return []
  }

  return response.permissions || []
}

const useQueryResponseLoading = (): boolean => {
  const {isLoading} = useQueryResponse()
  return isLoading
}

const useQueryResponseTotalCount = (): number => {
  const {response} = useQueryResponse()
  return response?.totalCounts ?? 0
}

export {
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponseData,
  useQueryResponseLoading,
  useQueryResponseTotalCount,
}
