import React from 'react'
import {usePermissions} from '../../core/Permissions'
import {badgeClasses} from '../../../core/globals'
import {Link} from 'react-router-dom'

interface Props {
  rolesAssigned: {id: string; name: string}[]
}

const PermissionsAssignedCell = (props: Props) => {
  const {rolesAssigned} = props
  const {badgesData} = usePermissions()

  return rolesAssigned.map((item, ind) => (
    <Link
      key={item.id}
      className={`badge badge-light-${badgesData?.[item.id] ?? badgeClasses[ind]} fs-7 m-1`}
      to={`/users-management/roles/view/${item.id}`}
    >
      {item.name}
    </Link>
  ))
}

export default PermissionsAssignedCell
