import {AuthModel} from './_models'
import {SELECTED_STORAGE, SELECTED_STORAGE_KEYS} from './constant'

const {AUTH_LOCAL_STORAGE_KEY} = SELECTED_STORAGE_KEYS
const getAuth = (): AuthModel | undefined => {
  if (!SELECTED_STORAGE) {
    return
  }

  const lsValue: string | null = SELECTED_STORAGE.getItem(AUTH_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return
  }

  try {
    const auth: AuthModel = JSON.parse(lsValue) as AuthModel
    if (auth) {
      // You can easily check auth_token expiration also
      return auth
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
  }
}

const setAuth = (auth: AuthModel) => {
  if (!SELECTED_STORAGE) {
    return
  }

  try {
    const lsValue = JSON.stringify(auth)
    SELECTED_STORAGE.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
  }
}

const removeAuth = () => {
  if (!SELECTED_STORAGE) {
    return
  }

  try {
    SELECTED_STORAGE.removeItem(AUTH_LOCAL_STORAGE_KEY)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
  }
}

export {getAuth, setAuth, removeAuth, AUTH_LOCAL_STORAGE_KEY}
