import axios, {AxiosResponse} from 'axios'
import {
  PermissionSchema,
  Role,
  RolesResponse,
  UsersByRoleResponse,
  UsersResponse,
  PermissionsResponse,
  StoresResponse,
  Store,
  FilterI,
  LabelsResponse,
  StoreProperty,
  StoreDevice,
  DeviceSecretResponse,
  LogisticsProvider,
  LogisticsResponse,
  Logistic,
  LabelsResponseI,
  TransactionsResponse,
  ActivitiesResponse,
  Retailer,
  ActivityLabelsResponseI,
} from './_models'

const API_URL = process.env.REACT_APP_API_URL

export const RETAILERS = `${API_URL}/auth/retailers`

export const T_FILTER = `${API_URL}/transactions/filters`
export const T_FILTER_TYPES = `${API_URL}/transactions/filter-types`
export const T_QUERY = `${API_URL}/transactions/query`
export const T_DETAILS = `${API_URL}/transactions/:id/details`
export const T_EXPORT = `${API_URL}/reports/export`
export const T_LABELS = `${API_URL}/transactions/labels`

export const U_LISTING = `${API_URL}/users`
export const U_LISTING_ROLES = `${API_URL}/roles/:roleId/users`
export const U_CREATE = `${API_URL}/users/invite`
export const U_UPDATE = `${API_URL}/users/<id>`
export const U_RESEND_INVITE = `${API_URL}/users/invite/resend`
export const U_SWITCH_RETAILER = `${API_URL}/users/switch-retailer/:retailerId`

export const R_LISTING = `${API_URL}/roles`
export const R_DETAIL = `${API_URL}/roles/:roleId`

export const P_SCHEMA = `${API_URL}/permissions`
export const P_LISTING = `${API_URL}/permissions/list`
export const P_UPDATE = `${API_URL}/permissions/:permissionId`

export const S_LISTING = `${API_URL}/stores`
export const S_EXPORT = `${API_URL}/stores/export`
export const S_DETAILS = `${API_URL}/stores/:storeId/details`
export const S_FILTERS = `${API_URL}/stores/filters`
export const S_FILTER_LABELS = `${API_URL}/stores/labels`
export const S_PROPERTIES_LISTING = `${API_URL}/stores/:storeRefNumber/properties`
export const S_PROPERTY_CREATE = `${API_URL}/stores/properties`
export const S_PROPERTY_UPDATE = `${API_URL}/stores/:propertyId/properties`
export const S_DEVICES_LISTING = `${API_URL}/stores/:storeRefNumber/devices`
export const S_DEVICE_CREATE = `${API_URL}/stores/device`
export const S_DEVICE_UPDATE = `${API_URL}/stores/:deviceId/device`
export const S_DEVICESECRET_NAME = `${API_URL}/stores/device-secret/:deviceId`
export const S_UPDATE_DEVICESECRET = `${API_URL}/stores/device-secret`

export const PROVIDERS = `${API_URL}/logistics/providers`

export const L_LISTING = `${API_URL}/logistics/query`
export const L_EXPORT = `${API_URL}/logistics/export`
export const L_FILTERS = `${API_URL}/logistics/filters`
export const L_LABELS = `${API_URL}/logistics/labels`
export const L_MARK = `${API_URL}/logistics/parcels/`
export const L_DETAILS = `${API_URL}/logistics/:logisticId/details`

export const A_LISTING = `${API_URL}/activity-log/logs/query`
export const A_LABELS = `${API_URL}/activity-log/labels`
export const A_EXPORT = `${API_URL}/activity-log/export`

export const extractQueryParams = (obj: Object) => {
  const filterArr = Object.entries(obj).filter((item) => item?.[1] || item?.[1] === 0)
  const queryStr = filterArr.reduce((prev, curr, ind) => {
    const suffix = ind < filterArr.length - 1 ? '&' : ''
    return `${prev}${curr[0]}=${curr[1]}${suffix}`
  }, '')
  return queryStr !== '' ? `${queryStr}` : queryStr
}

export function get_filters(token, params: {type: string}) {
  return axios.get(T_FILTER, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params,
  })
}
export function get_filters_types(token) {
  return axios.get(T_FILTER_TYPES, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}
export function get_transaction_query(token, payload): Promise<TransactionsResponse> {
  return axios
    .get(T_QUERY + payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((d: AxiosResponse<TransactionsResponse>) => d.data)
}
export function get_labels(token) {
  return axios.get(T_LABELS, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

export const export_transactions = (
  filters?: string,
  headers: {[key: string]: string} = {}
): Promise<any> => {
  return axios
    .get(T_EXPORT + `${filters ? '?' + filters : ''}`, {headers})
    .then((d: AxiosResponse<any>) => d.data)
}

export const get_users = (token, filters: Object = {}): Promise<UsersResponse> => {
  return axios
    .get(U_LISTING, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: filters,
    })
    .then((d: AxiosResponse<UsersResponse>) => d.data)
}

export const get_users_by_roles = (
  token,
  roleId: string,
  filters: Object = {}
): Promise<UsersByRoleResponse> => {
  const endpoint = U_LISTING_ROLES.replace(':roleId', roleId)
  return axios
    .get(endpoint, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: filters,
    })
    .then((d: AxiosResponse<UsersByRoleResponse>) => d.data)
}

export const get_retailers = (): Promise<Array<Retailer>> => {
  return axios.get(RETAILERS).then((d: AxiosResponse<Array<Retailer>>) => d.data)
}

export const create_user = (
  token,
  payload: {name: string; email: string; role: string}
): Promise<any> => {
  return axios
    .post(U_CREATE, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((d: AxiosResponse<UsersResponse>) => d.data)
}

export const update_user = (token, id: string, payload: any): Promise<any> => {
  const endpoint = U_UPDATE.replace('<id>', id)
  return axios
    .patch(endpoint, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((d: AxiosResponse<UsersResponse>) => d.data)
}

export const delete_user = (id: string): Promise<any> => {
  const endpoint = U_UPDATE.replace('<id>', id)
  return axios.delete(endpoint).then((d: AxiosResponse<any>) => d.data)
}

export const resend_invite = (payload: {email: string}): Promise<any> => {
  return axios.post(U_RESEND_INVITE, payload).then((d: AxiosResponse<UsersResponse>) => d.data)
}

export const switch_retailer = (retailerId: string): Promise<any> => {
  const endpoint = U_SWITCH_RETAILER.replace(':retailerId', retailerId)
  return axios.get(endpoint).then((d: AxiosResponse<any>) => d.data)
}

export const get_roles = (filters?: string): Promise<RolesResponse> => {
  return axios
    .get(R_LISTING + `${filters ? '?' + filters : ''}`)
    .then((d: AxiosResponse<RolesResponse>) => d.data)
}

export const get_role_details = (roleId: string): Promise<Role> => {
  const endpoint = R_DETAIL.replace(':roleId', roleId)
  return axios.get(endpoint).then((d: AxiosResponse<Role>) => d.data)
}

export const create_role = (payload: Object): Promise<any> => {
  return axios.post(R_LISTING, payload).then((d: AxiosResponse<any>) => d.data)
}

export const update_role = (roleId: string, payload: Object): Promise<any> => {
  const endpoint = R_DETAIL.replace(':roleId', roleId)
  return axios.patch(endpoint, payload).then((d: AxiosResponse<UsersResponse>) => d.data)
}

export const get_permission_schema = (): Promise<PermissionSchema> => {
  return axios.get(P_SCHEMA).then((d: AxiosResponse<PermissionSchema>) => d.data)
}

export const get_permissions = (filters?: string): Promise<PermissionsResponse> => {
  return axios
    .get(P_LISTING + `${filters ? '?' + filters : ''}`)
    .then((d: AxiosResponse<PermissionsResponse>) => d.data)
}

export const update_permission = (id: string, payload: any): Promise<any> => {
  const endpoint = P_UPDATE.replace(':permissionId', id)
  return axios.patch(endpoint, payload).then((d: AxiosResponse<UsersResponse>) => d.data)
}

export const get_stores = (filters?: string): Promise<StoresResponse> => {
  return axios
    .get(S_LISTING + `${filters ? '?' + filters : ''}`)
    .then((d: AxiosResponse<StoresResponse>) => d.data)
}

export const export_stores = (filters?: string): Promise<any> => {
  return axios
    .get(S_EXPORT + `${filters ? '?' + filters : ''}`)
    .then((d: AxiosResponse<any>) => d.data)
}

export const get_store_details = (storeId: string): Promise<Array<Store>> => {
  const endpoint = S_DETAILS.replace(':storeId', storeId)
  return axios.get(endpoint).then((d: AxiosResponse<Array<Store>>) => d.data)
}

export const get_store_filters = (): Promise<Array<FilterI>> => {
  return axios.get(S_FILTERS).then((d: AxiosResponse<Array<FilterI>>) => d.data)
}

export const get_store_labels = (): Promise<LabelsResponse[]> => {
  return axios.get(S_FILTER_LABELS).then((d: AxiosResponse<LabelsResponse[]>) => d.data)
}

export const get_store_properties = (storeRefNumber: string): Promise<Array<StoreProperty>> => {
  const endpoint = S_PROPERTIES_LISTING.replace(':storeRefNumber', storeRefNumber)
  return axios.get(endpoint).then((d: AxiosResponse<Array<StoreProperty>>) => d.data)
}

export const create_property = (payload: {
  name: string
  value: string
  sensitiveData: boolean
  store: string
  startDate: string
}): Promise<any> => {
  return axios.post(S_PROPERTY_CREATE, payload).then((d: AxiosResponse<any>) => d.data)
}

export const delete_store_property = (propertyId: string): Promise<any> => {
  const endpoint = S_PROPERTY_UPDATE.replace(':propertyId', propertyId)
  return axios.delete(endpoint).then((d: AxiosResponse<any>) => d.data)
}

export const update_store_property = (
  propertyId: string,
  payload: {name: string; value: string; sensitiveData: boolean}
): Promise<any> => {
  const endpoint = S_PROPERTY_UPDATE.replace(':propertyId', propertyId)
  return axios.patch(endpoint, payload).then((d: AxiosResponse<any>) => d.data)
}

export const get_store_devices = (storeRefNumber: string): Promise<Array<StoreDevice>> => {
  const endpoint = S_DEVICES_LISTING.replace(':storeRefNumber', storeRefNumber)
  return axios.get(endpoint).then((d: AxiosResponse<Array<StoreDevice>>) => d.data)
}

export const create_device = (payload: {
  name: string
  type: string
  suspended: boolean
  store: string
  deviceSecret: string
}): Promise<any> => {
  return axios.post(S_DEVICE_CREATE, payload).then((d: AxiosResponse<any>) => d.data)
}

export const update_store_device = (
  deviceId: string,
  payload: {name: string; type: 'POS' | 'WEBPOS'; suspended: boolean}
): Promise<any> => {
  const endpoint = S_DEVICE_UPDATE.replace(':deviceId', deviceId)
  return axios.patch(endpoint, payload).then((d: AxiosResponse<any>) => d.data)
}

export const delete_store_device = (deviceId: string): Promise<any> => {
  const endpoint = S_DEVICE_UPDATE.replace(':deviceId', deviceId)
  return axios.delete(endpoint).then((d: AxiosResponse<any>) => d.data)
}

export const get_store_devicessecret = (name: string): Promise<DeviceSecretResponse> => {
  const endpoint = S_DEVICESECRET_NAME.replace(':deviceId', name)
  return axios.get(endpoint).then((d: AxiosResponse<DeviceSecretResponse>) => d.data)
}

export const update_store_devicesecret = (payload: {
  id: string
  deviceName: string
  deviceSecret: string
  deviceId: string
  enabled: boolean
}): Promise<any> => {
  return axios.put(S_UPDATE_DEVICESECRET, payload).then((d: AxiosResponse<any>) => d.data)
}

export const delete_store_devicesecret = (deviceId: string): Promise<any> => {
  const endpoint = S_DEVICESECRET_NAME.replace(':deviceId', deviceId)
  return axios.delete(endpoint).then((d: AxiosResponse<any>) => d.data)
}

export const get_providers = (): Promise<Array<LogisticsProvider>> => {
  return axios.get(PROVIDERS).then((d: AxiosResponse<Array<LogisticsProvider>>) => d.data)
}

export const get_logistics = (provider: string, filters?: string): Promise<LogisticsResponse> => {
  return axios
    .get(L_LISTING + `${filters ? '?' + filters : ''}`, {
      headers: {provider},
    })
    .then((d: AxiosResponse<LogisticsResponse>) => d.data)
}

export const get_logistics_filters = (): Promise<Array<FilterI>> => {
  return axios.get(L_FILTERS).then((d: AxiosResponse<Array<FilterI>>) => d.data)
}

export const get_logistics_labels = (): Promise<LabelsResponseI[]> => {
  return axios.get(L_LABELS).then((d: AxiosResponse<LabelsResponseI[]>) => d.data)
}

export const export_logistics = (provider: string, filters?: string): Promise<any> => {
  return axios
    .get(L_EXPORT + `${filters ? '?' + filters : ''}`, {
      headers: {provider},
    })
    .then((d: AxiosResponse<any>) => d.data)
}

export const mark_logistics = (
  provider: string,
  action: string,
  parcels: Array<{[key: string]: string}>
): Promise<any> => {
  return axios
    .post(
      L_MARK + action,
      {items: parcels},
      {
        headers: {provider},
      }
    )
    .then((d: AxiosResponse<any>) => d.data)
}

export const get_logistic_details = (
  provider: string,
  logisticId: string
): Promise<Array<Logistic>> => {
  const endpoint = L_DETAILS.replace(':logisticId', logisticId)
  return axios
    .get(endpoint, {
      headers: {provider},
    })
    .then((d: AxiosResponse<Array<Logistic>>) => d.data)
}

export const get_activities = (filters?: string): Promise<ActivitiesResponse> => {
  return axios
    .get(A_LISTING + `${filters ? '?' + filters : ''}`)
    .then((d: AxiosResponse<ActivitiesResponse>) => d.data)
}

export const get_activities_labels = (): Promise<ActivityLabelsResponseI[]> => {
  return axios.get(A_LABELS).then((d: AxiosResponse<ActivityLabelsResponseI[]>) => d.data)
}

export const export_activities = (filters?: string): Promise<any> => {
  return axios
    .get(A_EXPORT + `${filters ? '?' + filters : ''}`)
    .then((d: AxiosResponse<any>) => d.data)
}

export function GET(endpoint: string, token: string, payload?: any, fullEndpoint?: boolean) {
  let url = fullEndpoint === true ? endpoint : API_URL + '/' + endpoint
  if (payload) url = url + payload
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}
