import {FC} from 'react'
import clsx from 'clsx'
import {useLayout} from '../core'
import {toAbsoluteUrl} from '../../helpers'
import {AsideMenu} from './AsideMenu'

const AsideDefault: FC = () => {
  const {classes} = useLayout()
  return (
    <div
      id='kt_aside'
      className={clsx('aside pb-5 pt-5 pt-lg-0 ', classes.aside.join(' '))}
      data-kt-drawer='true'
      data-kt-drawer-name='aside'
      data-kt-drawer-activate='{default: true, lg: false}'
      data-kt-drawer-overlay='true'
      data-kt-drawer-width="{default:'80px', '300px': '100px'}"
      data-kt-drawer-direction='start'
      data-kt-drawer-toggle='#kt_aside_mobile_toggle'
    >
      {/* begin::Brand */}
      <div className='aside-logo py-8' id='kt_aside_logo'>
        <img
          src={toAbsoluteUrl('/media/logos/smarts_light.png')}
          alt='logo'
          className='h-45px logo'
        />
      </div>
      <div className='aside-menu flex-column-fluid' id='kt_aside_menu'>
        <AsideMenu asideMenuCSSClasses={classes.asideMenu} />
      </div>
    </div>
  )
}

export {AsideDefault}
