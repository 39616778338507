import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {requestPassword} from '../core/_requests'
import {useIntl} from 'react-intl'

const initialValues = {
  email: '',
}

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string().email('Please enter a valid email').required('Email is required'),
})

export function ForgotPassword() {
  const [loading, setLoading] = useState(false)
  const intl = useIntl()
  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, {setSubmitting}) => {
      setLoading(true)
      requestPassword(values.email).finally(() => {
        setSubmitting(false)
        setLoading(false)
      })
    },
  })
  const handleDisable = () => {
    return formik.isSubmitting || !formik.isValid || !formik.values.email
  }
  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_password_reset_form'
      onSubmit={formik.handleSubmit}
    >
      <div className='text-center mb-10'>
        <h1 className='text-dark fw-bolder mb-3'>
          {intl.formatMessage({
            id: 'FORGOT.PASSWORD',
          })}{' '}
          ?
        </h1>
        <div className='text-gray-500 fw-semibold fs-6'>
          {intl.formatMessage({
            id: 'ENTER.EMAIL',
          })}
        </div>
      </div>
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-gray-900 fs-6'>
          {intl.formatMessage({
            id: 'EMAIL',
          })}
        </label>
        <input
          type='email'
          placeholder='Email'
          autoComplete='off'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>
      <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
        <button
          type='submit'
          id='kt_password_reset_submit'
          className='btn btn-primary me-4'
          disabled={handleDisable()}
        >
          {!loading && (
            <span className='indicator-label'>
              {intl.formatMessage({
                id: 'SUBMIT',
              })}
            </span>
          )}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              {intl.formatMessage({
                id: 'WAIT',
              })}
              ...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        <Link to='/auth/login'>
          <button
            type='button'
            id='kt_login_password_reset_form_cancel_button'
            className='btn btn-light'
          >
            Cancel
          </button>
        </Link>
      </div>
    </form>
  )
}
