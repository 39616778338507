// @ts-nocheck

import CustomHeader from '../../../components/header/CustomHeader'
import {Permission} from '../../../core/_models'
import {CreatedAtCell} from '../../../users/table/columns/CreatedAtCell'
import {PermissionsActionsCell} from './PermissionsActionCell'
import PermissionsAssignedCell from './PermissionsAssignedCell'

const permissionColumns: ReadonlyArray<Column<Permission>> = [
  {
    Header: (props) => <CustomHeader tableProps={props} titleId='NAME' className='min-w-125px' />,
    id: 'name',
    accessor: 'name',
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} titleId='ASSIGNED.TO' className='min-w-125px' />
    ),
    id: 'rolesAssigned',
    Cell: ({...props}) => (
      <PermissionsAssignedCell rolesAssigned={props.data[props.row.index].rolesAssigned} />
    ),
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} titleId='CREATED.AT' className='min-w-200px' />
    ),
    id: 'createdAt',
    Cell: ({...props}) => <CreatedAtCell createdAt={props.data[props.row.index]?.createdAt} />,
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} titleId='ACTIONS' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <PermissionsActionsCell id={props.data[props.row.index].value} />,
  },
]

export {permissionColumns}
