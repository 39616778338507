import {FC} from 'react'

type Props = {
  user: any
  col: string
}

const UserInfoCell: FC<Props> = ({user, col}) => {
  return (
    <div className='d-flex align-items-center'>
      <div className='d-flex flex-column'>
        <span>{col}</span>
      </div>
    </div>
  )
}

export {UserInfoCell}
