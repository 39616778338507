import {Retailer} from '../modules/panel/core/_models'

const reportMenuStructure: {
  to: string
  titleId: string
  bsTitleId: string
  hasBullet: boolean
  children: any[]
  pageName?: string
  pageTitleId?: string
  allowedRetailers: number[]
}[] = [
  {
    to: '/billing-report',
    titleId: 'REPORT',
    bsTitleId: 'REPORT',
    hasBullet: true,
    pageName: 'Transactions',
    pageTitleId: 'REPORT',
    allowedRetailers: [],
    children: [],
  },
  {
    to: '/transaction',
    titleId: 'TRANSACTIONS',
    bsTitleId: 'TRANSACTIONS',
    hasBullet: true,
    allowedRetailers: [1, 2, 3],
    children: [
      {
        to: '/transaction/embedded-report',
        titleId: 'REPORT',
        bsTitleId: 'REPORT',
        hasBullet: true,
        pageName: 'Transactions',
        pageTitleId: 'TRANSACTIONS.REPORT',
      },
    ],
  },
  {
    to: '/logistic',
    titleId: 'MENU.LOGISTICS',
    bsTitleId: 'MENU.LOGISTICS',
    hasBullet: true,
    allowedRetailers: [1, 2, 3],
    children: [
      {
        to: '/logistic/provider',
        titleId: 'PROVIDER',
        bsTitleId: 'PROVIDER',
        hasBullet: true,
        children: [
          {
            to: '/logistic/provider/embedded-report',
            titleId: 'EMBEDDED.REPORT',
            bsTitleId: 'EMBEDDED.REPORT',
            hasBullet: true,
            pageName: 'Transactions',
            pageTitleId: 'PROVIDER.REPORT',
          },
        ],
      },
    ],
  },
]
/**
 * creates a report menu structure based on active retailer
 * @param activeRetailer asctive retailer object
 * @returns report menu structure according to active retailers allowed
 */
export const getReportMenuStructure = (activeRetailer?: Retailer) => {
  return activeRetailer
    ? reportMenuStructure.filter((item) => item?.allowedRetailers?.includes(activeRetailer?.id))
    : reportMenuStructure
}
