import React, {useState} from 'react'
import {Card} from 'react-bootstrap'
import {codeToTitle, getFormattedDateTime, parseJwt} from '../../core/helper'
import {KTCard} from '../../../../../general/helpers'
import Accordian from '../../components/Accordian'
import {useIntl} from 'react-intl'
import Modal from '../../components/Modal'
import {keysforDateFormatValue} from '../../core/globals'
import {useAuth} from '../../../auth'

interface DetailSectionI {
  details: any
  title: string
  fields: string[]
  labels: {[name: string]: string}
  detailsLoader: boolean | undefined
}

export default function DetailSection({
  details,
  title,
  fields,
  labels,
  detailsLoader,
  ..._props
}: DetailSectionI) {
  const intl = useIntl()
  const {auth} = useAuth()
  const {activeRetailer} = parseJwt(auth?.accessToken ?? '')
  const [isModalOpen, setIsModalOpen] = useState(false)
  function getValueByPath(obj, path) {
    if (path?.includes('.')) {
      const keys = path?.split('.')
      let value = obj

      for (const key of keys) {
        if (value?.hasOwnProperty(key)) {
          value = value?.[key] ?? 'N/A'
        } else {
          return undefined
        }
      }

      return value ?? 'N/A'
    } else {
      return obj?.[path] ?? 'N/A'
    }
  }

  const fetchFieldValue = (fieldVal: string, index?: string) => {
    const path = Object.keys(labels).find((item) => item === fieldVal)
    let result = getValueByPath(details, index ? path?.replace('.0.', `.${index}.`) : path)
    if (path && keysforDateFormatValue.transaction.includes(path) && result !== 'N/A') {
      result = getFormattedDateTime(activeRetailer?.locale, activeRetailer?.timeZone, result + 'Z')
    }
    return result !== undefined && result.toString()
  }

  const fetchLabel = (fieldVal: string) => {
    return labels[fieldVal]
  }

  const getTabFragment = (index?: string) => (
    <div className='table-responsive'>
      <table
        id='kt_table_users'
        className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
      >
        <tbody className='text-gray-600 fw-bold'>
          {fields.map((field) => {
            return (
              <tr key={field}>
                <td className='fw-bold min-w-120px'>
                  {fetchLabel(field)
                    ? intl.formatMessage({
                        id: fetchLabel(field),
                      })
                    : ''}
                </td>
                <td className='text-end mw-300px'>
                  {field === 'errorMessage' ? (
                    <button
                      className='btn btn-link text-primary'
                      onClick={() => {
                        setIsModalOpen(true)
                      }}
                    >
                      {intl.formatMessage({id: 'VIEW'})}
                    </button>
                  ) : (
                    fetchFieldValue(field, index) ?? 'N/A'
                  )}
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )

  return (
    <>
      {' '}
      <KTCard rounded='rounded' border shadow>
        <Card.Header>
          <Card.Title>{codeToTitle(title)}</Card.Title>
        </Card.Header>
        <Card.Body>
          {detailsLoader !== undefined && detailsLoader === false ? (
            <>
              {title === 'articles' &&
              details?.transactionProductModel.transactionProductSubItems.length > 1 ? (
                <Accordian
                  items={details?.transactionProductModel.transactionProductSubItems.map(
                    (subItem, index) => ({
                      header: subItem.name,
                      content: getTabFragment(index.toString()),
                    })
                  )}
                />
              ) : (
                getTabFragment()
              )}
            </>
          ) : (
            <div className='d-flex justify-content-center align-items-center'>
              <span className='spinner-border spinner-border-md align-middle text-primary' />
            </div>
          )}
        </Card.Body>
      </KTCard>
      {isModalOpen && (
        <Modal
          heading={intl.formatMessage({id: 'ERROR.MESSAGE'})}
          onClose={() => {
            setIsModalOpen(false)
          }}
        >
          <div className='fs-6'>{fetchFieldValue('errorMessage') ?? 'N/A'}</div>
        </Modal>
      )}
    </>
  )
}
