import {useMemo, useState} from 'react'
import {useTable} from 'react-table'
import {CustomHeaderColumn} from './columns/CustomHeaderColumn'
import {CustomRow} from './columns/CustomRow'
import {returnColumns} from './columns/_columns'
import {useDash} from '../Dashboard'
import {Dropdown} from 'react-bootstrap'
import {codeToTitle} from '../../core/helper'
import {export_transactions} from '../../core/_request'
import {useNavigate} from 'react-router-dom'
import {KTIcon} from '../../../../../general/helpers/components/KTIcon'
import {useIntl} from 'react-intl'
import {useAuth} from '../../../auth'
import {PERMISSION_ARRKEY} from '../../../auth/core/constant'

interface UsersTableI {
  filterQueryStr: string
}

const TransactionsTable = (_props: UsersTableI) => {
  const {filterQueryStr} = _props
  const {querylistings} = useDash()
  const {getAllowedPermissionValue} = useAuth()
  const intl = useIntl()
  const users = querylistings
  const data = useMemo(() => users, [users])
  const navigate = useNavigate()
  const columns = useMemo(() => {
    return returnColumns(navigate, filterQueryStr)
    // eslint-disable-next-line
  }, [filterQueryStr])
  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  })
  const [loading, setLoading] = useState(false)

  const exportActions = ['basic_view', 'detailed_view']

  return (
    <>
      <div className='d-flex flex-row-reverse mb-5'>
        {getAllowedPermissionValue(PERMISSION_ARRKEY.EXPORT_TRANSACTIONS) && (
          <Dropdown
            onSelect={async (e) => {
              setLoading(true)
              let headers = {}
              if (e === 'detailed_view') {
                headers = {type: 'detailed'}
              }
              try {
                await export_transactions(filterQueryStr.replace('?', ''), headers)
              } catch (e) {
              } finally {
                setLoading(false)
              }
            }}
          >
            <Dropdown.Toggle
              className='btn btn-light-primary'
              id='dropdown-basic'
              disabled={loading}
            >
              <KTIcon iconName='exit-up' className='fs-2' />
              {intl.formatMessage({id: 'EXPORT'})}
              {loading && <span className='spinner-border spinner-border-sm align-middle ms-2' />}
            </Dropdown.Toggle>
            <Dropdown.Menu className='min-w-100'>
              {exportActions.map((action, index) => {
                return (
                  <Dropdown.Item key={index} eventKey={action}>
                    {codeToTitle(action)}
                  </Dropdown.Item>
                )
              })}
            </Dropdown.Menu>
          </Dropdown>
        )}
      </div>
      <div className='table-responsive'>
        <table
          id='kt_table_users'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
          {...getTableProps()}
        >
          <thead>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
              {headers.map((column) => (
                <CustomHeaderColumn key={column.id} column={column} />
              ))}
            </tr>
          </thead>
          <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row, i) => {
                prepareRow(row)
                return <CustomRow row={row} key={`row-${i}-${row.id}`} />
              })
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    {intl.formatMessage({
                      id: 'RESULT.EMPTY',
                    })}
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  )
}

export {TransactionsTable}
