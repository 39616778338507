import {useFormik} from 'formik'
import React, {useEffect, useState} from 'react'
import * as Yup from 'yup'
import {useRoles} from '../core/Roles'
import {useQueryResponse, useQueryResponseData} from '../core/RolesResponseProvider'
import clsx from 'clsx'
import {isNotEmpty} from '../../../../../general/helpers'
import {create_role, get_retailers, get_role_details, update_role} from '../../core/_request'
import {useIntl} from 'react-intl'
import {discardForm} from '../../core/helper'
import {Retailer, Role} from '../../core/_models'

interface RoleForm {
  name: string
  permissions: any[]
  retailerId: string
}

interface Props {
  onClose?: () => void
  roleDetails?: Role
}

const EditRoleForm = (props: Props) => {
  const intl = useIntl()
  const rolesList = useQueryResponseData()
  const {refetch} = useQueryResponse()
  const {onClose, roleDetails} = props
  const {itemIdForUpdate, saveItemIdForUpdate, defaultPermissions, defaultPermissionsLoading} =
    useRoles()
  const roleObj = rolesList.find((ele) => ele._id === itemIdForUpdate) ?? roleDetails
  const [loading, setLoading] = useState<boolean>(false)
  const [permissions, setPermissions] = useState<any[]>(roleDetails?.permissions ?? [])
  const [retailersState, setRetailersState] = useState<{
    loading: boolean
    retailers: Array<Retailer>
  }>({loading: false, retailers: []})
  const [roleForEdit, setRoleForEdit] = useState<RoleForm>({
    ...roleObj,
    name: roleObj?.name ?? '',
    permissions: roleDetails?.permissions ?? [],
    retailerId: roleDetails?.retailerId ?? '',
  })
  const isEditing = isNotEmpty(roleObj?._id)

  const editRoleSchema = Yup.object().shape({
    name: Yup.string().min(3, 'Enter minimum 3 characters').required('Role name is required'),
    permissions: Yup.array().min(1, 'Select at least one permission').required(),
    retailerId: Yup.string().required('Retailer ID is required'),
  })

  useEffect(() => {
    if (isEditing && !roleDetails) {
      fetchRoleDetails()
    }
    // eslint-disable-next-line
  }, [])
  /**
   * fetching details of role to be edited
   */
  const fetchRoleDetails = async () => {
    setLoading(true)
    if (roleObj?._id) {
      try {
        const {permissions: permissionsArr, retailerId} = await get_role_details(roleObj?._id)
        setPermissions(permissionsArr)
        setRoleForEdit((prev) => ({
          ...prev,
          permissions: permissionsArr,
          retailerId: retailerId ?? '',
        }))
      } catch {
      } finally {
        setLoading(false)
      }
    }
  }
  /**
   * closes this modal
   * @param withRefresh decides whether the roles are to be refreshed after closing
   */
  const closeModal = (withRefresh?: boolean) => {
    if (withRefresh) {
      if (isEditing) {
        onClose ? onClose() : refetch()
      } else {
        refetch()
      }
    }
    saveItemIdForUpdate(undefined)
  }
  /**
   * handles discard action for form
   */
  const discardModal = () => {
    if (formik.dirty) {
      discardForm(() => {
        saveItemIdForUpdate(undefined)
        formik.resetForm()
      })
    } else {
      closeModal()
    }
  }

  const formik = useFormik({
    initialValues: roleForEdit,
    validationSchema: editRoleSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        const payload = {
          name: values.name,
          permissions,
          retailerId: parseInt(values.retailerId),
        }
        if (isEditing) {
          await update_role(roleObj?._id as string, payload)
          closeModal(true)
        } else {
          await create_role(payload)
          closeModal(true)
        }
      } catch {
      } finally {
        setSubmitting(false)
      }
    },
    validateOnChange: true,
    validateOnMount: true,
    enableReinitialize: true,
  })
  /**
   * fetching retailer list
   */
  const fetchRetailers = async () => {
    setRetailersState((prev) => ({...prev, loading: true}))
    try {
      const retailers = await get_retailers()
      setRetailersState((prev) => ({...prev, retailers}))
    } catch (e) {
    } finally {
      setRetailersState((prev) => ({...prev, loading: false}))
    }
  }
  /**
   * toggles any permission from the listing of permissions
   * @param key name of permission group key
   * @param apiName name of api to be toggled
   */
  const togglePermission = (key: string, apiName: string) => {
    const found = permissions?.find((item) => key === Object.keys(item)?.[0])
    if (found?.[key]?.allowed?.includes(apiName)) {
      if (found) {
        const filterred = found?.[key]?.allowed.filter((str) => str !== apiName)
        if (filterred.length === 0) {
          const temp = permissions.filter((item) => {
            return key !== Object.keys(item)?.[0]
          })
          setPermissions(temp)
          formik.setValues({
            ...formik.values,
            permissions: temp,
          })
        } else {
          const obj = JSON.parse(JSON.stringify(found))
          obj[key].allowed = filterred
          const temp = permissions.map((item) => {
            return key === Object.keys(item)?.[0] ? obj : item
          })
          setPermissions(temp)
          formik.setValues({
            ...formik.values,
            permissions: temp,
          })
        }
      }
    } else {
      if (found) {
        const temp = permissions.map((ele, index) => {
          let obj = JSON.parse(JSON.stringify(ele))
          if (key === Object.keys(ele)?.[0]) {
            obj?.[key]?.allowed?.push?.(apiName)
          }
          return obj
        })
        setPermissions(temp)
        formik.setValues({
          ...formik.values,
          permissions: temp,
        })
      } else {
        const obj = {[key]: {allowed: [apiName]}}
        setPermissions((prev) => [...prev, obj])
        formik.setValues({
          ...formik.values,
          permissions: [...permissions, obj],
        })
      }
    }
  }
  useEffect(() => {
    fetchRetailers()
  }, [])

  return (
    <form id='kt_modal_add_role_form' className='form' onSubmit={formik.handleSubmit} noValidate>
      <div
        className='d-flex flex-column scroll-y me-n7 ps-1 pe-7 mh-430px'
        id='kt_modal_add_role_scroll'
        data-kt-scroll='true'
        data-kt-scroll-activate='{default: false, lg: true}'
        data-kt-scroll-max-height='auto'
        data-kt-scroll-dependencies='#kt_modal_add_role_header'
        data-kt-scroll-wrappers='#kt_modal_add_role_scroll'
        data-kt-scroll-offset='300px'
      >
        <div className='fv-row mb-10'>
          <label className='fs-5 fw-bold form-label mb-2'>
            <span className='required'>{intl.formatMessage({id: 'ROLE.NAME'})}</span>
          </label>

          <input
            className={clsx(
              'form-control form-control-solid',
              {'is-invalid': formik.touched.name && formik.errors.name},
              {
                'is-valid': formik.touched.name && !formik.errors.name,
              }
            )}
            {...formik.getFieldProps('name')}
            onChange={formik.handleChange}
            value={formik.values.name}
            disabled={formik.isSubmitting}
            placeholder={intl.formatMessage({id: 'PLACEHOLDER.ROLE.NAME'})}
            type='text'
            name='name'
          />
          {formik.touched.name && formik.errors.name && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.name}</span>
              </div>
            </div>
          )}
        </div>

        <div className='fv-row mb-7'>
          <label className='required fw-bold fs-6 mb-2'>
            {intl.formatMessage({id: 'RETAILER.ID'})}
          </label>
          <select
            {...formik.getFieldProps('retailerId')}
            className='form-select form-select-solid cursor-pointer'
            data-placeholder='Select option'
            name='retailerId'
            disabled={formik.isSubmitting || retailersState.loading}
            size={retailersState.loading ? 1 : 3}
          >
            <option hidden value={''} />
            {retailersState.loading ? (
              <option>{intl.formatMessage({id: 'LOADING'})}...</option>
            ) : (
              retailersState.retailers.map((item, index) => {
                return (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                )
              })
            )}
          </select>
          {formik.touched.retailerId && formik.errors.retailerId && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.retailerId}</span>
              </div>
            </div>
          )}
        </div>

        <div className='fv-row'>
          <label className='fs-5 fw-bold form-label mb-2'>
            <span className='required'>{intl.formatMessage({id: 'ROLE.PERMISSIONS'})}</span>
          </label>
          {defaultPermissionsLoading || loading ? (
            <div className='d-flex justify-content-center align-items-center'>
              <span className='me-2'>Please wait...</span>
              <span className='spinner-border spinner-border-md align-middle text-primary' />
            </div>
          ) : (
            <div className='d-flex flex-column fs-6 text-gray-600 fw-semibold'>
              {defaultPermissions.map((ele, ind) => {
                const [key, val]: any[] = Object.entries(ele)?.[0]
                return (
                  <div
                    className={clsx('d-flex gap-6 py-5', {
                      'border-1 border-bottom-dashed border-gray-200':
                        ind < defaultPermissions.length - 1,
                    })}
                    key={key}
                  >
                    <div className='text-gray-800 min-w-180px w-180px'>{val?.name ?? key}</div>
                    <div>
                      <div className='d-flex flex-wrap gap-4'>
                        {val?.allowed.map((apiName: string) => (
                          <label
                            key={apiName}
                            className='form-check form-check-sm form-check-custom form-check-solid'
                          >
                            <input
                              className='form-check-input'
                              type='checkbox'
                              value={apiName}
                              onChange={() => {
                                togglePermission(key, apiName)
                              }}
                              checked={
                                permissions
                                  ?.find((item) => key === Object.keys(item)?.[0])
                                  ?.[key]?.allowed?.includes(apiName) ?? false
                              }
                            />
                            <span className='form-check-label'>{apiName}</span>
                          </label>
                        ))}
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          )}
        </div>
      </div>
      <div className='text-center pt-15'>
        <button
          type='button'
          className='btn btn-light me-3'
          data-kt-roles-modal-action='cancel'
          onClick={discardModal}
        >
          {intl.formatMessage({id: 'DISCARD'})}
        </button>
        <button
          type='submit'
          className='btn btn-primary'
          data-kt-roles-modal-action='submit'
          disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}
        >
          {formik.isSubmitting ? (
            <span>
              {intl.formatMessage({id: 'PLEASE.WAIT'})}{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          ) : (
            <span className='indicator-label'>{intl.formatMessage({id: 'SUBMIT'})}</span>
          )}
        </button>
      </div>
    </form>
  )
}

export default EditRoleForm
