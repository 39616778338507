// @ts-nocheck
import {Column} from 'react-table'
import {UserInfoCell} from './UserInfoCell'
import {User} from '../../core/_models'
import CustomHeader from '../../../components/header/CustomHeader'
import {CreatedAtCell} from '../../../users/table/columns/CreatedAtCell'

// const { auth } = useAuth();
const returnColumns = (navigate: any, filterQueryStr: string) => {
  const columns: ReadonlyArray<Column<User>> = [
    {
      Header: (props) => (
        <CustomHeader tableProps={props} title='Transaction ID' className='min-w-110px' />
      ),
      id: 'transactionID',
      Cell: ({...props}) => (
        <UserInfoCell
          user={props.data[props.row.index]}
          col={
            <button
              className='p-0 border-0 bg-white text-primary fw-bold'
              onClick={() => {
                const id = props.data[props.row.index]?.id ?? ''
                sessionStorage.setItem('filterQueryStr', filterQueryStr)
                navigate(`/transactions/${id}/details`)
              }}
            >
              {props.data[props.row.index]?.id ?? 'N/A'}
            </button>
          }
        />
      ),
    },
    {
      Header: (props) => (
        <CustomHeader tableProps={props} title='Created at' className='mw-125px' />
      ),
      id: 'createdAt',
      Cell: ({...props}) => <CreatedAtCell createdAt={props.data[props.row.index]?.createdAt} />,
    },
    {
      Header: (props) => (
        <CustomHeader tableProps={props} title='Store number' className='mw-110px' />
      ),
      id: 'storeNumber',
      Cell: ({...props}) => (
        <UserInfoCell
          user={props.data[props.row.index]}
          col={props.data[props.row.index]?.store?.refNumber ?? 'N/A'}
        />
      ),
    },
    {
      Header: (props) => (
        <CustomHeader tableProps={props} title='Device Name' className='mw-125px' />
      ),
      id: 'deviceName',
      Cell: ({...props}) => (
        <UserInfoCell
          user={props.data[props.row.index]}
          col={props.data[props.row.index]?.device?.name ?? 'N/A'}
        />
      ),
    },
    {
      Header: (props) => <CustomHeader tableProps={props} title='Supplier' className='mw-125px' />,
      id: 'supplier',
      Cell: ({...props}) => (
        <UserInfoCell
          user={props.data[props.row.index]}
          col={props.data[props.row.index]?.transactionProductModel?.supplier ?? 'N/A'}
        />
      ),
    },
    {
      Header: (props) => (
        <CustomHeader tableProps={props} title='Service name' className='mw-125px' />
      ),
      id: 'serviceName',
      Cell: ({...props}) => (
        <UserInfoCell
          user={props.data[props.row.index]}
          col={props.data[props.row.index]?.transactionProductModel?.name ?? 'N/A'}
        />
      ),
    },
    {
      Header: (props) => (
        <CustomHeader tableProps={props} title='Serial number' className='mw-125px' />
      ),
      id: 'serialNumber',
      Cell: ({...props}) => (
        <UserInfoCell
          user={props.data[props.row.index]}
          col={props.data[props.row.index]?.transactionProductModel?.serialNumber ?? 'N/A'}
        />
      ),
    },
    {
      Header: (props) => (
        <CustomHeader tableProps={props} title='Transaction type' className='mw-125px' />
      ),
      id: 'TransactionType',
      Cell: ({...props}) => (
        <UserInfoCell
          user={props.data[props.row.index]}
          col={props.data[props.row.index]?.transactionType ?? 'N/A'}
        />
      ),
    },
    {
      Header: (props) => (
        <CustomHeader tableProps={props} title='Transaction result' className='mw-125px' />
      ),
      id: 'transactionResult',
      Cell: ({...props}) => (
        <UserInfoCell
          user={props.data[props.row.index]}
          col={props.data[props.row.index]?.transactionResult ?? 'N/A'}
        />
      ),
    },

    {
      Header: (props) => <CustomHeader tableProps={props} title='Amount' className='mw-100px' />,
      id: 'totalAmount',
      Cell: ({...props}) => (
        <UserInfoCell
          user={props.data[props.row.index]}
          col={props.data[props.row.index]?.transactionProductModel?.totalAmount ?? 'N/A'}
        />
      ),
    },
  ]
  return columns
}

export {returnColumns}
