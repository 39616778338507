import React, {useState} from 'react'
import {useStoresContext} from '../core/Stores'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {useIntl} from 'react-intl'
import {codeToTitle, discardForm} from '../../core/helper'
import Input from '../../../auth/components/Input'
import {isNotEmpty} from '../../../../../general/helpers'
import {create_property, update_store_property} from '../../core/_request'
import {useParams} from 'react-router-dom'
import moment from 'moment'
import {StoreProperty} from '../../core/_models'

const editPropertySchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  value: Yup.string().required('Value is required'),
  sensitiveData: Yup.string().required('Sensitive Data is required'),
})

interface Props {
  fetchProperties: () => void
}

const EditPropertyForm = ({fetchProperties}: Props) => {
  const {itemDetailsForUpdate, saveItemDetailsForUpdate} = useStoresContext()
  const intl = useIntl()
  const {storeId} = useParams()
  const isEditing = itemDetailsForUpdate?.values !== undefined
  const detailsObj = {...itemDetailsForUpdate?.values} as StoreProperty

  const [propertyForEdit] = useState({
    name: detailsObj?.name ?? '',
    value: detailsObj?.value as string,
    sensitiveData: detailsObj?.sensitiveData ? 'Yes' : 'No',
  })

  const formik = useFormik({
    initialValues: propertyForEdit,
    validationSchema: editPropertySchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        const payload = {
          ...values,
          sensitiveData: values.sensitiveData === 'Yes',
        }
        if (isEditing) {
          await update_store_property(itemDetailsForUpdate?.values?.id.toString() ?? '', payload)
        } else {
          await create_property({
            ...payload,
            store: storeId ?? '',
            startDate: moment().toISOString(),
          })
        }
        cancel(true)
      } catch {
      } finally {
        setSubmitting(false)
      }
    },
    validateOnChange: true,
    validateOnMount: true,
    enableReinitialize: true,
  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      fetchProperties()
    }
    saveItemDetailsForUpdate(null)
  }

  /**
   * handles discard action for form
   */
  const discardModal = () => {
    if (formik.dirty) {
      discardForm(() => {
        saveItemDetailsForUpdate(null)
        formik.resetForm()
      })
    } else {
      cancel()
    }
  }

  const sensitiveDataOptions = [
    {label: 'Yes', value: true},
    {label: 'No', value: false},
  ]

  return (
    <form
      id='kt_modal_add_role_form'
      className='form'
      noValidate
      onSubmit={formik.handleSubmit}
      onChange={formik.handleChange}
    >
      <div
        className='d-flex flex-column scroll-y me-n7 ps-1 pe-7 mh-430px'
        id='kt_modal_add_role_scroll'
        data-kt-scroll='true'
        data-kt-scroll-activate='{default: false, lg: true}'
        data-kt-scroll-max-height='auto'
        data-kt-scroll-offset='300px'
      >
        <div className='fv-row mb-7'>
          <Input
            labelId='NAME'
            attrs={formik.getFieldProps('name')}
            errorStrId={formik.errors.name}
            disabled={formik.isSubmitting}
            placeHolderId='PLACEHOLDER.PROPERTY.NAME'
            onChange={(value, e) => formik.handleChange(e)}
            isInvalid={isNotEmpty(formik.touched.name) && isNotEmpty(formik.errors.name)}
            isValid={isNotEmpty(formik.touched.name) && !formik.errors.name}
            required
          />
        </div>
        <div className='fv-row mb-7'>
          <Input
            labelId='VALUE'
            attrs={formik.getFieldProps('value')}
            errorStrId={formik.errors.value}
            disabled={formik.isSubmitting}
            placeHolderId='PLACEHOLDER.PROPERTY.VALUE'
            onChange={(value, e) => formik.handleChange(e)}
            isInvalid={isNotEmpty(formik.touched.value) && isNotEmpty(formik.errors.value)}
            isValid={isNotEmpty(formik.touched.value) && !formik.errors.value}
            required
          />
        </div>
        <div className='mb-7'>
          <label className='required fw-bold fs-6 mb-5'>
            {intl.formatMessage({id: 'SENSITIVE.DATA'})}
          </label>
          {sensitiveDataOptions.map((item, ind) => (
            <div key={ind}>
              <div className='d-flex fv-row mb-2'>
                <div className='form-check form-check-custom form-check-solid'>
                  <input
                    className='form-check-input me-3'
                    {...formik.getFieldProps('role')}
                    name='sensitiveData'
                    type='radio'
                    value={item.label}
                    checked={formik.values.sensitiveData === item.label}
                    id={`kt_modal_update_role_option_${item}`}
                    disabled={formik.isSubmitting}
                    onChange={formik.handleChange}
                  />
                  <label
                    className='form-check-label'
                    htmlFor={`kt_modal_update_role_option_${item}`}
                  >
                    <div className='fw-bold text-gray-800'>{codeToTitle(item.label)}</div>
                  </label>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className='text-center pt-15'>
        <button
          type='button'
          className='btn btn-light me-3'
          data-kt-roles-modal-action='cancel'
          onClick={discardModal}
        >
          {intl.formatMessage({id: 'DISCARD'})}
        </button>
        <button
          type='submit'
          className='btn btn-primary'
          data-kt-roles-modal-action='submit'
          disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}
        >
          {formik.isSubmitting ? (
            <span>
              {intl.formatMessage({id: 'PLEASE.WAIT'})}{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          ) : (
            <span className='indicator-label'>{intl.formatMessage({id: 'SUBMIT'})}</span>
          )}
        </button>
      </div>
    </form>
  )
}

export default EditPropertyForm
