import React, {createContext, useCallback, useContext, useState} from 'react'
import {Role} from '../../core/_models'

interface PermissionsContextI {
  itemIdForUpdate: string | null | undefined
  saveItemIdForUpdate: (id: string | null | undefined) => void
  badgesData: {[key: string]: string}
  saveBadgesData: (params: {[key: string]: string}) => void
  roles: Role[]
  saveRoles: (param: Role[]) => void
}

const PermissionsContext = createContext<PermissionsContextI>({
  itemIdForUpdate: undefined,
  saveItemIdForUpdate: () => {},
  badgesData: {},
  saveBadgesData: (params) => {},
  roles: [],
  saveRoles: (param) => {},
})

const usePermissions = () => {
  return useContext(PermissionsContext)
}

const PermissionsProvider = ({children}) => {
  const [itemIdForUpdate, setItemIdForUpdate] = useState<string | null | undefined>()
  const [badgesData, setBadgesData] = useState<{[key: string]: string}>({})
  const [roles, setRoles] = useState<Role[]>([])

  const saveItemIdForUpdate = (id: string | null | undefined) => {
    setItemIdForUpdate(id)
  }
  const saveBadgesData = useCallback((obj: {[key: string]: string}) => {
    setBadgesData({...obj})
  }, [])
  const saveRoles = useCallback((arr: Role[]) => {
    setRoles([...arr])
  }, [])

  return (
    <PermissionsContext.Provider
      value={{
        itemIdForUpdate,
        saveItemIdForUpdate,
        badgesData,
        saveBadgesData,
        roles,
        saveRoles,
      }}
    >
      {children}
    </PermissionsContext.Provider>
  )
}

export {usePermissions, PermissionsProvider}
