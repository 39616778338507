import React from 'react'
import {CustomRow} from '../dashboad/table/columns/CustomRow'
import {Column, ColumnInstance, Row, useTable} from 'react-table'
import {CustomHeaderColumn} from '../dashboad/table/columns/CustomHeaderColumn'
import {useIntl} from 'react-intl'

interface Props {
  data: any[]
  columns: readonly Column<any>[]
  onRowClick?: (index: number) => void
}

const Grid = (props: Props) => {
  const {columns, data, onRowClick} = props
  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  })
  const intl = useIntl()
  return (
    <div className='table-responsive'>
      <table
        id='kt_table_users'
        className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
        {...getTableProps()}
      >
        <thead>
          <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
            {headers.map((column: ColumnInstance<any>) => (
              <CustomHeaderColumn key={column.id} column={column} />
            ))}
          </tr>
        </thead>
        <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
          {rows.length > 0 ? (
            rows.map((row: Row<any>, i) => {
              prepareRow(row)
              return (
                <CustomRow
                  row={row}
                  key={`row-${i}-${row.id}`}
                  onRowClick={
                    onRowClick
                      ? () => {
                          onRowClick?.(i)
                        }
                      : undefined
                  }
                />
              )
            })
          ) : (
            <tr>
              <td colSpan={7}>
                <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                  {intl.formatMessage({id: 'RESULT.EMPTY'})}
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
}

export default Grid
