import React from 'react'
import {KTCard} from '../../../../general/helpers'
import {PermissionsListHeader} from './components/header/PermissionsListHeader'
import PermissionsListTable from './table/PermissionsListTable'
import {usePermissions} from './core/Permissions'
import Modal from '../components/Modal'
import {useIntl} from 'react-intl'
import EditPermissionsForm from './components/EditPermissionsForm'

const PermissionsLayout = () => {
  const {itemIdForUpdate, saveItemIdForUpdate} = usePermissions()
  const intl = useIntl()
  return (
    <KTCard>
      <PermissionsListHeader />
      <PermissionsListTable />
      {itemIdForUpdate && (
        <Modal
          heading={intl.formatMessage({id: 'PERMISSIONS.UPDATE'})}
          onClose={() => {
            saveItemIdForUpdate(undefined)
          }}
        >
          <EditPermissionsForm />
        </Modal>
      )}
    </KTCard>
  )
}

export default PermissionsLayout
