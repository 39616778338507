import {FC, createContext, useCallback, useContext, useState} from 'react'
import {WithChildren} from '../../../../general/helpers'
import {PaginationI, User} from '../core/_models'

interface UsersContextI {
  usersList: Array<User>
  saveUsersList: (users: Array<User>) => void
  usersLoading: boolean
  pagination: PaginationI
  totalItems: number
  saveTotalItems: (params: number) => void
  savePagination: (param: PaginationI) => void
  toggleUsersLoading: (loading: boolean) => void
  itemIdForUpdate: string | null | undefined
  saveItemIdForUpdate: (id: string | null | undefined) => void
  filters: {[key: string]: string | number}
  saveFilters: (obj: {[key: string]: string | number}) => void
}

const UsersContext = createContext<UsersContextI>({
  usersList: [],
  saveUsersList: (users: Array<User>) => {},
  usersLoading: false,
  pagination: {size: 20, page: 1},
  savePagination: (param: PaginationI) => {},
  totalItems: 0,
  saveTotalItems: (param: number) => {},
  toggleUsersLoading: (loading: boolean) => {},
  itemIdForUpdate: undefined,
  saveItemIdForUpdate: (id: string | null | undefined) => {},
  filters: {},
  saveFilters: (obj: {[key: string]: string | number}) => {},
})

const useUsers = () => {
  return useContext(UsersContext)
}
const UsersInit: FC<WithChildren> = ({children}) => {
  return <>{children}</>
}
const UsersProvider: FC<WithChildren> = ({children}) => {
  const [usersList, setUsersList] = useState<Array<User>>([])
  const [usersLoading, setUsersLoading] = useState<boolean>(false)
  const [itemIdForUpdate, setItemIdForUpdate] = useState<string | null | undefined>()
  const [pagination, setPagination] = useState<PaginationI>({
    size: 10,
    page: 1,
  })
  const [totalItems, setTotalItems] = useState<number>(0)
  const [filters, setFilters] = useState<{[key: string]: string | number}>({})
  const saveUsersList = (list: Array<User>) => {
    setUsersList(list)
  }
  const toggleUsersLoading = useCallback(() => {
    setUsersLoading((prev) => !prev)
  }, [])
  const saveItemIdForUpdate = useCallback((id: string | null | undefined) => {
    setItemIdForUpdate(id)
  }, [])
  const saveFilters = useCallback((filterObj: {[key: string]: string | number}) => {
    setFilters(filterObj)
  }, [])
  const savePagination = useCallback((pagObj: PaginationI) => {
    setPagination(pagObj)
  }, [])
  const saveTotalItems = useCallback((total: number) => {
    setTotalItems(total)
  }, [])

  return (
    <UsersContext.Provider
      value={{
        usersList,
        saveUsersList,
        usersLoading,
        pagination,
        savePagination,
        totalItems,
        saveTotalItems,
        toggleUsersLoading,
        itemIdForUpdate,
        saveItemIdForUpdate,
        filters,
        saveFilters,
      }}
    >
      <div className='position-relative'>{children}</div>
    </UsersContext.Provider>
  )
}
export {UsersInit, useUsers, UsersProvider}
