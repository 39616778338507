import React from 'react'
import {KTCard, KTCardBody, toAbsoluteUrl} from '../../helpers'
import {useIntl} from 'react-intl'

const ErrorFallback = () => {
  const intl = useIntl()
  return (
    <KTCard>
      <KTCardBody>
        <div className='d-flex flex-column align-items-center gap-8'>
          <img
            src={toAbsoluteUrl('/media/misc/united-palms-19.png')}
            alt='error'
            className='mh-300px'
          />
          <div className='fs-3'>{intl.formatMessage({id: 'ERROR.BOUNDARY'})}</div>
        </div>
      </KTCardBody>
    </KTCard>
  )
}

export default ErrorFallback
