import Swal from 'sweetalert2'

export const prepareQueryString = (params, endpoint = '') => {
  let paramsString = ''
  const paramKeys = Object.keys(params)
  if (paramKeys.length > 0) {
    if (endpoint.search('\\?') >= 0) paramsString += '&'
    else paramsString += '?'

    for (let i = 0; i < paramKeys.length; i++) {
      const end = i < paramKeys.length - 1 ? '&' : ''
      paramsString += Object.keys(params)[i] + '=' + encodeURIComponent(params[paramKeys[i]]) + end
    }
  }
  return paramsString
}

export const codeToTitle = (code: string) => {
  let wordArr = code.split('_')
  wordArr = wordArr.map((word) => {
    word = word.charAt(0).toLocaleUpperCase() + word.slice(1)
    return word
  })
  return wordArr.join(' ')
}

export function parseJwt(token: string) {
  var base64Url = token.split('.')[1]
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join('')
  )

  return JSON.parse(jsonPayload)
}
/**
 * checks whether a jwt token is expired
 * @param token a jwt token
 * @returns true/false if the token is expired or not respectively
 */
export const isJwtTokenExpired = (token: string) => {
  return parseJwt(token).exp * 1000 < Date.now()
}
/**
 * to scroll to below of any element
 * @param element element to be scrolled below
 * @param offset space needed below element
 * @param behavior scroll behaviour
 */
export function scrollToBelowElement(
  element: HTMLElement,
  offset: number = 0,
  behavior: ScrollBehavior = 'smooth'
) {
  const elementRect = element.getBoundingClientRect()
  const absoluteElementBottom = elementRect.bottom
  const scrollBy = absoluteElementBottom - offset
  window.scrollBy({top: scrollBy, behavior})
}

export const getStatus = (userObj: any) => {
  if (userObj.isActive === true) {
    return 'active'
  } else if (userObj.inviteStatus === 'pending') {
    return 'pending'
  } else {
    return 'inactive'
  }
}

export const showToast = (data: string) => {
  return <div dangerouslySetInnerHTML={{__html: data}} />
}
/**
 * handles confirmation for discarding a form
 * @param onConfirm function to be called after confirmation
 */
export const discardForm = (onConfirm: () => void) => {
  Swal.fire({
    text: 'Are you sure you would like to cancel?',
    icon: 'warning',
    showCancelButton: true,
    buttonsStyling: false,
    confirmButtonText: 'Yes, cancel it!',
    cancelButtonText: 'No, return',
    customClass: {
      confirmButton: 'btn btn-primary',
      cancelButton: 'btn btn-active-light',
    },
  }).then(function (result) {
    if (result.value) {
      onConfirm()
    }
  })
}
/**
 * finds if two arrays are equal and have same elements
 * @param a array of string or number
 * @param b array of string or number
 * @returns whether the arrays have same elements only
 */
export const areArraysEqual = (a: Array<string | number>, b: Array<string | number>) => {
  const temp = [...b]
  return (
    Array.isArray(a) &&
    Array.isArray(b) &&
    a.length === b.length &&
    a.every((item) => {
      const index = temp.findIndex((itemB) => itemB === item)
      if (index > -1) {
        temp.splice(index, 1)
        return true
      }
      return false
    })
  )
}
/**
 * checks whether a role is admin onbasis of all permissions allowed
 * @param defaultPermissions array of all permissions available
 * @param role role object
 * @returns whether the role has all the permissions
 */
export const isRoleAdmin = (defaultPermissions: any[], permissions: any[]) => {
  return defaultPermissions.every((ele: any) => {
    const key = Object.keys(ele)?.[0]
    if (!Array.isArray(permissions)) return false
    const found = permissions?.find(
      (item) =>
        Object.keys(ele)?.[0] === key && areArraysEqual(item?.[key]?.allowed, ele?.[key]?.allowed)
    )
    return found !== undefined
  })
}
/**
 * creates an array of options object for rendering select
 * @param data options array for a filter fetching from API
 * @returns array of options object
 */
export const getFilterOptionsArr = (data: any[]) => {
  return data.map((item) => ({
    label: item?.['type'] ?? item?.['name'] ?? 'N/A',
    value: item?.['type'] ?? item?.['name'] ?? 'N/A',
  }))
}
/**
 * sorts an object array based on key
 * @param arr object array
 * @param key name of key
 * @returns sorted array based on the value pair of the key
 */
export const sortObjArr = (arr: any[], key: string) => {
  return arr.sort((a, b) => {
    if (a?.[key]?.toLowerCase() === b?.[key]?.toLowerCase()) {
      return 0
    }
    return a?.[key]?.toLowerCase() > b?.[key]?.toLowerCase() ? 1 : -1
  })
}
/**
 * creates a formatted date time string based on locale and timezone
 * @param locale locale
 * @param timeZone timezone
 * @param dateStr utc date string
 * @returns formatted string for date and time
 */
export const getFormattedDateTime = (locale: string, timeZone: string, dateStr: string) => {
  try {
    return new Intl.DateTimeFormat(locale, {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
      timeZone: timeZone,
    }).format(new Date(dateStr))
  } catch {
    return 'N/A'
  }
}
/**
 * creates a placeholder based on locale and timezone
 * @param locale locale
 * @param timeZone timezone
 * @returns placeholder string for date pickers
 */
export const getDatePlaceholder = (locale: string, timeZone: string) => {
  const formatParts = new Intl.DateTimeFormat(locale, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    timeZone: timeZone,
  }).formatToParts(new Date())

  return formatParts
    .map((part) => {
      switch (part.type) {
        case 'day':
          return 'DD'
        case 'month':
          return 'MM'
        case 'year':
          return 'YYYY'
        case 'literal':
          return part.value
        default:
          return ''
      }
    })
    .join('')
}
