import React from 'react'
import {PageTitle} from '../../../../general/layout/core'
import StoresWrapper from './StoresWrapper'
import {useIntl} from 'react-intl'
import WithPermissionRestricted from '../../../../general/hoc/WithPermissionRestricted'

const StoresPage = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle>{intl.formatMessage({id: 'STORES.LIST'})}</PageTitle>
      <StoresWrapper />
    </>
  )
}

export default WithPermissionRestricted(StoresPage, 'STORES.LIST', ['stores', 'GetAllStores'])
