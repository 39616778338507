import {useIntl} from 'react-intl'
import {PageTitle} from '../../../../general/layout/core'
import WithPermissionRestricted from '../../../../general/hoc/WithPermissionRestricted'
import UsersWrapper from './UsersWrapper'

const UsersPage = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle>{intl.formatMessage({id: 'USERS.LIST'})}</PageTitle>
      <UsersWrapper />
    </>
  )
}

export default WithPermissionRestricted(UsersPage, 'USERS.LIST', ['users', 'GetAllUsers']);
