import {useEffect, useRef, useState} from 'react'
import moment from 'moment'
import {isJwtTokenExpired, parseJwt} from '../core/helper'
import {useAuth} from '../../auth'
import Modal from './Modal'
import {get_new_token} from '../../auth/core/_requests'
import {SELECTED_STORAGE_KEYS} from '../../auth/core/constant'

function ReLogin(props: any) {
  const activityKey = SELECTED_STORAGE_KEYS.LAST_ACTIVITY_LOCAL_STORAGE_KEY
  const timeoutId = useRef<ReturnType<typeof setTimeout>>()
  const modalTimeoutId = useRef<ReturnType<typeof setTimeout>>()
  const logoutTimeoutId = useRef<ReturnType<typeof setTimeout>>()
  const {auth, logout, saveAuth} = useAuth()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [lastMinutesActive, setLastMinutesActive] = useState(false)

  /**
   * initializing timeouts for expiry and inactivity modal
   */
  useEffect(() => {
    updateActivity()
    const authToken = auth?.accessToken ?? ''
    if (authToken) {
      if (isJwtTokenExpired(authToken)) {
        logout(true)
      } else {
        initializeExpireTimeout()
        resetModalTimeout()
      }
    } else {
      logout()
    }
    return () => {
      clearTimeout(timeoutId.current)
      clearTimeout(modalTimeoutId.current)
      clearTimeout(logoutTimeoutId.current)
      localStorage.removeItem(activityKey)
    }
    // eslint-disable-next-line
  }, [])
  // reinitializing the event listeners
  useEffect(() => {
    window.addEventListener('click', updateActivity)
    window.addEventListener('keypress', updateActivity)
    return () => {
      window.removeEventListener('click', updateActivity)
      window.removeEventListener('keypress', updateActivity)
    }
    // eslint-disable-next-line
  }, [lastMinutesActive, isModalOpen])
  /**
   * initiates the API call for user token update
   */
  const updateToken = async () => {
    const authObj = {...auth}
    const {accessToken} = await get_new_token(auth?.accessToken ?? '')
    Object.assign(authObj, {accessToken})
    saveAuth(authObj)
    initializeExpireTimeout(accessToken)
  }

  /**
   * calculates time period from last activity
   * @returns time period in ms
   */
  const getLastActivityTime = () => {
    const lastActivityTime = localStorage.getItem(activityKey)
    if (lastActivityTime) {
      const timeFromLastActivity = moment().diff(
        moment(new Date(lastActivityTime).toISOString()),
        'milliseconds'
      )
      return timeFromLastActivity
    }
  }

  /**
   * method to update any activity from user
   */
  const updateActivity = () => {
    if (isModalOpen) return
    localStorage.setItem(activityKey, new Date().toString())
    resetModalTimeout()
    if (lastMinutesActive) {
      updateToken()
      setLastMinutesActive(false)
    }
    // eslint-disable-next-line
  }
  /**
   * resets the timeout for inactivity modal
   */
  const resetModalTimeout = () => {
    modalTimeoutId.current = setTimeout(() => {
      const lastActivityTime = getLastActivityTime()
      if (lastActivityTime && lastActivityTime > 3600000) {
        window.removeEventListener('click', updateActivity)
        window.removeEventListener('keypress', updateActivity)
        setIsModalOpen(true)
      }
    }, 3600000)
  }

  /**
   * initializes the timeout for token expiration check for activity in las 15 minutes
   */
  const initializeExpireTimeout = (accessToken?: string) => {
    const authToken = accessToken ?? auth?.accessToken ?? ''
    const expireTime = new Date(parseJwt(authToken).exp * 1000).toISOString()
    if (isJwtTokenExpired(authToken)) {
      logout(true)
      return
    }
    const diffInMilliseconds = moment(expireTime)
      .subtract(15, 'minutes')
      .diff(moment(), 'millisecond')
    const msTillExpire = moment(expireTime).diff(moment(), 'milliseconds')
    clearTimeout(logoutTimeoutId.current)
    logoutTimeoutId.current = setTimeout(() => {
      logout(true)
    }, msTillExpire)
    if (diffInMilliseconds <= 0) {
      setLastMinutesActive(true)
    } else {
      timeoutId.current = setTimeout(() => {
        setLastMinutesActive(true)
      }, diffInMilliseconds)
    }
  }

  /**
   * handler for modal closing
   */
  const onModalClose = () => {
    updateActivity()
    resetModalTimeout()
    setIsModalOpen(false)
  }
  return (
    <>
      {props.children}
      {isModalOpen && (
        <Modal
          onClose={onModalClose}
          heading='Stay connected?'
          size='modal-md'
          bodyClasses='mx-xl-15'
        >
          <div className='text-center'>
            <button type='button' onClick={() => logout(true)} className='btn btn-light'>
              No
            </button>
            <button type='button' className='btn btn-primary ms-4' onClick={onModalClose}>
              Yes
            </button>
          </div>
        </Modal>
      )}
    </>
  )
}

export default ReLogin
