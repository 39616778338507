/* eslint-disable jsx-a11y/anchor-is-valid */
import {ReactNode, useEffect} from 'react'
import {Outlet} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../general/helpers'
import {useIntl} from 'react-intl'

interface Props {
  children?: ReactNode
}

const AuthLayout = (props: Props) => {
  const intl = useIntl()
  useEffect(() => {
    const root = document.getElementById('root')
    if (root) {
      root.style.height = '100%'
    }
    return () => {
      if (root) {
        root.style.height = 'auto'
      }
    }
  }, [])

  return (
    <div
      className='d-flex flex-column flex-root'
      style={{backgroundImage: `url(${toAbsoluteUrl('/media/auth/bg10.jpeg')})`}}
    >
      <div className='d-flex flex-column flex-lg-row flex-column-fluid'>
        <div className='d-flex flex-lg-row-fluid'>
          <div className='d-flex flex-column flex-center pb-0 pb-lg-10 p-10 w-100'>
            <img
              className='theme-light-show mx-auto mw-100 w-500px w-lg-500px mb-10 mb-lg-20'
              src={toAbsoluteUrl('/media/logos/logo.png')}
              alt=''
            />
            <h1 className='text-gray-800 fs-2qx fw-bold text-center mb-7'>
              Smarts customer portal
            </h1>
            <div className=' text-gray-600 fs-5 text-center fw-semibold mw-900px'>
              {intl.formatMessage({id: 'WELCOME.TEXT'})}
              <div className='mt-3'>{intl.formatMessage({id: 'WELCOME.TEXT.LOGIN'})}</div>
            </div>
          </div>
        </div>
        <div className='d-flex flex-column-fluid flex-lg-row-auto justify-content-center justify-content-lg-end p-12'>
          <div className='bg-body d-flex flex-column flex-center rounded-4 w-md-600px p-10'>
            <div className='d-flex flex-center flex-column align-items-stretch h-lg-100 w-md-400px'>
              <div className='d-flex flex-center flex-column flex-column-fluid pb-15 pb-lg-20'>
                {props.children ?? <Outlet />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {AuthLayout}
