import {Navigate, Route, Routes} from 'react-router-dom'
import {MasterLayout} from '../../general/layout/core/MasterLayout'
import {TwoStepVerification} from '../modules/auth/components/TwoStepVerification'
import {AuthLayout} from '../modules/auth/AuthLayout'
import ReLogin from '../modules/panel/components/ReLogin'
import {getRoutes} from './routes'
import {useIntl} from 'react-intl'
import {getReportRoutes} from './ReportRoutes'
import {ErrorBoundary} from 'react-error-boundary'
import ErrorFallback from '../../general/layout/core/ErrorFallback'
import {getReportMenuStructure} from '../analytics-setup/Constant'
import {parseJwt} from '../modules/panel/core/helper'
import {useAuth} from '../modules/auth'
import {useEffect, useState} from 'react'
import {Retailer} from '../modules/panel/core/_models'

const PrivateRoutes = () => {
  const intl = useIntl()
  const {auth} = useAuth()
  const [activeRetailerData, setActiveRetailerData] = useState<Retailer>()
  useEffect(() => {
    // updating activeRetailer data after login
    if (auth?.accessToken) {
      const {activeRetailer} = parseJwt(auth?.accessToken)
      setActiveRetailerData(activeRetailer)
    }
  }, [auth?.accessToken])
  return (
    <Routes>
      <Route
        element={
          <ReLogin>
            <MasterLayout />
          </ReLogin>
        }
      >
        {getRoutes(intl).map((route) => {
          if (route.children.length > 0 && route.element) {
            return (
              <Route
                key={route.path}
                path={route.path}
                element={
                  <ErrorBoundary fallback={<ErrorFallback />} key={route.path}>
                    {route.element}
                  </ErrorBoundary>
                }
              >
                {route?.children?.map((childRoute) => {
                  return (
                    <Route
                      key={route.path}
                      index={childRoute?.index === true}
                      path={childRoute?.index === true ? '' : childRoute.path}
                      element={
                        <ErrorBoundary
                          fallback={<ErrorFallback />}
                          key={childRoute.path ?? route.path}
                        >
                          {childRoute.element}
                        </ErrorBoundary>
                      }
                    />
                  )
                })}
              </Route>
            )
          } else {
            return (
              <Route
                key={route.path}
                path={route.path}
                element={
                  <ErrorBoundary fallback={<ErrorFallback />} key={route.path}>
                    {route.element}
                  </ErrorBoundary>
                }
              />
            )
          }
        })}
        {[...getReportRoutes(getReportMenuStructure(activeRetailerData))]}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
      <Route element={<AuthLayout />}>
        <Route
          path='auth/2fa-verification'
          element={
            <ErrorBoundary fallback={<ErrorFallback />}>
              <TwoStepVerification />
            </ErrorBoundary>
          }
        />
      </Route>
    </Routes>
  )
}
export {PrivateRoutes}
