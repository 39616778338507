import React from 'react'
import {User} from '../../../core/_models'
import {getStatus} from '../../../core/helper'

interface Props {
  user: User
}

const UserStatusCell = (props: Props) => {
  const {user} = props

  const status = getStatus(user);

  if (status === 'active') {
    return <div className='badge badge-light-success fw-bolder'>Active</div>
  } else if (status === 'pending') {
    return <div className='badge badge-light-warning fw-bolder'>Pending</div>
  } else {
    return <div className='badge badge-light-info fw-bolder'>Inactive</div>
  }
}

export default UserStatusCell
