/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect} from 'react'
import {setLanguage, useLang} from '../../i18n/Metronici18n'
import {MenuComponent} from '../../assets/ts/components'

const languages = [
  {
    lang: 'en',
    name: 'English',
  },
  // {
  //   lang: 'de',
  //   name: 'German',
  // },
]

const Languages: FC = () => {
  const lang = useLang()
  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])
  const currentLanguage = languages.find((x) => x.lang === lang)
  /**
   * changes the current language for app
   * @param lang shortform for a language
   */
  const changeLanguage = (lang: string) => {
    setLanguage(lang)
  }
  return (
    <div>
      <button
        className='btn btn-light btn-active-light-primary btn-sm px-3'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-start'
      >
        {currentLanguage?.lang?.toUpperCase()}
      </button>
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-140px py-4'
        data-kt-menu='true'
      >
        {languages.map((language) => (
          <div className='menu-item px-3' key={language.lang}>
            <button
              className={`menu-link ${
                language.lang === currentLanguage?.lang ? 'active' : ''
              } px-3 btn btn-transparent btn-active-light-primary btn-sm w-100`}
              onClick={() => changeLanguage(language.lang)}
            >
              {language.name}
            </button>
          </div>
        ))}
      </div>
    </div>
  )
}

export {Languages}
