import React from 'react'
import {QueryRequestProvider} from './core/RolesRequestProvider'
import {QueryResponseProvider} from './core/RolesResponseProvider'
import {RolesProvider} from './core/Roles'
import RolesLayout from './RolesLayout'
import WithPermissionRestricted from '../../../../general/hoc/WithPermissionRestricted'

const RolesWrapper = () => {
  return (
    <RolesProvider>
      <QueryRequestProvider>
        <QueryResponseProvider>
          <RolesLayout />
        </QueryResponseProvider>
      </QueryRequestProvider>
    </RolesProvider>
  )
}

export default WithPermissionRestricted(RolesWrapper, 'ROLES.LIST', ['roles', 'GetAllRoles'])
