export const badgeClasses = [
  'primary',
  'danger',
  'success',
  'info',
  'warning',
  'dark-turquoise',
  'crimson',
  'dark-magenta',
  'dark-orange',
  'medium-orchid',
  'deep-pink',
  'saddle-brown',
  'tomato',
  'dark',
  'steel-blue',
  'orange-red',
  'dark-orchid',
  'hot-pink',
]

// breadcrumb seperator
export const separator = {
  title: '',
  path: '',
  isActive: false,
  isSeparator: true,
}

export const perPageArr = [10, 20, 50, 100]

export const keysforDateFormatValue = {
  transaction: ['createdAt', 'completedAt'],
  activities: ['createdAt', 'updatedAt'],
  properties: ['startDate', 'endDate'],
  logistics: [
    'createdDateTime',
    'deliveryDateTime',
    'checkinDateTime',
    'pickupDateTime',
    'returnDateTime',
    'dropOffDateTime',
  ],
}
