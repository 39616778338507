import PermissionsListFilter from '../PermissionsListFilter'
import {PermissionsListSearchComponent} from './PermissionsListSearchComponent'

const PermissionsListHeader = () => {
  return (
    <div className='card-header border-0 pt-6'>
      <PermissionsListSearchComponent />
      <div className='card-toolbar'>
        <PermissionsListFilter />
      </div>
    </div>
  )
}

export {PermissionsListHeader}
