import React from 'react'
import {useQueryResponse} from './core/RolesResponseProvider'
import {toAbsoluteUrl} from '../../../../general/helpers'
import Modal from '../components/Modal'
import EditRoleForm from './components/EditRoleForm'
import {useRoles} from './core/Roles'
import RoleCard from './components/RoleCard'
import {PageTitle} from '../../../../general/layout/core'
import {useIntl} from 'react-intl'
import {Card} from 'react-bootstrap'
import {useAuth} from '../../auth'
import {PERMISSION_ARRKEY} from '../../auth/core/constant'

const RolesLayout = () => {
  const {response, isLoading} = useQueryResponse()
  const {itemIdForUpdate, saveItemIdForUpdate} = useRoles()
  const intl = useIntl()
  const {getAllowedPermissionValue} = useAuth()
  return (
    <>
      <PageTitle>{intl.formatMessage({id: 'ROLES.LIST'})}</PageTitle>
      <div className='row row-cols-1 row-cols-md-2 row-cols-xl-3 g-5 g-xl-9'>
        {isLoading ? (
          <Card>
            <Card.Body className='d-flex justify-content-center align-items-center'>
              <div className='d-flex justify-content-center align-items-center'>
                <span className='me-2'>Please wait...</span>
                <span className='spinner-border spinner-border-md align-middle text-primary' />
              </div>
            </Card.Body>
          </Card>
        ) : (
          response?.roles?.map((role) => (
            <div className='col-md-4' key={role._id}>
              <RoleCard role={role} />
            </div>
          ))
        )}

        {getAllowedPermissionValue(PERMISSION_ARRKEY.ADD_ROLE) && (
          <div className='col-md-4'>
            <div className='card h-md-100'>
              <div className='card-body d-flex flex-center'>
                <button
                  type='button'
                  className='btn btn-clear d-flex flex-column flex-center'
                  onClick={() => {
                    saveItemIdForUpdate(null)
                  }}
                >
                  <img
                    src={toAbsoluteUrl('/media/misc/united-palms-8.png')}
                    alt=''
                    className='mw-100 mh-150px mb-7'
                  />
                  <div className='fw-bold fs-3 text-gray-600 text-hover-primary'>
                    {intl.formatMessage({id: 'ROLE.NEW.ADD'})}
                  </div>
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      {itemIdForUpdate !== undefined && (
        <Modal
          heading={
            itemIdForUpdate === null
              ? intl.formatMessage({id: 'ROLE.ADD'})
              : intl.formatMessage({id: 'ROLE.UPDATE'})
          }
          onClose={() => {
            saveItemIdForUpdate(undefined)
          }}
          size='mw-750px'
        >
          <EditRoleForm />
        </Modal>
      )}
    </>
  )
}

export default RolesLayout
