// @ts-nocheck
import clsx from 'clsx'
import {FC} from 'react'
import {Row} from 'react-table'
import {User} from '../../core/_models'

type Props = {
  row: Row<User>
  onRowClick?: () => void
}

const CustomRow: FC<Props> = ({row, onRowClick}) => {
  return (
    <tr
      {...row.getRowProps()}
      onClick={() => {
        onRowClick?.()
      }}
      className={clsx({'cursor-pointer': onRowClick !== undefined})}
    >
      {row.cells.map((cell) => {
        return (
          <td
            {...cell.getCellProps()}
            className={clsx({'text-end min-w-100px': cell.column.id === 'actions'})}
          >
            {cell.render('Cell')}
          </td>
        )
      })}
    </tr>
  )
}

export {CustomRow}
