import React from 'react'

export const NoInternetConnection = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    version='1.1'
    width='300px'
    height='300px'
    style={{
      shapeRendering: 'geometricPrecision',
      textRendering: 'geometricPrecision',
      fillRule: 'evenodd',
      clipRule: 'evenodd',
    }}
  >
    <g>
      <path
        style={{opacity: 1}}
        fill='#fefefe'
        d='M -0.5,-0.5 C 99.5,-0.5 199.5,-0.5 299.5,-0.5C 299.5,99.5 299.5,199.5 299.5,299.5C 199.5,299.5 99.5,299.5 -0.5,299.5C -0.5,199.5 -0.5,99.5 -0.5,-0.5 Z'
      />
    </g>
    <g>
      <path
        style={{opacity: 1}}
        fill='#f5c54a'
        d='M 253.5,85.5 C 249.382,84.0134 245.382,84.3467 241.5,86.5C 242.293,92.758 241.127,98.758 238,104.5C 233.667,110.167 228.667,115.167 223,119.5C 221.007,121.818 219.841,124.484 219.5,127.5C 217.787,128.635 217.12,130.302 217.5,132.5C 215.036,138.09 212.036,143.423 208.5,148.5C 200.378,151.504 196.211,148.838 196,140.5C 198.121,136.593 199.955,132.593 201.5,128.5C 202.628,123.938 203.295,119.272 203.5,114.5C 204.317,107.535 205.65,100.535 207.5,93.5C 206.207,94.842 205.374,96.5087 205,98.5C 204.034,103.522 203.201,108.522 202.5,113.5C 199.923,119.66 196.59,125.327 192.5,130.5C 185.911,135.233 178.911,139.4 171.5,143C 158.162,143.987 156.162,139.654 165.5,130C 172.419,125.624 179.252,121.124 186,116.5C 192.556,108.058 197.556,98.7245 201,88.5C 209.993,70.1676 223.993,57.5009 243,50.5C 254.875,53.529 263.708,60.529 269.5,71.5C 264.443,76.5618 259.11,81.2285 253.5,85.5 Z'
      />
    </g>
    <g>
      <path
        style={{opacity: 1}}
        fill='#201f20'
        d='M 107.5,69.5 C 108.768,71.9052 109.435,74.5718 109.5,77.5C 118.517,78.6931 118.184,79.6931 108.5,80.5C 107.213,89.7145 106.546,89.7145 106.5,80.5C 103.608,80.2789 100.941,79.4456 98.5,78C 104.575,78.4248 107.575,75.5914 107.5,69.5 Z'
      />
    </g>
    <g>
      <path
        style={{opacity: 1}}
        fill='#373639'
        d='M 144.5,70.5 C 145.103,72.1897 146.103,73.6897 147.5,75C 145.096,76.8209 142.763,76.6543 140.5,74.5C 142.421,73.6761 143.754,72.3428 144.5,70.5 Z'
      />
    </g>
    <g>
      <path
        style={{opacity: 1}}
        fill='#080707'
        d='M 253.5,85.5 C 255.396,101.373 249.563,113.373 236,121.5C 235.333,123.833 235.333,126.167 236,128.5C 237.646,132.29 239.146,136.123 240.5,140C 240.67,143.155 239.17,144.655 236,144.5C 234.033,144.458 232.199,143.958 230.5,143C 226.197,138.206 222.53,133.039 219.5,127.5C 219.841,124.484 221.007,121.818 223,119.5C 228.667,115.167 233.667,110.167 238,104.5C 241.127,98.758 242.293,92.758 241.5,86.5C 245.382,84.3467 249.382,84.0134 253.5,85.5 Z'
      />
    </g>
    <g>
      <path
        style={{opacity: 1}}
        fill='#866c27'
        d='M 207.5,93.5 C 207.284,91.3193 208.117,89.6526 210,88.5C 210.249,90.668 209.416,92.3347 207.5,93.5 Z'
      />
    </g>
    <g>
      <path
        style={{opacity: 1}}
        fill='#796124'
        d='M 207.5,93.5 C 205.65,100.535 204.317,107.535 203.5,114.5C 203.5,113.833 203.167,113.5 202.5,113.5C 203.201,108.522 204.034,103.522 205,98.5C 205.374,96.5087 206.207,94.842 207.5,93.5 Z'
      />
    </g>
    <g>
      <path
        style={{opacity: 1}}
        fill='#fbe2a0'
        d='M 207.5,101.5 C 209.288,101.215 210.955,101.548 212.5,102.5C 211.667,102.833 210.833,103.167 210,103.5C 208.624,103.316 207.791,102.649 207.5,101.5 Z'
      />
    </g>
    <g>
      <path
        style={{opacity: 1}}
        fill='#15130e'
        d='M 202.5,113.5 C 203.167,113.5 203.5,113.833 203.5,114.5C 203.295,119.272 202.628,123.938 201.5,128.5C 198.308,128.405 195.308,129.071 192.5,130.5C 196.59,125.327 199.923,119.66 202.5,113.5 Z'
      />
    </g>
    <g>
      <path
        style={{opacity: 1}}
        fill='#181818'
        d='M 146.5,130.5 C 146.768,132.099 146.434,133.432 145.5,134.5C 143.192,131.185 140.859,127.851 138.5,124.5C 139.891,122.11 141.891,120.443 144.5,119.5C 145.706,123.072 146.373,126.738 146.5,130.5 Z'
      />
    </g>
    <g>
      <path
        style={{opacity: 1}}
        fill='#222223'
        d='M 137.5,139.5 C 137.5,140.167 137.167,140.5 136.5,140.5C 133.327,139.83 130.327,138.664 127.5,137C 126.151,135.365 126.318,133.865 128,132.5C 131.044,135.051 134.211,137.384 137.5,139.5 Z'
      />
    </g>
    <g>
      <path
        style={{opacity: 1}}
        fill='#8b6f29'
        d='M 167.5,131.5 C 168.497,131.47 169.164,131.97 169.5,133C 168.881,137.361 166.547,139.695 162.5,140C 166.089,138.326 167.755,135.493 167.5,131.5 Z'
      />
    </g>
    <g>
      <path
        style={{opacity: 1}}
        fill='#6a6a6a'
        d='M 146.5,130.5 C 147.629,132.628 147.796,134.961 147,137.5C 146.487,136.473 145.987,135.473 145.5,134.5C 146.434,133.432 146.768,132.099 146.5,130.5 Z'
      />
    </g>
    <g>
      <path
        style={{opacity: 1}}
        fill='#0d0c0b'
        d='M 217.5,132.5 C 219.954,139.141 223.121,145.474 227,151.5C 228.265,160.091 224.765,162.924 216.5,160C 213.197,156.545 210.531,152.711 208.5,148.5C 212.036,143.423 215.036,138.09 217.5,132.5 Z'
      />
    </g>
    <g>
      <path
        style={{opacity: 1}}
        fill='#3b3b3d'
        d='M 99.5,136.5 C 100.306,137.868 101.306,139.201 102.5,140.5C 101.354,141.719 100.354,143.052 99.5,144.5C 98.937,142.291 97.6036,140.791 95.5,140C 96.7932,139.51 98.1266,139.343 99.5,139.5C 99.5,138.5 99.5,137.5 99.5,136.5 Z'
      />
    </g>
    <g>
      <path
        style={{opacity: 1}}
        fill='#a1a1a1'
        d='M 137.5,139.5 C 138.973,140.072 140.307,140.905 141.5,142C 139.465,142.599 137.799,142.099 136.5,140.5C 137.167,140.5 137.5,140.167 137.5,139.5 Z'
      />
    </g>
    <g>
      <path
        style={{opacity: 1}}
        fill='#856b27'
        d='M 203.5,136.5 C 203.897,135.475 204.563,135.308 205.5,136C 209.653,140.223 209.319,144.057 204.5,147.5C 204.167,147.333 203.833,147.167 203.5,147C 207.367,143.298 207.367,139.798 203.5,136.5 Z'
      />
    </g>
    <g>
      <path
        style={{opacity: 1}}
        fill='#a195c8'
        d='M 151.5,151.5 C 145.155,151.653 138.822,151.986 132.5,152.5C 138.776,150.56 145.109,150.226 151.5,151.5 Z'
      />
    </g>
    <g>
      <path
        style={{opacity: 1}}
        fill='#6958a7'
        d='M 151.5,151.5 C 157.356,151.854 159.523,154.854 158,160.5C 155.157,163.163 151.991,165.163 148.5,166.5C 144.418,166.344 140.418,166.844 136.5,168C 130.65,170.222 124.983,172.722 119.5,175.5C 114.916,170.329 114.582,170.662 118.5,176.5C 114.544,178.788 111.211,181.788 108.5,185.5C 107.833,185.833 107.167,186.167 106.5,186.5C 101.684,186.58 97.0169,187.414 92.5,189C 97.1231,188.774 101.79,188.607 106.5,188.5C 107.833,188.5 109.167,188.5 110.5,188.5C 118.552,187.927 126.552,186.927 134.5,185.5C 138.875,185.347 142.708,186.68 146,189.5C 146.6,192.095 146.1,194.429 144.5,196.5C 139.653,199.285 134.653,201.618 129.5,203.5C 123.507,203.999 117.507,204.499 111.5,205C 102.801,218.174 91.4679,221.008 77.5,213.5C 71.307,211.905 65.307,212.405 59.5,215C 68.5601,217.95 71.7268,224.116 69,233.5C 68.227,237.532 67.3937,241.532 66.5,245.5C 56.5373,250.031 46.8706,249.197 37.5,243C 35.2953,240.46 32.7953,238.293 30,236.5C 31.8238,218.029 37.8238,201.029 48,185.5C 55.5218,176.639 64.6884,170.139 75.5,166C 94.5328,161.497 113.533,156.997 132.5,152.5C 138.822,151.986 145.155,151.653 151.5,151.5 Z'
      />
    </g>
    <g>
      <path
        style={{opacity: 1}}
        fill='#060606'
        d='M 148.5,166.5 C 151.985,167.848 155.152,169.848 158,172.5C 159.193,177.943 157.027,181.11 151.5,182C 144.39,183.172 137.556,182.339 131,179.5C 130.5,179.667 130,179.833 129.5,180C 131.373,181.704 133.04,183.537 134.5,185.5C 126.552,186.927 118.552,187.927 110.5,188.5C 110.068,187.29 109.401,186.29 108.5,185.5C 111.211,181.788 114.544,178.788 118.5,176.5C 119.167,176.5 119.5,176.167 119.5,175.5C 124.983,172.722 130.65,170.222 136.5,168C 140.418,166.844 144.418,166.344 148.5,166.5 Z'
      />
    </g>
    <g>
      <path
        style={{opacity: 1}}
        fill='#a198c1'
        d='M 119.5,175.5 C 119.5,176.167 119.167,176.5 118.5,176.5C 114.582,170.662 114.916,170.329 119.5,175.5 Z'
      />
    </g>
    <g>
      <path
        style={{opacity: 1}}
        fill='#dfdde6'
        d='M 108.5,185.5 C 109.401,186.29 110.068,187.29 110.5,188.5C 109.167,188.5 107.833,188.5 106.5,188.5C 106.5,187.833 106.5,187.167 106.5,186.5C 107.167,186.167 107.833,185.833 108.5,185.5 Z'
      />
    </g>
    <g>
      <path
        style={{opacity: 1}}
        fill='#a9a0cd'
        d='M 106.5,186.5 C 106.5,187.167 106.5,187.833 106.5,188.5C 101.79,188.607 97.1231,188.774 92.5,189C 97.0169,187.414 101.684,186.58 106.5,186.5 Z'
      />
    </g>
    <g>
      <path
        style={{opacity: 1}}
        fill='#aaa0cd'
        d='M 138.5,190.5 C 139.978,190.238 141.311,190.571 142.5,191.5C 138.987,192.294 135.653,193.128 132.5,194C 134.257,192.25 136.257,191.084 138.5,190.5 Z'
      />
    </g>
    <g>
      <path
        style={{opacity: 1}}
        fill='#191819'
        d='M 144.5,196.5 C 145.154,200.683 143.488,203.516 139.5,205C 135.974,205.69 132.64,205.19 129.5,203.5C 134.653,201.618 139.653,199.285 144.5,196.5 Z'
      />
    </g>
    <g>
      <path
        style={{opacity: 1}}
        fill='#070708'
        d='M 77.5,213.5 C 76.4595,223.195 75.1262,232.862 73.5,242.5C 71.3379,244.02 69.0046,245.02 66.5,245.5C 67.3937,241.532 68.227,237.532 69,233.5C 71.7268,224.116 68.5601,217.95 59.5,215C 65.307,212.405 71.307,211.905 77.5,213.5 Z'
      />
    </g>
  </svg>
)
