import React from 'react'
import {useNavigate} from 'react-router-dom'
import {Store} from '../../../core/_models'
import {SELECTED_STORAGE_KEYS} from '../../../../auth/core/constant'
import {useStoresQueryRequest} from '../../core/StoresRequestProvider'
interface Props {
  store: Store
}
const StoresRefActionCell = (props: Props) => {
  const navigate = useNavigate()
  const {state} = useStoresQueryRequest()
  return (
    <button
      onClick={() => {
        sessionStorage.setItem(SELECTED_STORAGE_KEYS.STORES_FILTERS_KEY, JSON.stringify(state))
        navigate(`view/${props.store.id}`)
      }}
      className='p-0 border-0 bg-white text-primary fw-bold'
    >
      {props.store.refNumber}
    </button>
  )
}

export default StoresRefActionCell
