import {FC} from 'react'
import {getFormattedDateTime, parseJwt} from '../../../core/helper'
import {useAuth} from '../../../../auth'

type Props = {
  createdAt?: string
}

const CreatedAtCell: FC<Props> = ({createdAt}) => {
  const {auth} = useAuth()
  const {activeRetailer} = parseJwt(auth?.accessToken ?? '')

  const formattedDate = getFormattedDateTime(
    activeRetailer?.locale,
    activeRetailer?.timeZone,
    createdAt ?? ''
  )

  return <div>{formattedDate}</div>
}

export {CreatedAtCell}
