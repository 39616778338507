const QUERIES = {
  USERS_LIST: 'users-list',
  ROLES_LIST: 'roles-list',
  PERMISSIONS_LIST: 'permissions-list',
  STORES_LIST: 'stores-list',
  STORES_DETAILS: 'stores-details',
  STORES_PROPERTIES: 'stores-properties',
  LOGISTICS_LIST: 'logistics-list',
}

export {QUERIES}
