import {IntlShape} from 'react-intl'
import {separator} from '../modules/panel/core/globals'
/**
 * returns breadcrumbs array 
 * @param intl Intl object for internationalization
 * @param key 
 * @returns breadcrumbs array
 */
export const getBreadcrumbs = (intl: IntlShape, key: string) => {
  const breadCrumbsData = {
    viewTransaction: [
      {
        title: intl.formatMessage({id: 'TRANSACTIONS'}),
        path: `/transactions`,
        isActive: false,
      },
      separator,
    ],
    viewStore: [
      {
        title: intl.formatMessage({id: 'STORES.LIST'}),
        path: `/stores-management`,
        isActive: false,
      },
      separator,
    ],
    viewRole: [
      {
        title: intl.formatMessage({id: 'ROLES.LIST'}),
        path: `/users-management/roles`,
        isActive: false,
      },
      separator,
    ],
  }

  return breadCrumbsData?.[key] ?? []
}
