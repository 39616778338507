import React, {createContext, useContext, useEffect, useState} from 'react'
import {get_permission_schema} from '../../core/_request'

interface RolesContextI {
  itemIdForUpdate: string | null | undefined
  saveItemIdForUpdate: (id: string | null | undefined) => void
  defaultPermissionsLoading: boolean
  defaultPermissions: any[]
  fetchPermissionSchema: () => void
}

const RolesContext = createContext<RolesContextI>({
  itemIdForUpdate: undefined,
  saveItemIdForUpdate: () => {},
  defaultPermissionsLoading: false,
  defaultPermissions: [],
  fetchPermissionSchema: () => {},
})

const useRoles = () => {
  return useContext(RolesContext)
}

const RolesProvider = ({children}) => {
  const [itemIdForUpdate, setItemIdForUpdate] = useState<string | null | undefined>()
  const [defaultPermissionsLoading, setDefaultPermissionsLoading] = useState<boolean>(false)
  const [defaultPermissions, setDefaultPermissions] = useState<any[]>([])
  const saveItemIdForUpdate = (id: string | null | undefined) => {
    setItemIdForUpdate(id)
  }
  /**
   * fetching permission schema to map permissions checkboxes
   */
  const fetchPermissionSchema = async () => {
    setDefaultPermissionsLoading(true)
    try {
      const {permissions: permissionSchema} = await get_permission_schema()
      setDefaultPermissions([...permissionSchema])
    } catch {
    } finally {
      setDefaultPermissionsLoading(false)
    }
  }

  useEffect(() => {
    fetchPermissionSchema()
  }, [])

  return (
    <RolesContext.Provider
      value={{
        itemIdForUpdate,
        saveItemIdForUpdate,
        defaultPermissions,
        defaultPermissionsLoading,
        fetchPermissionSchema,
      }}
    >
      {children}
    </RolesContext.Provider>
  )
}

export {useRoles, RolesProvider}
