import React from 'react'
import {Card} from 'react-bootstrap'
import {LabelsArrOfObj, Store} from '../../core/_models'
import {useIntl} from 'react-intl'

interface Props {
  storeDetails: Store
  labels: {[key: string]: string | LabelsArrOfObj}
}

const StoreCard = (props: Props) => {
  const {storeDetails, labels} = props
  const intl = useIntl()
  const detailKeysToBeShown = [
    'id',
    'storeGln',
    'houseNumber',
    'streetName',
    'city',
    'postalCode',
    'stateCode',
    'stateName',
  ]
  // map for rendering store logo
  const logoMap = {
    E: '/media/logos/stores/kkiosk.svg',
    A: '/media/logos/stores/avec.svg',
    P: '/media/logos/stores/pressbooks.png',
    T: '/media/logos/stores/gas_station.png',
    F: '/media/logos/stores/generic_store.png',
  }

  return (
    <Card>
      <Card.Body className='pt-15'>
        <div className='d-flex flex-center flex-column mb-5'>
          <div className='symbol symbol-85px mb-7 border border-1 border-gray-400 bg-gray-300 p-1 rounded-circle h-125px w-125px d-flex align-items-center justify-content-center'>
            <img
              src={logoMap?.[storeDetails.storeFormat] ?? ``}
              alt='store'
              style={{objectFit: 'contain'}}
            />
          </div>
          <div className='fs-3 text-gray-800 text-hover-primary fw-bold mb-1'>
            {storeDetails.refNumber}
          </div>
          <div className='fs-5 fw-semibold text-muted mb-6'>{storeDetails.name}</div>
        </div>

        <div className='separator separator-dashed my-3' />

        <div id='store_view_details'>
          {detailKeysToBeShown.map((item) => (
            <div className='fs-6' key={item}>
              <div className='fw-bold mt-5'>
                {labels?.[item]
                  ? intl.formatMessage({
                      id: labels?.[item].toString(),
                    })
                  : ''}
              </div>
              <div className='text-gray-600'>{storeDetails?.[item] ?? 'N/A'}</div>
            </div>
          ))}
        </div>
      </Card.Body>
    </Card>
  )
}

export default StoreCard
