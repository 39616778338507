import {Route} from 'react-router-dom'
import React from 'react'
import Report from '../analytics-setup/Report'
import {ErrorBoundary} from 'react-error-boundary'
import ErrorFallback from '../../general/layout/core/ErrorFallback'

export const getReportRoutes = (menuStructure) => {
  return menuStructure.map((item) => {
    const {to, pageName, children, pageTitleId} = item

    if (children && children.length > 0) {
      const childRoutes = getReportRoutes(children)
      return childRoutes
    } else {
      return (
        <Route
          path={`/analytics${to}`}
          element={
            <ErrorBoundary fallback={<ErrorFallback />} key={pageName}>
              <Report pageName={pageName} pageTitleId={pageTitleId} key={pageName} />
            </ErrorBoundary>
          }
          key={pageName}
        />
      )
    }
  })
}
