import clsx from 'clsx'
import React, {ChangeEvent, Fragment, HTMLInputTypeAttribute, InputHTMLAttributes} from 'react'
import {useIntl} from 'react-intl'
import ClearCross from '../../panel/components/ClearCross'

interface Props {
  id?: string
  label?: string
  labelId?: string
  isValid?: boolean
  isInvalid?: boolean
  attrs?: InputHTMLAttributes<HTMLInputElement>
  onChange?: (value: string, event?: ChangeEvent<HTMLInputElement>) => void
  disabled?: boolean
  placeHolder?: string
  placeHolderId?: string
  errorStrId?: string
  required?: boolean
  type?: HTMLInputTypeAttribute
  isClearable?: boolean
}

const Input = (props: Props) => {
  const {
    id,
    label,
    labelId,
    isValid,
    isInvalid,
    attrs,
    onChange,
    disabled,
    placeHolder,
    placeHolderId,
    errorStrId,
    required,
    type,
    isClearable,
  } = props
  const intl = useIntl()
  /**
   * finds valid numeric data
   * @param inputString any string value
   * @returns the matched part of string validated with number with one decimal
   */
  function getNumericString(inputString) {
    if (inputString === '') return inputString
    // Use the match() method to get the matching regex part
    const matches = inputString.match(/^(?!.*\..*\.)(?=.*\d)\d*(?:\.\d*)?$/)
    return matches ? matches[0] : attrs?.value
  }

  return (
    <Fragment key={id}>
      <label className='form-label fs-6 text-dark mb-2'>
        <span className={clsx({required: required})}>
          {label ?? intl.formatMessage({id: labelId})}
        </span>
      </label>

      <div className='position-relative'>
        <input
          className={clsx(
            'form-control form-control-solid',
            {'is-invalid': isInvalid},
            {
              'is-valid': isValid,
            }
          )}
          {...attrs}
          onChange={(e) =>
            onChange?.(type === 'number' ? getNumericString(e.target.value) : e.target.value, e)
          }
          disabled={disabled}
          placeholder={placeHolder ?? intl.formatMessage({id: placeHolderId})}
          type={type === 'number' ? 'text' : type ?? 'text'}
        />
        {isClearable && attrs?.value && <ClearCross onClick={() => onChange?.('')} />}
      </div>
      {isInvalid && errorStrId ? (
        <div className='fv-plugins-message-container'>
          <div className='fv-help-block'>
            <span role='alert'>{intl.formatMessage({id: errorStrId})}</span>
          </div>
        </div>
      ) : (
        <></>
      )}
    </Fragment>
  )
}

export default Input
