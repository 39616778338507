import React, {useState} from 'react'
import StoresListFilters from './StoresListFilters'
import {KTIcon, isNotEmpty} from '../../../../../general/helpers'
import {export_stores} from '../../core/_request'
import {useStoresQueryResponse} from '../core/StoresResponseProvider'
import {useIntl} from 'react-intl'
import {useStoresQueryRequest} from '../core/StoresRequestProvider'
import {toast} from 'react-toastify'
import {useAuth} from '../../../auth'
import {PERMISSION_ARRKEY} from '../../../auth/core/constant'

const StoresListHeader = () => {
  const [exportLoading, setExportLoading] = useState(false)
  const {query} = useStoresQueryResponse()
  const {state} = useStoresQueryRequest()
  const {getAllowedPermissionValue} = useAuth()
  const intl = useIntl()
  /**
   * starts export stores data
   */
  const triggerStoresExport = async () => {
    const isFiltersEmpty =
      Object.entries(state).find((obj) => {
        return obj[0] !== 'page' && obj[0] !== 'size' && isNotEmpty(obj[1])
      }) === undefined
    if (isFiltersEmpty) {
      toast.error(intl.formatMessage({id: 'FILTER.SELECT'}))
      return
    }
    setExportLoading(true)
    try {
      await export_stores(query)
    } catch (e) {
    } finally {
      setExportLoading(false)
    }
  }
  return (
    <div className='card-header border-0 pt-6'>
      <div className='card-toolbar w-100'>
        <div className='d-flex justify-content-end w-100'>
          <StoresListFilters />
          {getAllowedPermissionValue(PERMISSION_ARRKEY.EXPORT_STORES) && (
            <button
              disabled={exportLoading}
              type='button'
              className='btn btn-light-primary me-3'
              onClick={triggerStoresExport}
            >
              <KTIcon iconName='exit-up' className='fs-2' />
              {intl.formatMessage({id: 'EXPORT'})}
              {exportLoading && (
                <span className='spinner-border spinner-border-sm align-middle text-primary ms-2' />
              )}
            </button>
          )}
        </div>
      </div>
    </div>
  )
}

export default StoresListHeader
