import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {KTIcon} from '../../../../../general/helpers'
import Modal from '../../components/Modal'
import {get_store_filters} from '../../core/_request'
import {FilterI} from '../../core/_models'
import Input from '../../../auth/components/Input'
import Select from '../../../auth/components/Select'
import {getFilterOptionsArr} from '../../core/helper'
import CardLoading from '../../components/CardLoading'
import {useStoresQueryRequest} from '../core/StoresRequestProvider'
import {useStoresQueryResponseLoading} from '../core/StoresResponseProvider'

const StoresListFilters = () => {
  const [filtersOpen, setFiltersOpen] = useState<boolean>(false)
  const intl = useIntl()
  const [loading, setLoading] = useState<boolean>(false)
  const [filterData, setFilterData] = useState<FilterI[]>([])
  const [filters, setFilters] = useState<{[key: string]: string | number}>({})
  const {updateState, state} = useStoresQueryRequest()
  const storesloading = useStoresQueryResponseLoading()
  /**
   * fetching filters for stores
   */
  const fetchFilters = async () => {
    setLoading(true)
    try {
      const response = await get_store_filters()
      setFilterData(response.sort((a, b) => a.order - b.order))
    } catch {
    } finally {
      setLoading(false)
    }
  }
  /**
   * creates required filter object to fetch filtered stores
   */
  const applyFilters = () => {
    const tempFilters = Object.entries(filters).reduce(
      (prev, curr) => ({
        ...prev,
        [curr?.[0] === 'name' ? `${curr?.[0]}.contains` : `${curr?.[0]}.equals`]: curr?.[1],
      }),
      {}
    )
    updateState(tempFilters)
    setFiltersOpen(false)
  }
  /**
   * resets all the filters applied
   */
  const resetFilters = () => {
    const tempFilters = {
      page: 1,
      size: state.size,
    }
    updateState({...tempFilters}, false)
    setFilters({})
    setFiltersOpen(false)
  }

  useEffect(() => {
    fetchFilters()
  }, [])

  useEffect(() => {
    const {page, size, ...filterState} = state
    const tempFilters = Object.entries(filterState).reduce(
      (prev, curr) => ({
        ...prev,
        [curr?.[0]?.split('.')?.[0]]: curr?.[1],
      }),
      {}
    )
    !filtersOpen && setFilters(tempFilters)
    // eslint-disable-next-line
  }, [filtersOpen])
  return (
    <>
      <button
        disabled={storesloading}
        type='button'
        className='btn btn-light-primary me-3'
        onClick={() => setFiltersOpen((prev) => !prev)}
      >
        <KTIcon iconName='filter' className='fs-2' />
        {intl.formatMessage({id: 'FILTERS'})}
      </button>

      {filtersOpen ? (
        <Modal
          heading={intl.formatMessage({id: 'FILTERS'})}
          onClose={() => {
            setFiltersOpen(false)
          }}
          size='mw-750px'
        >
          <div className='row align-items-center'>
            {loading ? (
              <CardLoading />
            ) : (
              <>
                {filterData.map((filterItem) => (
                  <div className=' col-md-4 col-sm-12 col-xs-12 mb-8' key={filterItem?._id}>
                    {filterItem.data?.length > 0 ? (
                      <Select
                        label={filterItem.name}
                        placeHolder={filterItem.name}
                        attrs={{name: filterItem.name, value: filters?.[filterItem.slug] ?? ''}}
                        onChange={(e) =>
                          setFilters((prev) => ({...prev, [filterItem.slug]: e.target.value}))
                        }
                        options={getFilterOptionsArr(filterItem?.data)}
                        isClearable
                        onClear={() => {
                          setFilters((prev) => ({...prev, [filterItem.slug]: ''}))
                        }}
                      />
                    ) : (
                      <Input
                        label={filterItem.name}
                        placeHolder={filterItem.name}
                        attrs={{name: filterItem.name, value: filters?.[filterItem.slug] ?? ''}}
                        onChange={(value, e) =>
                          setFilters((prev) => ({...prev, [filterItem.slug]: value}))
                        }
                        isClearable
                      />
                    )}
                  </div>
                ))}
                <div className='row gap-5 justify-content-end'>
                  <button className={'col-md-2 btn btn-light'} onClick={resetFilters}>
                    <span className='indicator-label'>{intl.formatMessage({id: 'RESET'})}</span>
                  </button>
                  <button
                    className={' col-md-2 btn btn-primary'}
                    style={{
                      height: '100%',
                      minHeight: 'min-content',
                    }}
                    onClick={applyFilters}
                  >
                    {intl.formatMessage({id: 'APPLY'})}
                  </button>
                </div>
              </>
            )}
          </div>
        </Modal>
      ) : (
        <></>
      )}
    </>
  )
}

export default StoresListFilters
