import React, {useMemo} from 'react'
import {PageTitle} from '../layout/core/PageData'
import {KTCard, KTCardBody, KTIcon} from '../helpers'
import {useIntl} from 'react-intl'
import {useAuth} from '../../app/modules/auth'

/**
 * checks for mentioned permission in using nested keys array to restrict any component from access
 * @param OriginalComponent any list page component
 * @param titleId string for translated page title
 * @param keys array of nested keys
 * @returns component or restricts it based on permission allowed
 */
const WithPermissionRestricted: (
  OriginalComponent: React.FunctionComponent,
  titleId: string,
  keys: string[]
) => React.FunctionComponent = (OriginalComponent, titleId, keys) => {
  const PermissionRestricted = () => {
    const {allowedPermissions, getAllowedPermissionValue} = useAuth()

    // finding if the key required is true for permission
    const allowed = useMemo(() => {
      return getAllowedPermissionValue(keys)
      // eslint-disable-next-line
    }, [allowedPermissions])

    const intl = useIntl()
    return allowed ? (
      <OriginalComponent />
    ) : (
      <>
        <PageTitle>{intl.formatMessage({id: titleId})}</PageTitle>
        <KTCard className='min-h-450px'>
          <KTCardBody className='d-flex align-items-center justify-content-center'>
            <div className='d-flex flex-column align-items-center gap-8'>
              <div className='d-flex align-items-center gap-1'>
                <KTIcon iconName='shield-cross' className='fs-2qx' />
                <p className='m-0 fs-4'>{intl.formatMessage({id: 'PERMISSION.RESTRICTED'})}</p>
              </div>
              <p className='m-0 fs-4'>{intl.formatMessage({id: 'PAGE.NO.ACCESS'})}</p>
            </div>
          </KTCardBody>
        </KTCard>
      </>
    )
  }
  return PermissionRestricted
}
export default WithPermissionRestricted
