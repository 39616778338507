import clsx from 'clsx'
import React from 'react'

interface Props {
  tabsList: any[]
  selectedTab: string
  onChange: (id: string) => void
}

const Tabs = (props: Props) => {
  const {tabsList, selectedTab, onChange} = props
  return (
    <ul
      className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-8'
      role='tablist'
    >
      {tabsList.map((tab) => (
        <li className='nav-item' role='presentation' key={tab.id}>
          <button
            className={clsx(`nav-link text-active-primary pb-4`, {active: selectedTab === tab.id})}
            data-bs-toggle='tab'
            aria-selected='false'
            role='tab'
            onClick={() => onChange(tab.id)}
          >
            {tab?.title}
          </button>
        </li>
      ))}
    </ul>
  )
}

export default Tabs
