import React, {useEffect, useMemo} from 'react'
import {
  useQueryResponseData,
  useQueryResponseLoading,
  useQueryResponseTotalCount,
} from '../core/PermissionsResponseProvider'
import {permissionColumns} from './columns/_columns'
import {ColumnInstance, Row, useTable} from 'react-table'
import {KTCardBody} from '../../../../../general/helpers'
import {CustomHeaderColumn} from '../../users/table/columns/CustomHeaderColumn'
import {CustomRow} from '../../users/table/columns/CustomRow'
import ListLoading from '../../dashboad/components/loading/ListLoading'
import {useIntl} from 'react-intl'
import {Permission} from '../../core/_models'
import {get_roles} from '../../core/_request'
import {usePermissions} from '../core/Permissions'
import {badgeClasses} from '../../core/globals'
import {Card} from 'react-bootstrap'
import {useQueryRequest} from '../core/PermissionsRequestProvider'
import Pagination from '../../components/Pagination'
import {useAuth} from '../../../auth'
import {PERMISSION_ARRKEY} from '../../../auth/core/constant'

const PermissionsListTable = () => {
  const permissionsList = useQueryResponseData()
  const totalCount = useQueryResponseTotalCount()
  const isLoading = useQueryResponseLoading()
  const {loading, state, updateState} = useQueryRequest()
  const {saveBadgesData, saveRoles} = usePermissions()
  const {getAllowedPermissionValue} = useAuth()
  const data = useMemo(() => permissionsList, [permissionsList])
  const columns = useMemo(() => {
    if (!getAllowedPermissionValue(PERMISSION_ARRKEY.UPDATE_PERMISSIONS)) {
      return permissionColumns.filter((col) => col.id !== 'actions')
    }
    return permissionColumns
    //eslint-disable-next-line
  }, [])
  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  })

  const intl = useIntl()
  useEffect(() => {
    initBadges()
    // eslint-disable-next-line
  }, [])

  const initBadges = async () => {
    let index = 0
    try {
      const {roles} = await get_roles()
      saveRoles(roles)
      saveBadgesData(
        roles.reduce((prev, curr) => Object.assign(prev, {[curr._id]: badgeClasses?.[index++]}), {})
      )
    } catch {
    } finally {
    }
  }
  return loading ? (
    <Card>
      <Card.Body className='d-flex justify-content-center align-items-center'>
        <div className='d-flex justify-content-center align-items-center'>
          <span className='me-2'>{intl.formatMessage({id: 'PLEASE.WAIT'})}</span>
          <span className='spinner-border spinner-border-md align-middle text-primary' />
        </div>
      </Card.Body>
    </Card>
  ) : (
    <KTCardBody className='py-4'>
      <div className='table-responsive'>
        <table
          id='kt_table_users'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
          {...getTableProps()}
        >
          <thead>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
              {headers.map((column: ColumnInstance<Permission>) => (
                <CustomHeaderColumn key={column.id} column={column} />
              ))}
            </tr>
          </thead>
          <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: Row<Permission>, i) => {
                prepareRow(row)
                return <CustomRow row={row} key={`row-${i}-${row.id}`} />
              })
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    {intl.formatMessage({id: 'RESULT.EMPTY'})}
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {totalCount > 0 ? (
        <Pagination totalItems={totalCount} state={state} updateState={updateState} />
      ) : (
        <></>
      )}
      {isLoading && <ListLoading />}
    </KTCardBody>
  )
}

export default PermissionsListTable
