import React, {useEffect, useMemo, useState} from 'react'
import {KTCard, KTCardBody, isNotEmpty} from '../../../../general/helpers'
import CardLoading from '../components/CardLoading'
import Grid from '../components/Grid'
import ListLoading from '../dashboad/components/loading/ListLoading'
import Pagination from '../components/Pagination'
import {
  useActivitiesQueryResponse,
  useActivitiesQueryResponseData,
  useActivitiesQueryResponseTotalCount,
} from './core/ActivitiesResponseProvider'
import {useActivitiesQueryRequest} from './core/ActivitiesRequestProvider'
import ActivitiesListHeader from './components/ActivitiesListHeader'
import Modal from '../components/Modal'
import {useIntl} from 'react-intl'
import {get_activities_labels} from '../core/_request'
import {activitiesColumns} from './table/columns/_columns'
import {keysforDateFormatValue} from '../core/globals'
import {getFormattedDateTime, parseJwt} from '../core/helper'
import {useAuth} from '../../auth'

const ActivitiesLayout = () => {
  const {isLoading} = useActivitiesQueryResponse()
  const {state, updateState, loading} = useActivitiesQueryRequest()
  const totalCounts = useActivitiesQueryResponseTotalCount()
  const activities = useActivitiesQueryResponseData()
  const data = useMemo(
    () => activities.map((activity) => ({...activity.executedBy, ...activity})),
    [activities]
  )
  const [activeItem, setActiveItem] = useState<{[key: string]: any} | undefined>(undefined)
  const [labelsState, setLabelsState] = useState<{
    loading: boolean
    labels: {[key: string]: string}
  }>({loading: false, labels: {}})
  const intl = useIntl()
  const {auth} = useAuth()
  const {activeRetailer} = parseJwt(auth?.accessToken ?? '')

  const columns = useMemo(() => activitiesColumns, [])

  /**
   * fetching labels for showing activity details
   */
  const fetchActivitysLabels = async () => {
    setLabelsState((prev) => ({...prev, loading: true}))
    try {
      const response = await get_activities_labels()
      const {executedBy, ...labels} = response?.[0]?.labels
      setLabelsState((prev) => ({...prev, labels: {...labels, ...executedBy}}))
    } catch (e) {
    } finally {
      setLabelsState((prev) => ({...prev, loading: false}))
    }
  }
  /**
   * opens activity details on row click
   * @param index index of row
   */
  const rowClickHandler = (index: number) => {
    const {executedBy, ...obj} = data[index]
    keysforDateFormatValue.activities.forEach((key) => {
      if (obj?.[key]) {
        obj[key] = getFormattedDateTime(activeRetailer?.locale, activeRetailer?.timeZone, obj[key])
      }
    })
    setActiveItem({...executedBy, ...obj})
  }

  const keysToShow = [
    'id',
    'name',
    'path',
    'method',
    'result',
    'action',
    'createdAt',
    'userAgent',
    'failedResponse',
    'params',
  ]

  useEffect(() => {
    if (
      activeItem &&
      Object.keys(activeItem)?.length > 0 &&
      Object.keys(labelsState.labels).length === 0
    ) {
      fetchActivitysLabels()
    }
    //eslint-disable-next-line
  }, [activeItem])

  return (
    <>
      <KTCard>
        <ActivitiesListHeader />
        <KTCardBody className='py-4'>
          {loading ? (
            <CardLoading />
          ) : (
            <>
              <Grid columns={columns} data={data} onRowClick={rowClickHandler} />
              {isLoading ? <ListLoading /> : <></>}
              {totalCounts > 0 ? (
                <Pagination totalItems={totalCounts} state={state} updateState={updateState} />
              ) : (
                <></>
              )}
            </>
          )}
        </KTCardBody>
      </KTCard>
      {activeItem && (
        <Modal
          heading={intl.formatMessage({id: 'ACTIVITY.DETAILS'})}
          onClose={() => setActiveItem(undefined)}
          size='mw-1200px'
        >
          {labelsState.loading ? (
            <CardLoading />
          ) : (
            <div className='d-flex gap-7 flex-wrap'>
              {keysToShow.map((key) => (
                <div className='d-flex flex-column w-350px gap-1' key={key}>
                  <div className='fw-bold fs-5'>
                    {labelsState.labels?.[key]
                      ? intl.formatMessage({
                          id: labelsState.labels?.[key],
                        })
                      : ''}
                  </div>
                  <div className='text-gray-600 fs-6' style={{wordWrap: 'break-word'}}>
                    {isNotEmpty(activeItem?.[key]) ? activeItem?.[key] : 'N/A'}
                  </div>
                </div>
              ))}
            </div>
          )}
        </Modal>
      )}
    </>
  )
}

export default ActivitiesLayout
