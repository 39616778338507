import React, {ReactNode, useState} from 'react'
import {KTIcon} from '../../../../general/helpers'

interface Props {
  items: {id: number; header: string; content: ReactNode}[]
}

const Accordian = (props: Props) => {
  const {items} = props
  const [active, setActive] = useState(0)

  return (
    <div className='accordion accordion-icon-toggle' id='kt_accordion_1'>
      <div className='mb-5'>
        <div
          className={`accordion-header py-3 d-flex`}
          data-bs-toggle='collapse'
          data-bs-target={`#kt_accordion_1_item_0`}
          onClick={() => {
            setActive(0)
          }}
        >
          <span className='accordion-icon'>
            {active === 0 ? (
              <KTIcon iconName='minus-square' iconType='duotone' className='fs-3' />
            ) : (
              <KTIcon iconName='plus-square' iconType='duotone' className='fs-3' />
            )}
          </span>
          <h3 className='fs-4 text-gray-800 fw-bold mb-0 ms-4'>{items?.[0].header}</h3>
        </div>
        <div
          id={`kt_accordion_1_item_0`}
          className={`collapse show fs-6 ps-10`}
          data-bs-parent='#kt_accordion_1'
        >
          {items?.[0].content}
        </div>
      </div>
      {items.slice(1, items.length).map((item, ind) => (
        <div className='mb-5' key={item.header}>
          <div
            className={`accordion-header py-3 d-flex collapsed`}
            data-bs-toggle='collapse'
            data-bs-target={`#kt_accordion_1_item_${ind + 1}`}
            onClick={() => {
              setActive(ind + 1)
            }}
          >
            <span className='accordion-icon'>
              {active === ind + 1 ? (
                <KTIcon iconName='minus-square' iconType='duotone' className='fs-3' />
              ) : (
                <KTIcon iconName='plus-square' iconType='duotone' className='fs-3' />
              )}
            </span>
            <h3 className='fs-4 text-gray-800 fw-bold mb-0 ms-4'>{item.header}</h3>
          </div>
          <div
            id={`kt_accordion_1_item_${ind + 1}`}
            className={`collapse fs-6 ps-10`}
            data-bs-parent='#kt_accordion_1'
          >
            {item.content}
          </div>
        </div>
      ))}
    </div>
  )
}

export default Accordian
