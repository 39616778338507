/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useRef, useState} from 'react'
import {MenuComponent} from '../../../../../../general/assets/ts/components'
import {useUsers} from '../../Users'
import {User} from '../../../core/_models'
import {getStatus} from '../../../core/helper'
import {delete_user, resend_invite} from '../../../core/_request'
import {KTIcon} from '../../../../../../general/helpers'
import {useIntl} from 'react-intl'
import Modal from '../../../components/Modal'
import {useAuth} from '../../../../auth'
import {PERMISSION_ARRKEY} from '../../../../auth/core/constant'

type Props = {
  user: User
  fetchUsers: () => void
}

const UserActionsCell: FC<Props> = ({user, fetchUsers}) => {
  const {saveItemIdForUpdate} = useUsers()
  const {getAllowedPermissionValue} = useAuth()
  const [inviteLoading, setInviteLoading] = useState<boolean>(false)
  const [deleteState, setDeleteState] = useState<{loading: boolean; isModalOpen: boolean}>({
    loading: false,
    isModalOpen: false,
  })

  const dropdownRef = useRef<HTMLButtonElement>(null)
  const intl = useIntl()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const openEditModal = () => {
    saveItemIdForUpdate(user._id)
  }
  /**
   * initiate request for inviting user again
   */
  const inviteUser = async () => {
    setInviteLoading(true)
    try {
      const payload = {
        email: user.email,
      }
      await resend_invite(payload)
      dropdownRef.current?.click()
    } catch {
    } finally {
      setInviteLoading(false)
    }
  }

  /**
   * initiate request for deleting user
   */
  const deleteUser = async () => {
    setDeleteState((prev) => ({...prev, loading: true}))
    try {
      await delete_user(user._id)
      fetchUsers()
    } catch (e) {
    } finally {
      setDeleteState((prev) => ({...prev, loading: false, isModalOpen: false}))
    }
  }

  const status = getStatus(user)

  const updateAction = getAllowedPermissionValue(PERMISSION_ARRKEY.UPDATE_USER)
  const deleteAction = getAllowedPermissionValue(PERMISSION_ARRKEY.DELETE_USER)
  const resendInviteAction = getAllowedPermissionValue(PERMISSION_ARRKEY.RESEND_INVITE)

  return (resendInviteAction && status === 'pending') || deleteAction || updateAction ? (
    <>
      <button
        ref={dropdownRef}
        className='btn btn-light btn-active-light-primary btn-sm'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        <KTIcon iconType='solid' iconName='dots-vertical' className='fs-2 m-0 p-0' />
      </button>
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-140px py-4'
        data-kt-menu='true'
      >
        {updateAction && (
          <div className='menu-item px-3'>
            <button
              className='menu-link px-3 btn btn-transparent btn-active-light-primary btn-sm w-100'
              onClick={openEditModal}
            >
              {intl.formatMessage({id: 'EDIT'})}
            </button>
          </div>
        )}
        {deleteAction && (
          <div className='menu-item px-3'>
            <button
              disabled={deleteState.loading}
              className='btn btn-color-gray-600 btn-active-light-danger btn-sm px-3 w-100 d-flex align-items-center'
              onClick={() => {
                setDeleteState((prev) => ({...prev, isModalOpen: true}))
              }}
            >
              {intl.formatMessage({id: 'DELETE'})}
              {inviteLoading ? (
                <span className='spinner-border spinner-border-sm align-middle ms-2' />
              ) : (
                <></>
              )}
            </button>
          </div>
        )}
        {resendInviteAction && status === 'pending' ? (
          <div className='menu-item px-3'>
            <button
              disabled={inviteLoading}
              className='btn btn-color-gray-600 btn-active-light-primary btn-sm px-3 w-100 d-flex align-items-center'
              onClick={inviteUser}
            >
              {intl.formatMessage({id: 'RESEND.INVITE'})}
              {inviteLoading ? (
                <span className='spinner-border spinner-border-sm align-middle ms-2' />
              ) : (
                <></>
              )}
            </button>
          </div>
        ) : (
          <></>
        )}
      </div>
      {deleteState.isModalOpen ? (
        <Modal
          onClose={() => {
            setDeleteState((prev) => ({...prev, isModalOpen: false}))
          }}
          heading={intl.formatMessage({
            id: `DELETE.USER`,
          })}
          size='modal-md '
          bodyClasses='p-0'
        >
          <div>
            <p className='mb-8 text-center h6 fw-normal'>
              {intl.formatMessage({id: 'USER.DELETE.CONFIRMATION'})} <b>{user.name}</b> ?
            </p>
            <div className='d-flex gap-4 justify-content-center'>
              <button
                type='button'
                onClick={() => setDeleteState((prev) => ({...prev, isModalOpen: false}))}
                className='btn btn-light'
                disabled={deleteState.loading}
              >
                {intl.formatMessage({id: 'NO'})}
              </button>
              <button
                type='button'
                className='btn btn-danger ms-4 d-flex align-items-center'
                onClick={deleteUser}
                disabled={deleteState.loading}
              >
                {intl.formatMessage({id: 'DELETE'})}
                {deleteState.loading ? (
                  <span>
                    <span className='spinner-border spinner-border-sm align-middle ms-2' />
                  </span>
                ) : (
                  <></>
                )}
              </button>
            </div>
          </div>
        </Modal>
      ) : (
        <></>
      )}
    </>
  ) : (
    <></>
  )
}

export {UserActionsCell}
