import React, {useState} from 'react'
import {ListSearch} from '../../components/ListSearch'
import {useActivitiesQueryRequest} from '../core/ActivitiesRequestProvider'
import ActivitiesListFilters from './ActivitiesListFilters'
import {toast} from 'react-toastify'
import {useIntl} from 'react-intl'
import {KTIcon, isNotEmpty} from '../../../../../general/helpers'
import {export_activities} from '../../core/_request'
import {useActivitiesQueryResponse} from '../core/ActivitiesResponseProvider'
import {useAuth} from '../../../auth'
import {PERMISSION_ARRKEY} from '../../../auth/core/constant'

const ActivitiesListHeader = () => {
  const {state, updateState} = useActivitiesQueryRequest()
  const {query} = useActivitiesQueryResponse()
  const [exportLoading, setExportLoading] = useState(false)
  const intl = useIntl()
  const {getAllowedPermissionValue} = useAuth()
  /**
   * starts export activities data
   */
  const triggerActivitiesExport = async () => {
    const isFiltersEmpty =
      Object.entries(state).find((obj) => {
        return obj[0] !== 'page' && obj[0] !== 'size' && isNotEmpty(obj[1])
      }) === undefined
    if (isFiltersEmpty) {
      toast.error(intl.formatMessage({id: 'FILTER.SELECT'}))
      return
    }
    setExportLoading(true)
    try {
      await export_activities(query)
    } catch (e) {
    } finally {
      setExportLoading(false)
    }
  }

  return (
    <div className='card-header border-0 pt-6'>
      <div className='card-toolbar w-100 d-flex flex-wrap justify-content-between gap-2'>
        <div className='d-flex gap-2 flex-wrap'>
          <ListSearch placeHolderId='SEARCH.ACTIVITIES' updateState={updateState} />
        </div>
        <div className='d-flex justify-content-end align-items-center'>
          <ActivitiesListFilters />
          {getAllowedPermissionValue(PERMISSION_ARRKEY.EXPORT_ACTIVITY_LOGS) && (
            <button
              disabled={exportLoading}
              type='button'
              className='btn btn-light-primary me-3'
              onClick={triggerActivitiesExport}
            >
              <KTIcon iconName='exit-up' className='fs-2' />
              {intl.formatMessage({id: 'EXPORT'})}
              {exportLoading && (
                <span className='spinner-border spinner-border-sm align-middle text-primary ms-2' />
              )}
            </button>
          )}
        </div>
      </div>
    </div>
  )
}

export default ActivitiesListHeader
