import React, {useEffect, useState} from 'react'
import {UsersTable} from './table/UsersTable'
import {KTCard, KTCardBody, QueryStateI} from '../../../../general/helpers'
import {UsersListHeader} from './components/header/UsersListHeader'
import {useAuth} from '../../auth'
import {useUsers} from './Users'
import {get_users, get_users_by_roles} from '../core/_request'
import {UserEditForm} from './components/form/UserEditForm'
import Modal from '../components/Modal'
import {Card} from 'react-bootstrap'
import ListLoading from '../dashboad/components/loading/ListLoading'
import {useLocation, useParams} from 'react-router-dom'
import Pagination from '../components/Pagination'
import {useIntl} from 'react-intl'

const UsersLayout = () => {
  const {state} = useLocation()
  const {roleId} = useParams()
  const {
    filters,
    pagination,
    savePagination,
    saveTotalItems,
    itemIdForUpdate,
    saveItemIdForUpdate,
    totalItems,
  } = useUsers()
  const {auth} = useAuth()
  const intl = useIntl()
  const {saveUsersList} = useUsers()
  const [initialLoad, setInitialLoad] = useState<boolean>(true)
  const [loading, setLoading] = useState<boolean>(false)
  useEffect(() => {
    fetchUsers(true, pagination.size, 1)
    savePagination({...pagination, page: 1})
    //eslint-disable-next-line
  }, [filters])

  useEffect(() => {
    !initialLoad && fetchUsers()
    //eslint-disable-next-line
  }, [pagination])

  const fetchUsers = async (pageLoad?: boolean, size?: number, page?: number) => {
    pageLoad === true && setInitialLoad(true)
    setLoading(true)
    try {
      const params = {
        ...filters,
        page: page ?? pagination.page,
        size: size ?? pagination.size,
      }
      const locationState=state as any;
      if (locationState?.from === 'roles' && roleId) {
        const {totalCounts, usersByRole} = await get_users_by_roles(
          auth?.accessToken,
          roleId,
          params
        )
        saveTotalItems(totalCounts)
        saveUsersList(usersByRole)
      } else {
        const {totalCounts, users} = await get_users(auth?.accessToken, params)
        saveTotalItems(totalCounts)
        saveUsersList(users)
      }
    } catch (err) {
      console.log(err)
    } finally {
      setLoading(false)
      setInitialLoad(false)
    }
  }

  return (
    <KTCard>
      <UsersListHeader loading={loading} />
      {initialLoad ? (
        <Card>
          <Card.Body>
            <div className='d-flex justify-content-center align-items-center'>
              <span className='me-2'>Please wait...</span>
              <span className='spinner-border spinner-border-md align-middle text-primary' />
            </div>
          </Card.Body>
        </Card>
      ) : (
        <KTCardBody className='py-4 position-relative'>
          <UsersTable
            fetchUsers={() => {
              fetchUsers(false, pagination.size, pagination.page)
            }}
          />
          {loading ? <ListLoading /> : <></>}
          {totalItems > 0 ? (
            <Pagination
              totalItems={totalItems}
              state={pagination}
              updateState={(obj: Partial<QueryStateI>) => {
                savePagination({page: obj?.page ?? 1, size: obj?.size ?? 10})
              }}
            />
          ) : (
            <></>
          )}
        </KTCardBody>
      )}
      {itemIdForUpdate !== undefined && (
        <Modal
          heading={intl.formatMessage({id: itemIdForUpdate === null ? 'ADD.USER' : 'EDIT.USER'})}
          onClose={() => {
            saveItemIdForUpdate(undefined)
          }}
          bodyClasses='mx-xl-15'
        >
          <UserEditForm fetchUsers={fetchUsers} />
        </Modal>
      )}
    </KTCard>
  )
}

export default UsersLayout
