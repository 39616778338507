/* eslint-disable react-hooks/exhaustive-deps */
import {FC, useContext, useState, useEffect, useMemo} from 'react'
import {useQuery} from 'react-query'
import {
  createResponseContext,
  initialQueryResponse,
  QUERIES,
  WithChildren,
} from '../../../../../general/helpers'
import {extractQueryParams, get_stores} from '../../core/_request'
import {StoresResponse} from '../../core/_models'
import {useStoresQueryRequest} from './StoresRequestProvider'

const QueryResponseContext = createResponseContext<StoresResponse>(initialQueryResponse)
const StoresQueryResponseProvider: FC<WithChildren> = ({children}) => {
  const {state, loading, toggleLoading} = useStoresQueryRequest()
  // below state object is altered to maintain the page indexing starting from 0 rather than 1
  const [query, setQuery] = useState<string>(extractQueryParams({...state, page: state.page - 1}))
  const updatedQuery = useMemo(() => extractQueryParams({...state, page: state.page - 1}), [state])

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery)
    }
  }, [updatedQuery])

  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    `${QUERIES.STORES_LIST}-${query}`,
    () => {
      return get_stores(query)
    },
    {
      cacheTime: 0,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      onSettled: () => {
        loading && toggleLoading?.()
      },
      retry: false,
    }
  )

  return (
    <QueryResponseContext.Provider value={{isLoading: isFetching, refetch, response, query}}>
      {children}
    </QueryResponseContext.Provider>
  )
}

const useStoresQueryResponse = () => useContext(QueryResponseContext)

const useStoresQueryResponseData = () => {
  const {response} = useStoresQueryResponse()
  if (!response) {
    return []
  }

  return response.stores || []
}

const useStoresQueryResponseLoading = (): boolean => {
  const {isLoading} = useStoresQueryResponse()
  return isLoading
}

const useStoresQueryResponseTotalCount = (): number => {
  const {response} = useStoresQueryResponse()
  return response?.totalCounts ?? 0
}

export {
  StoresQueryResponseProvider,
  useStoresQueryResponse,
  useStoresQueryResponseData,
  useStoresQueryResponseLoading,
  useStoresQueryResponseTotalCount,
}
