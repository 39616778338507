import clsx from 'clsx'
import React, {ChangeEvent, Fragment, SelectHTMLAttributes} from 'react'
import {useIntl} from 'react-intl'
import ClearCross from '../../panel/components/ClearCross'

interface Props {
  id?: string
  label?: string
  labelId?: string
  isValid?: boolean
  isInvalid?: boolean
  attrs?: SelectHTMLAttributes<HTMLSelectElement>
  onChange?: (event: ChangeEvent<HTMLSelectElement>) => void
  disabled?: boolean
  placeHolder?: string
  placeHolderId?: string
  errorStrId?: string
  required?: boolean
  options?: Array<{label: string; value: string}>
  classNames?: string
  isClearable?: boolean
  onClear?: () => void
}

const Select = (props: Props) => {
  const {
    id,
    label,
    labelId,
    isValid,
    isInvalid,
    attrs,
    onChange,
    disabled,
    placeHolder,
    placeHolderId,
    errorStrId,
    required,
    options,
    classNames,
    isClearable = false,
    onClear,
  } = props
  const intl = useIntl()

  return (
    <Fragment key={id}>
      {(label || labelId) && (
        <label className={`form-label fs-6  text-dark ${clsx({required: required})}`}>
          {label ?? intl.formatMessage({id: labelId})}
        </label>
      )}
      <div className='position-relative'>
        <select
          className={clsx(
            `form-select form-select-solid cursor-pointer ${classNames ?? ''}`,
            {'is-invalid': isInvalid},
            {
              'is-valid': isValid,
            },
            {'text-gray-500': !attrs?.value}
          )}
          id={id}
          name={attrs?.name}
          value={attrs?.value}
          onChange={onChange}
          placeholder={placeHolder ?? intl.formatMessage({id: placeHolderId})}
          disabled={disabled}
        >
          <option style={{display: 'none'}}>
            {' '}
            {intl.formatMessage({id: 'SELECT'})} {attrs?.name}
          </option>
          {options?.map((option) => {
            return (
              <option key={option.value} value={option.value} className='text-gray-700'>
                {option.label}
              </option>
            )
          })}
        </select>
        {isClearable && attrs?.value && <ClearCross onClick={() => onClear?.()} isSelect />}
      </div>
      {isInvalid && errorStrId ? (
        <div className='fv-plugins-message-container'>
          <div className='fv-help-block'>
            <span role='alert'>{intl.formatMessage({id: errorStrId})}</span>
          </div>
        </div>
      ) : (
        <></>
      )}
    </Fragment>
  )
}

export default Select
