import React from 'react'
import {Card} from 'react-bootstrap'
import {FormattedMessage} from 'react-intl'

const CardLoading = () => {
  return (
    <Card>
      <Card.Body>
        <div className='d-flex justify-content-center align-items-center'>
          <span className='me-2'>
            <FormattedMessage id='PLEASE.WAIT' />
          </span>
          <span className='spinner-border spinner-border-md align-middle text-primary' />
        </div>
      </Card.Body>
    </Card>
  )
}

export default CardLoading
