import React from 'react'
import {PageTitle} from '../../../../general/layout/core'
import PermissionsWrapper from './PermissionsWrapper'
import {useIntl} from 'react-intl'
import WithPermissionRestricted from '../../../../general/hoc/WithPermissionRestricted'

const PermissionsPage = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle>{intl.formatMessage({id: 'PERMISSIONS.LIST'})}</PageTitle>
      <PermissionsWrapper />
    </>
  )
}

export default WithPermissionRestricted(PermissionsPage, 'PERMISSIONS.LIST', [
  'permissions',
  'GetAllPermissions',
])
