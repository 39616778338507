import React, {useMemo} from 'react'
import {Card} from 'react-bootstrap'
import {StoreDevice, StoreProperty} from '../../core/_models'
import {FormattedMessage, useIntl} from 'react-intl'
import {useStoresContext} from '../core/Stores'
import {keysforDateFormatValue} from '../../core/globals'
import {useAuth} from '../../../auth'
import {getFormattedDateTime, parseJwt} from '../../core/helper'
import {PERMISSION_ARRKEY} from '../../../auth/core/constant'

interface Props {
  type: 'property' | 'device'
  details: StoreProperty | StoreDevice
  labels: {[key: string]: string}
}

const PropertyCard = (props: Props) => {
  const {type, details, labels} = props
  const {saveItemDetailsForUpdate, saveItemDetailsForDelete} = useStoresContext()
  const intl = useIntl()
  const {auth, getAllowedPermissionValue} = useAuth()
  const {activeRetailer} = parseJwt(auth?.accessToken ?? '')

  /**
   * creating object as needed for property or device rendering
   */
  const convertedObject = useMemo(() => {
    const {id, ...obj} = {...details}
    if (type === 'device') {
      Object.assign(obj, {
        suspended: intl.formatMessage({id: obj?.['suspended'] ? 'INACTIVE' : 'ACTIVE'}),
      })
      delete obj?.['store']
    }
    if (type === 'property') {
      Object.assign(obj, {
        sensitiveData: intl.formatMessage({id: obj?.['sensitiveData'] ? 'YES' : 'NO'}),
      })
      keysforDateFormatValue.properties.forEach((key) => {
        if (obj?.[key]) {
          obj[key] = getFormattedDateTime(
            activeRetailer?.locale,
            activeRetailer?.timeZone,
            obj[key]
          )
        }
      })
    }

    return obj
    // eslint-disable-next-line
  }, [details, type])

  // keys for permission actions
  const updateBtnKey =
    type === 'device' ? PERMISSION_ARRKEY.UPDATE_DEVICE : PERMISSION_ARRKEY.UPDATE_STORE_PROPERTY
  const deleteBtnKey =
    type === 'device' ? PERMISSION_ARRKEY.DELETE_DEVICE : PERMISSION_ARRKEY.DELETE_STORE_PROPERTY

  return (
    <Card>
      <Card.Header className='border-0 d-flex justify-content-between align-items-center'>
        <Card.Title>
          <FormattedMessage id={type === 'device' ? 'DEVICE' : 'PROPERTY'} />
          <span className='ms-1 fst-italic text-muted d-inline-block'>{details.id}</span>
        </Card.Title>
        <div className='d-flex gap-2'>
          {getAllowedPermissionValue(updateBtnKey) && (
            <button
              type='button'
              className='btn btn-icon btn-active-light-primary w-30px h-30px ms-auto'
              onClick={() => {
                saveItemDetailsForUpdate({name: type, values: details})
              }}
            >
              <i className='ki-outline ki-pencil fs-3' />
            </button>
          )}
          {getAllowedPermissionValue(deleteBtnKey) && (
            <button
              type='button'
              className='btn btn-icon btn-active-light-danger w-30px h-30px ms-auto'
              onClick={() => {
                saveItemDetailsForDelete({name: type, values: details})
              }}
            >
              <i className='ki-outline ki-trash fs-3' />
            </button>
          )}
        </div>
      </Card.Header>
      <Card.Body className='pt-0 pb-5'>
        <table className='table align-middle table-row-dashed gy-5'>
          <tbody className='fs-6 fw-semibold text-gray-600'>
            {Object.keys(convertedObject).map((key) => (
              <tr key={key}>
                <td className='min-w-100px w-200px'>
                  {labels?.[key] ? intl.formatMessage({id: labels?.[key]}) : ''}
                </td>
                <td>{convertedObject[key] ?? 'N/A'}</td>
                {key === 'deviceSecret' && (
                  <td className='text-end'>
                    {getAllowedPermissionValue(PERMISSION_ARRKEY.UPDATE_DEVICE_SECRET) && (
                      <button
                        type='button'
                        className='btn btn-icon btn-active-light-primary w-30px h-30px ms-auto'
                        onClick={() => {
                          saveItemDetailsForUpdate({name: 'deviceSecret', values: details})
                        }}
                      >
                        <i className='ki-outline ki-pencil fs-3' />{' '}
                      </button>
                    )}
                    {getAllowedPermissionValue(PERMISSION_ARRKEY.DELETE_DEVICE_SECRET) && (
                      <button
                        type='button'
                        className='btn btn-icon btn-active-light-danger w-30px h-30px ms-auto'
                        onClick={() => {
                          saveItemDetailsForDelete({name: 'deviceSecret', values: details})
                        }}
                      >
                        <i className='ki-outline ki-trash fs-3' />{' '}
                      </button>
                    )}
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </Card.Body>
    </Card>
  )
}

export default PropertyCard
