/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect} from 'react'
import {MenuComponent} from '../../../../../../general/assets/ts/components'
import {usePermissions} from '../../core/Permissions'
import {useIntl} from 'react-intl'
import {useAuth} from '../../../../auth'
import {PERMISSION_ARRKEY} from '../../../../auth/core/constant'

type Props = {
  id: null | string
}

const PermissionsActionsCell: FC<Props> = ({id}) => {
  const {saveItemIdForUpdate} = usePermissions()
  const {getAllowedPermissionValue} = useAuth()
  const intl = useIntl()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const openEditModal = () => {
    saveItemIdForUpdate(id)
  }

  return getAllowedPermissionValue(PERMISSION_ARRKEY.UPDATE_PERMISSIONS) ? (
    <button className='btn btn-light btn-active-light-primary btn-sm' onClick={openEditModal}>
      {intl.formatMessage({id: 'EDIT'})}
    </button>
  ) : (
    <></>
  )
}

export {PermissionsActionsCell}
