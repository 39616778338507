/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from 'react'
import {useDebounce, KTIcon} from '../../../../../../general/helpers'
import {useQueryRequest} from '../../core/PermissionsRequestProvider'
import {useIntl} from 'react-intl'
import ClearCross from '../../../components/ClearCross'

const PermissionsListSearchComponent = () => {
  const {updateState} = useQueryRequest()
  const [searchTerm, setSearchTerm] = useState<string>()
  const intl = useIntl()
  const debouncedSearchTerm = useDebounce(searchTerm, 500)
  useEffect(
    () => {
      if (debouncedSearchTerm !== undefined && searchTerm !== undefined) {
        updateState({search: debouncedSearchTerm, page: 1})
      }
    },
    [debouncedSearchTerm]
  )

  return (
    <div className='card-title'>
      {/* begin::Search */}
      <div className='d-flex align-items-center position-relative my-1'>
        <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
        <input
          type='text'
          data-kt-user-table-filter='search'
          className='form-control form-control-solid w-250px ps-14'
          placeholder={intl.formatMessage({id: 'PLACEHOLDER.PERMISSION.SEARCH'})}
          value={searchTerm ?? ''}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        {searchTerm && <ClearCross onClick={() => setSearchTerm('')} />}
      </div>
      {/* end::Search */}
    </div>
  )
}

export {PermissionsListSearchComponent}
