import clsx from 'clsx'
import {RefObject, SetStateAction, useEffect, useRef, useState} from 'react'
import {
  getDatePlaceholder,
  parseJwt,
  prepareQueryString,
  scrollToBelowElement,
  showToast,
} from '../core/helper'
import {get_transaction_query} from '../core/_request'
import {useAuth} from '../../auth/core/Auth'
import {useDash} from './Dashboard'
import Flatpickr from 'react-flatpickr'
import {toast} from 'react-toastify'
import {toAbsoluteUrl} from '../../../../general/helpers'
import {useIntl} from 'react-intl'
import {PaginationI} from '../core/_models'
import moment from 'moment'

interface PropsI {
  filters: any
  setFilterQueryStr: (e: string) => void
  filtersFromSession: {[name: string]: any}
  setFiltersFromSession: (e: {[name: string]: any}) => void
  filterLoad: boolean
  setFilterLoad: (e: boolean) => void
  pagination: PaginationI
  setPagination: React.Dispatch<SetStateAction<PaginationI>>
  setTotalItems: (e: number) => void
  setTableLoad: (e: boolean) => void
  tableLoad: boolean
  listRef: RefObject<HTMLDivElement | null>
}

function FilterComp({
  pagination,
  setPagination,
  setTotalItems,
  setTableLoad,
  ..._props
}: Readonly<PropsI>) {
  const [filterValues, setFilterValues] = useState({})
  const [loading, setLoading] = useState(false)
  const {
    filters,
    setFilterQueryStr,
    filtersFromSession,
    setFiltersFromSession,
    filterLoad,
    setFilterLoad,
    listRef,
  } = _props
  const {auth} = useAuth()
  const {saveQueryListings} = useDash()
  const [selectedDateFrom, setSelectedDateFrom] = useState<any>()
  const [selectedDateTo, setSelectedDateTo] = useState<any>()
  const isFirstRender = useRef(true)
  const intl = useIntl()
  const {activeRetailer} = parseJwt(auth?.accessToken ?? '')
  const datePlaceHolder = getDatePlaceholder(activeRetailer?.locale, activeRetailer?.timeZone)

  useEffect(() => {
    if (Object.keys(filtersFromSession).length > 0) {
      handleSubmit()
    }
    //eslint-disable-next-line
  }, [filtersFromSession])

  useEffect(() => {
    if (isFirstRender.current === false) {
      handleSubmit('pagination')
    }
    isFirstRender.current = false
    // eslint-disable-next-line
  }, [pagination])

  const handleSubmit = async (trigger?: string) => {
    const fromSession = sessionStorage.getItem('filterQueryStr') !== null
    const filterVal = fromSession ? {...filtersFromSession} : {...filterValues}
    if (Object.keys(filterVal).length === 0) {
      toast.error(
        showToast(
          intl.formatMessage({
            id: 'FILTER.SELECT',
          })
        )
      )
      return
    }
    setTableLoad(true)
    const tempFilters = {}
    setLoading(true)
    Object.keys(filterVal).forEach((key) => {
      const value = typeof filterVal[key] === 'string' ? filterVal[key].trim() : filterVal[key]
      if (value || filterVal[key] === 0) {
        let filterKey = ''
        if (key === 'createdAt.greaterThanOrEqual') {
          tempFilters[key] = value
        } else if (key === 'createdAt.lessThan') {
          tempFilters[key] = value.split('T')[0] + 'T23:59:00Z'
        } else if (key === 'storeName') {
          filterKey = key + '.contains'
          tempFilters[filterKey] = value
        } else if (key !== 'page' && key !== 'size') {
          filterKey = key + '.equals'
          tempFilters[filterKey] = value
        } else {
          filterKey = key
          tempFilters[filterKey] = value
        }
      }
    })
    if (fromSession === false) {
      tempFilters['page'] = trigger === 'pagination' ? Number(pagination['page']) - 1 : 0
      tempFilters['size'] = trigger === 'pagination' ? pagination['size'] : 20
      if (trigger !== 'pagination') {
        isFirstRender.current = true
        setPagination({
          size: 20,
          page: 1,
        })
      }
    }
    try {
      const tempQueryStr = prepareQueryString(tempFilters)
      setFilterQueryStr(tempQueryStr)
      const {totalCounts, transactions} = await get_transaction_query(
        auth?.accessToken,
        tempQueryStr
      )
      setTotalItems(totalCounts)
      if (transactions?.length === 0) {
        toast.error(
          showToast(
            intl.formatMessage({
              id: 'TRANSACTION.EMPTY',
            })
          )
        )
      } else {
        setTimeout(() => listRef?.current && scrollToBelowElement(listRef?.current, 110), 200)
      }
      let tempData = [
        ...transactions.map((transaction) => ({
          ...transaction,
          createdAt: transaction.createdAt + 'Z',
        })),
      ]
      tempData = tempData.map((item) => {
        if (item['transactionType'] === 'REFUND') {
          item['transactionProductModel']['totalAmount'] =
            Number(item?.['transactionProductModel']?.['totalAmount'] ?? 0) * -1
        }
        return item
      })
      saveQueryListings([...tempData])
    } catch (error) {
      console.log(error)
    }
    setTableLoad(false)
    if (fromSession) {
      if (
        filtersFromSession?.['createdAt.greaterThanOrEqual'] ||
        filtersFromSession?.['createdAt.lessThan']
      ) {
        const from = filtersFromSession['createdAt.greaterThanOrEqual']
        const to = filtersFromSession['createdAt.lessThan']
        const obj = {from, to}
        Object.entries(obj)
          .filter((item) => item[1])
          .forEach((item) => {
            const dateObj = new Date(item[1])
            let dateIST: any = dateObj.toISOString()
            dateIST = new Date(dateIST)
            dateIST = new Date(dateIST.getTime() - 5.5 * 60 * 60 * 1000)
            if (item?.[0] === 'from') {
              setSelectedDateFrom(dateIST)
            } else {
              setSelectedDateTo(dateIST)
            }
          })
      }
      isFirstRender.current = true
      setPagination({
        ...pagination,
        size: tempFilters['size'],
        page: Number(tempFilters['page']) + 1,
      })
      setFilterValues({...filtersFromSession})
      setFilterLoad(false)
      setFiltersFromSession({})
      sessionStorage.removeItem('filterQueryStr')
    }
    setLoading(false)
  }

  function convertUtcToIst(utcTimeString) {
    const utcDate = new Date(utcTimeString)
    const istDate = new Date(utcDate.getTime() + 5.5 * 60 * 60 * 1000)
    return istDate.toISOString()
  }

  const sortOptions = (arr: string[]) => {
    return arr.sort((a, b) => {
      if (a.toLowerCase() === b.toLowerCase()) return 0
      return a.toLowerCase() > b.toLowerCase() ? 1 : -1
    })
  }

  const arrangeOptions = (options: any[]) => {
    const optionsArr = options.map((item) => {
      return item?.['type'] ?? item?.['name'] ?? 'N/A'
    })
    const sortedOptions = sortOptions(optionsArr)
    return sortedOptions
  }

  const renderClearIcon = (slug, type: string, id?: string) => {
    if (filterValues[slug]) {
      return (
        <span
          className={`clear-icon position-absolute end-0 top-50 cursor-pointer translate-middle-y translate-y-n50 p-1 ${
            type === 'select' ? 'me-9' : 'px-3'
          }`}
          onClick={() => clearInput(slug)}
          style={{color: 'rgb(153, 153, 153)'}}
        >
          <svg
            height='20'
            width='20'
            viewBox='0 0 20 20'
            aria-hidden='true'
            focusable='false'
            fill='currentColor'
          >
            <path d='M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z'></path>
          </svg>
        </span>
      )
    }
    return null
  }

  const clearInput = (slug: string) => {
    const temp = {...filterValues}
    delete temp?.[slug]
    setFilterValues({...temp})
  }

  const DisplayField = (single_filter, index: number) => {
    if (single_filter.slug === 'createdAt') {
      return (
        <div className='row'>
          <div className='col-md-6'>
            <label className='form-label fs-6  text-dark col-md-12'>{'From'}</label>
            <Flatpickr
              options={{
                enableTime: false,
                maxDate: selectedDateTo ?? moment().endOf('day').toISOString(),
                dateFormat: datePlaceHolder
                  .replace('MM', 'm')
                  .replace('DD', 'd')
                  .replace('YYYY', 'Y'),
              }}
              placeholder={datePlaceHolder}
              value={selectedDateFrom ?? ''}
              onChange={([date]) => {
                if (date === undefined) {
                  clearInput(`createdAt.greaterThanOrEqual`)
                } else {
                  let formattedDate = date.toISOString()
                  const istTime = convertUtcToIst(formattedDate).slice(0, -5) + 'Z'
                  setFilterValues({
                    ...filterValues,
                    [`createdAt.greaterThanOrEqual`]: istTime,
                  })
                }
                setSelectedDateFrom(date)
              }}
              className={clsx('form-control form-control-lg form-control-solid')}
            />
          </div>
          <div className='col-md-6 mt-4 mt-md-0'>
            <label className='form-label fs-6  text-dark col-md-12'>{'To'}</label>
            <Flatpickr
              options={{
                enableTime: false,
                minDate: selectedDateFrom,
                maxDate: moment().endOf('day').toISOString(),
                dateFormat: datePlaceHolder
                  .replace('MM', 'm')
                  .replace('DD', 'd')
                  .replace('YYYY', 'Y'),
              }}
              placeholder={datePlaceHolder}
              value={selectedDateTo ?? ''}
              onChange={([date]) => {
                if (date === undefined) {
                  clearInput(`createdAt.lessThan`)
                } else {
                  let formattedDate = date.toISOString()
                  const istTime = convertUtcToIst(formattedDate).slice(0, -5) + 'Z'
                  setFilterValues({...filterValues, [`createdAt.lessThan`]: istTime})
                }
                setSelectedDateTo(date)
              }}
              className={clsx('form-control form-control-lg form-control-solid')}
            />
          </div>
        </div>
      )
    } else if (single_filter.data?.length > 0) {
      return (
        <>
          <label className='form-label fs-6  text-dark'>{single_filter.name}</label>
          <div className='position-relative'>
            <select
              className='form-select form-select-solid cursor-pointer'
              data-allow-clear='true'
              data-kt-select2='true'
              data-placeholder='Select option'
              data-kt-user-table-filter='role'
              data-hide-search='true'
              style={{
                color: filterValues[single_filter.slug] === undefined ? '#99A1B7' : undefined,
              }}
              id={`${index}-select`}
              name={single_filter.name}
              value={filterValues[single_filter.slug] ?? ''}
              onChange={(e) =>
                setFilterValues({...filterValues, [single_filter.slug]: e.target.value})
              }
            >
              <option style={{display: 'none'}}>Select {single_filter.name}</option>
              {arrangeOptions(single_filter.data).map((e, index) => {
                return (
                  <option key={`${index}-${e}`} value={e}>
                    {e}
                  </option>
                )
              })}
            </select>
            {renderClearIcon(single_filter.slug, 'select', `${index}-select`)}
          </div>
        </>
      )
    } else {
      return (
        <>
          <label className='form-label fs-6  text-dark'>{single_filter.name}</label>
          <div className='position-relative'>
            <input
              onChange={(e) =>
                setFilterValues({...filterValues, [single_filter.slug]: e.target.value})
              }
              value={filterValues[single_filter.slug] ?? ''}
              placeholder={single_filter.name}
              className={clsx('form-control form-control form-control-solid')}
              name={single_filter.name}
              autoComplete='off'
            />
            {renderClearIcon(single_filter.slug, 'text')}
          </div>
        </>
      )
    }
  }

  let data: JSX.Element

  if (filters.length > 0 && !filterLoad) {
    data = (
      <>
        <div className='mt-8 row align-items-center'>
          {filters.map((single_filter, index) => {
            return (
              <div className=' col-md-4 col-sm-12 col-xs-12 mb-8' key={single_filter._id}>
                {DisplayField(single_filter, index)}
              </div>
            )
          })}
        </div>
        <div className='row gap-5 justify-content-end'>
          <button
            className={'col-md-2 btn btn-light'}
            onClick={() => {
              isFirstRender.current = true
              setPagination({
                page: 1,
                size: 20,
              })
              setSelectedDateFrom(undefined)
              setSelectedDateTo(undefined)
              saveQueryListings([])
              setFilterValues({})
            }}
          >
            <span className='indicator-label'>Reset</span>
          </button>
          <button
            className={' col-md-2 btn btn-primary'}
            style={{
              height: '100%',
              minHeight: 'min-content',
            }}
            onClick={() => {
              if (_props.tableLoad === false) {
                handleSubmit()
              }
            }}
          >
            {!loading && <span className='indicator-label'>Search</span>}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </>
    )
  } else {
    data = (
      <>
        <div className='card-body'>
          <div className='card-px text-center'>
            <h2 className='fs-2x fw-bold mb-0'>No filter available</h2>
            <p className='text-gray-400 fs-4 fw-semibold py-7'>
              It looks like no filter options available in transaction detail
            </p>
          </div>
          <div className='text-center'>
            <img
              src={toAbsoluteUrl('/media/auth/membership.png')}
              alt=''
              className='mw-100 h-200px'
            />
          </div>
        </div>
      </>
    )
  }
  return data
}

export default FilterComp
