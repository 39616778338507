import axios, {AxiosResponse} from 'axios'
import {AuthModel, UserModel} from './_models'

const API_URL = process.env.REACT_APP_API_URL
export const LOGIN_URL = `${API_URL}/auth/signin`
export const LOGOUT_URL = `${API_URL}/auth/signout`
export const REQUEST_PASSWORD_URL = `${API_URL}/auth/forgot/password`
export const VALIDATE_OTP = `${API_URL}/auth/otp/validate`
export const RESEND_OTP = `${API_URL}/auth/otp/resend`
export const RESET_PASSWORD = `${API_URL}/auth/reset/password`
export const VERIFY_TOKEN = `${API_URL}/auth/url/verify`
export const USER_INFO = `${API_URL}/auth/me`
export const REFRESH_TOKEN = `${API_URL}/auth/refresh`

// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post<AuthModel>(LOGIN_URL, {
    email: email,
    password: password,
  })
}

export function validate(email: string, otp: number, token: string) {
  return axios.get<AuthModel>(`${VALIDATE_OTP}?otp=${otp}&email=${email}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

export function verifyToken(resetToken: string, linkType: string) {
  const header = {
    Authorization: `Bearer ${resetToken}`,
    token: resetToken,
    type: linkType,
  }
  return axios.get<AuthModel>(VERIFY_TOKEN, {
    headers: header,
  })
}

export function resetPassword(
  query: string,
  password: string,
  confirmpassword: string,
  token: string
) {
  return axios.post(
    `${RESET_PASSWORD}?${query}`,
    {
      password: password,
      confirmPassword: confirmpassword,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )
}

export function resend(email: string, token: string) {
  return axios.get(`${RESEND_OTP}?email=${email}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post(REQUEST_PASSWORD_URL, {
    email: email,
  })
}

export function getUserByToken(token: string) {
  return axios.get<UserModel>(USER_INFO, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

export function logoutUser() {
  return axios.get(LOGOUT_URL)
}

export const get_new_token = async (token: string) => {
  return axios
    .get(REFRESH_TOKEN, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((d: AxiosResponse<{accessToken: string}>) => d.data)
}
