/* eslint-disable react/jsx-no-target-blank */
import {useIntl} from 'react-intl'
import {AsideMenuItem} from './AsideMenuItem'
import {AsideMenuItemWithSubMain} from './AsideMenuItemWithSubMain'
import {get_providers} from '../../../app/modules/panel/core/_request'
import {useEffect, useState} from 'react'
import {usePageData} from '../core'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {providersSetup} from '../../../app/providers-setup/Constant'
import {useAuth} from '../../../app/modules/auth'
import {parseJwt} from '../../../app/modules/panel/core/helper'
import {getReportMenuStructure} from '../../../app/analytics-setup/Constant'
import {PERMISSION_ARRKEY} from '../../../app/modules/auth/core/constant'

export function AsideMenuMain() {
  const intl = useIntl()
  const {providers, setProviders} = usePageData()
  const {auth, allowedPermissions, getAllowedPermissionValue} = useAuth()
  const {activeRetailer} = parseJwt(auth?.accessToken ?? '')
  const defaultAsideMenu = [
    {
      to: '/transactions',
      title: intl.formatMessage({id: 'TRANSACTIONS'}),
      fontIcon: 'bi bi-card-list',
      bsTitle: intl.formatMessage({id: 'TRANSACTIONS'}),
      className: 'py-2',
      permissionKeys: PERMISSION_ARRKEY.GET_TRANSACTIONS,
    },
    {
      to: '/analytics',
      title: intl.formatMessage({id: 'MENU.ANALYTICS'}),
      fontIcon: 'bi-file-earmark-ruled fs-2',
      bsTitle: intl.formatMessage({id: 'MENU.ANALYTICS'}),
      className: 'py-2',
      children: [...generateReportMenuStructure(getReportMenuStructure(activeRetailer))],
    },
    {
      to: '/logistics',
      title: intl.formatMessage({id: 'MENU.LOGISTICS'}),
      fontIcon: 'bi bi-box-seam',
      bsTitle: intl.formatMessage({id: 'MENU.LOGISTICS'}),
      className: 'py-2',
      permissionKeys: PERMISSION_ARRKEY.GET_LOGISTICS,
      children: providers?.map((provider) => ({
        to: `/logistics/${provider.apiProperty}`,
        title: provider.name,
        bsTitle: provider.name,
        hasBullet: true,
      })),
    },
    {
      to: '/users-management',
      title: intl.formatMessage({id: 'MENU.USERS'}),
      fontIcon: 'bi-people fs-2',
      bsTitle: intl.formatMessage({id: 'MENU.USERS'}),
      className: 'py-2',
      children: [
        {
          to: `/users-management/users`,
          title: intl.formatMessage({id: 'USERS'}),
          bsTitle: intl.formatMessage({id: 'USERS'}),
          hasBullet: true,
          permissionKeys: PERMISSION_ARRKEY.GET_USERS,
        },
        {
          to: `/users-management/roles`,
          title: intl.formatMessage({id: 'MENU.ROLES'}),
          bsTitle: intl.formatMessage({id: 'MENU.ROLES'}),
          hasBullet: true,
          permissionKeys: PERMISSION_ARRKEY.GET_ROLES,
        },
        {
          to: `/users-management/permissions`,
          title: intl.formatMessage({id: 'MENU.PERMISSIONS'}),
          bsTitle: intl.formatMessage({id: 'MENU.PERMISSIONS'}),
          hasBullet: true,
          permissionKeys: PERMISSION_ARRKEY.GET_PERMISSIONS,
        },
        {
          to: `/users-management/activities`,
          title: intl.formatMessage({id: 'MENU.ACTIVITIES'}),
          bsTitle: intl.formatMessage({id: 'MENU.ACTIVITIES'}),
          hasBullet: true,
          permissionKeys: PERMISSION_ARRKEY.GET_ACTIVITY_LOGS,
        },
      ],
    },
    {
      to: '/stores-management',
      title: intl.formatMessage({id: 'MENU.STORES'}),
      fontIcon: 'bi bi-shop',
      bsTitle: intl.formatMessage({id: 'MENU.STORES'}),
      className: 'py-2',
      permissionKeys: PERMISSION_ARRKEY.GET_STORES,
    },
  ]
  const [asideMenuArr, setAsideMenuArr] = useState(defaultAsideMenu)

  function generateReportMenuStructure(menuItems) {
    return menuItems.map((value) => {
      const obj = {
        to: `/analytics${value?.to}`,
        title: intl.formatMessage({id: value?.titleId}),
        bsTitle: intl.formatMessage({id: value?.bsTitleId}),
        hasBullet: value?.hasBullet,
      }
      if (value?.children && value?.children?.length > 0) {
        return {
          ...obj,
          children: generateReportMenuStructure(value.children),
        }
      } else {
        return obj
      }
    })
  }

  const fetchProviders = () => {
    get_providers().then((res) => {
      const sorted = res
        .filter((provider) => {
          const found = providersSetup.find((item) => item.apiProperty === provider.apiProperty)
          return found?.allowedRetailers.includes(activeRetailer.id)
        })
        .sort((a, b) => {
          if (a.name.toLowerCase() === b.name.toLowerCase()) return 0
          return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
        })
      setProviders(sorted)
    })
  }

  const getLastMenuItems = (items: any[]) => {
    return items?.map((item) => {
      return <AsideMenuItem {...item} key={item.title} />
    })
  }
  /**
   * initializing aside menu according to allowed permissions
   */
  const initAsideMenu = () => {
    const filteredMenu = defaultAsideMenu?.filter((menu) => {
      return !menu?.permissionKeys || getAllowedPermissionValue(menu?.permissionKeys)
    })
    filteredMenu.forEach((menu, index) => {
      if (menu?.children) {
        filteredMenu[index].children = menu.children.filter((childMenu) => {
          return !childMenu?.permissionKeys || getAllowedPermissionValue(childMenu?.permissionKeys)
        })
      }
    })
    setAsideMenuArr(filteredMenu.filter((menu) => !menu?.children || menu?.children.length > 0))
  }

  useEffect(() => {
    fetchProviders()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    initAsideMenu()
    // eslint-disable-next-line
  }, [allowedPermissions, providers])
  return (
    <>
      {asideMenuArr?.map(({children, ...menu}) =>
        children ? (
          <AsideMenuItemWithSubMain key={menu.title} {...menu}>
            {children?.map((childOne) => {
              const {children: childrenOne, ...othersOne} = childOne
              if (childrenOne && childrenOne?.length > 0) {
                return childrenOne?.map((childOne) => {
                  const {children: childrenTwo, ...othersTwo} = childOne
                  if (childrenTwo && childrenTwo?.length > 0) {
                    return (
                      <AsideMenuItemWithSub {...othersOne} key={othersOne.title}>
                        {childrenTwo?.map((child) => {
                          return (
                            <AsideMenuItemWithSub {...othersTwo} key={othersTwo.title}>
                              {getLastMenuItems(childrenTwo)}
                            </AsideMenuItemWithSub>
                          )
                        })}
                      </AsideMenuItemWithSub>
                    )
                  } else {
                    return (
                      <AsideMenuItemWithSub {...othersOne} key={othersOne.title}>
                        {getLastMenuItems(childrenOne)}
                      </AsideMenuItemWithSub>
                    )
                  }
                })
              } else {
                return <AsideMenuItem {...othersOne} key={childOne.title} />
              }
            })}
          </AsideMenuItemWithSubMain>
        ) : (
          <AsideMenuItem {...menu} key={menu.title} />
        )
      )}
    </>
  )
}
