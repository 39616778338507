import {useMemo} from 'react'
import {toast} from 'react-toastify'
import {showToast} from '../../app/modules/panel/core/helper'
import {getAuth} from '../../app/modules/auth'

const WithAxios = ({children, intl, axios}) => {
  axios.defaults.headers.Accept = 'application/json'
  useMemo(() => {
    const options = {autoClose: 3000}

    axios.interceptors.request.use(
      (config: {headers: {Authorization: string}}) => {
        const auth = getAuth()
        if (auth?.accessToken) {
          config.headers.Authorization = `Bearer ${auth.accessToken}`
        }
        return config
      },
      (err: any) => Promise.reject(err)
    )
    axios.interceptors.response.use(
      (response: any) => {
        const {
          data: {message, show},
        } = response
        if (show) {
          if (intl.messages?.[message]) {
            toast.success(
              showToast(
                intl.formatMessage({
                  id: message,
                })
              ),
              options
            )
          } else {
            toast.warn(
              showToast(
                intl.formatMessage({
                  id: 'MESSAGE.ERROR',
                })
              ),
              options
            )
          }
        }
        return response
      },
      (err: any) => {
        const {
          response: {data},
        } = err
        if (intl.messages?.[data?.message]) {
          toast.error(
            showToast(
              intl.formatMessage({
                id: data.message,
              })
            ),
            options
          )
        } else {
          toast.error(
            showToast(
              intl.formatMessage({
                id: 'ERROR.OCCURRED',
              })
            ),
            options
          )
        }
        return Promise.reject(err)
      }
    )
    // eslint-disable-next-line
  }, [intl])

  return children
}

export default WithAxios
