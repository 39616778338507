import {useEffect, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useLocation, useNavigate} from 'react-router-dom'
import {useFormik} from 'formik'
import {resetPassword, verifyToken} from '../core/_requests'
import {PasswordMeterComponent} from '../../../../general/assets/ts/components'

const initialValues = {
  changepassword: '',
  password: '',
}

const forgotPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, 'Minimum 8 character')
    .max(50, 'Maximum 50 character')
    .required('Password is required')
    .matches(
      /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/,
      'Require at least one lowercase , upercase and digit'
    )
    .matches(/[!@#$%^&*()\\[\]{}\-_+=~`|:;"'<>,./?]/g, 'Special character is missing '),
  changepassword: Yup.string()
    .min(8, 'Minimum 8 character')
    .max(50, 'Maximum 50 character')
    .required('Password confirmation is required')
    .oneOf([Yup.ref('password')], "Password and Confirm Password didn't match"),
})

export function ResetPassword() {
  const location = useLocation()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: async (values, {setSubmitting}) => {
      const {changepassword, password} = values
      const queryParams = new URLSearchParams(location.search)
      const typeFromQuery = queryParams.get('type')
      const tokenFromQuery = queryParams.get('access_token') ?? (queryParams.get('token') as string)
      setLoading(true)
      try {
        const Query = `type=${typeFromQuery}&token=${tokenFromQuery}`
        await resetPassword(Query, password, changepassword, tokenFromQuery ?? '').then((res) => {
          if (res.status === 200) {
            navigate('/auth/login')
          }
        })
      } finally {
        setLoading(false)
        setSubmitting(false)
      }
    },
  })
  const verifyTokenByApi = async () => {
    const queryParams = new URLSearchParams(location.search)
    const typeFromQuery = queryParams.get('type') as string
    const tokenFromQuery = queryParams.get('access_token') ?? (queryParams.get('token') as string)
    if (tokenFromQuery) {
      await verifyToken(tokenFromQuery, typeFromQuery).catch(({response}) => {
        if (response.status === 401) {
          navigate('/expire')
        }
      })
    } else {
      navigate('/auth')
    }
  }
  useEffect(() => {
    verifyTokenByApi()
    PasswordMeterComponent.bootstrap()
    //eslint-disable-next-line
  }, [])
  const handleDisable = () => {
    if (formik.values.changepassword === '' || formik.values.password === '') {
      return true
    }
    return formik.isSubmitting || !formik.isValid
  }
  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_password_reset_form'
      onSubmit={formik.handleSubmit}
    >
      <div className='text-center mb-10'>
        <h1 className='text-dark fw-bolder mb-3'>Setup New Password</h1>
        <div className='text-gray-500 fw-semibold fs-6'>
          Have you already reset the password ?{' '}
          <Link to='/auth/login' className='link-primary'>
            Sign in
          </Link>
        </div>
      </div>
      <div className='fv-row mb-8' data-kt-password-meter='true'>
        <div className='mb-1'>
          <label className='form-label fw-bolder text-dark fs-6'>Password</label>
          <div className='position-relative mb-3'>
            <input
              type='password'
              placeholder='Password'
              autoComplete='off'
              {...formik.getFieldProps('password')}
              className={clsx(
                'form-control bg-transparent',
                {
                  'is-invalid': formik.touched.password && formik.errors.password,
                },
                {
                  'is-valid': formik.touched.password && !formik.errors.password,
                }
              )}
            />
            {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.password}</span>
                </div>
              </div>
            )}
          </div>
          <div
            className='d-flex align-items-center mb-3'
            data-kt-password-meter-control='highlight'
          >
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
          </div>
        </div>
        <div className='text-muted'>
          Use 8 or more characters with a mix of letters, numbers & symbols.
        </div>
      </div>
      <div className='fv-row mb-5'>
        <label className='form-label fw-bolder text-dark fs-6'>Confirm Password</label>
        <input
          type='password'
          placeholder='Password confirmation'
          autoComplete='off'
          {...formik.getFieldProps('changepassword')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.changepassword && formik.errors.changepassword,
            },
            {
              'is-valid': formik.touched.changepassword && !formik.errors.changepassword,
            }
          )}
        />
        {formik.touched.changepassword && formik.errors.changepassword && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.changepassword}</span>
            </div>
          </div>
        )}
      </div>
      <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
        <button
          type='submit'
          disabled={handleDisable()}
          id='kt_password_reset_submit'
          className='btn btn-primary me-4'
        >
          {!loading && <span className='indicator-label'>Submit</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        <Link to='/auth/login'>
          <button
            type='button'
            id='kt_login_password_reset_form_cancel_button'
            className='btn btn-light'
            disabled={formik.isSubmitting || !formik.isValid}
          >
            Cancel
          </button>
        </Link>
      </div>
    </form>
  )
}
