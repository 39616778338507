import React from 'react'
import {LogisticsQueryRequestProvider} from './core/LogisticsRequestProvider'
import {LogisticsQueryResponseProvider} from './core/LogisticsResponseProvider'
import LogisticsLayout from './LogisticsLayout'

const LogisticsWrapper = () => {
  return (
    <LogisticsQueryRequestProvider>
      <LogisticsQueryResponseProvider>
        <LogisticsLayout />
      </LogisticsQueryResponseProvider>
    </LogisticsQueryRequestProvider>
  )
}

export default LogisticsWrapper
