import {FC, useEffect, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import clsx from 'clsx'
import {isNotEmpty} from '../../../../../../general/helpers'
import {useUsers} from '../../Users'
import {codeToTitle, discardForm, getStatus} from '../../../core/helper'
import {create_user, get_retailers, get_roles, update_user} from '../../../core/_request'
import {useAuth} from '../../../../auth'
import {Retailer, Role} from '../../../core/_models'
import {useLocation, useParams} from 'react-router'
import {useIntl} from 'react-intl'

type Props = {
  fetchUsers: () => void
}

interface UserForm {
  name: string
  email: string
  _id: string | undefined
  status: string
  role: string
  retailerId: string[]
}

const UserEditForm: FC<Props> = ({fetchUsers}) => {
  const {auth} = useAuth()
  const {state} = useLocation()
  const {roleId} = useParams()
  const intl = useIntl()
  const {usersList, itemIdForUpdate, saveItemIdForUpdate} = useUsers()
  const userObj = usersList.find((ele) => ele._id === itemIdForUpdate)
  const user = {_id: userObj?._id, name: userObj?.name, email: userObj?.email, ...userObj}
  const [loading, setLoading] = useState(false)
  const [roles, setRoles] = useState<Role[]>([])
  const [retailersState, setRetailersState] = useState<{
    loading: boolean
    retailers: Array<Retailer>
  }>({loading: false, retailers: []})
  const isEditing = isNotEmpty(user._id)
  const [userForEdit, setUserForEdit] = useState<UserForm>({
    ...user,
    name: user?.name ?? '',
    email: user?.email ?? '',
    status: isEditing ? getStatus(user) : '',
    role: user?.role ?? '',
    retailerId: user?.retailerId?.map((id) => id.toString()) ?? [],
  })
  const statusArr = ['active', 'inactive']
  const locationState= state as any
  const isRolePath = locationState?.from === 'roles'

  useEffect(() => {
    if (!isRolePath || !isEditing) fetchRoles()
    fetchRetailers()
    // eslint-disable-next-line
  }, [])

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      fetchUsers()
    }
    saveItemIdForUpdate(undefined)
  }

  const getStatusParams = (status: string) => {
    if (status === 'active') {
      return {isActive: true}
    } else if (status === 'inactive') {
      return {
        isActive: false,
      }
    }
  }

  const fetchRoles = async () => {
    setLoading(true)
    try {
      const {roles: items} = await get_roles()
      setRoles(items)
      if (isEditing) {
        const roleFound = items.find((item) => item.name === user.role)
        if (roleFound) setUserForEdit((prev) => ({...prev, role: roleFound._id}))
      }
    } catch {
    } finally {
      setLoading(false)
    }
  }
  /**
   * fetching retailer list
   */
  const fetchRetailers = async () => {
    setRetailersState((prev) => ({...prev, loading: true}))
    try {
      const retailers = await get_retailers()
      setRetailersState((prev) => ({...prev, retailers}))
    } catch (e) {
    } finally {
      setRetailersState((prev) => ({...prev, loading: false}))
    }
  }

  /**
   * handles discard action for form
   */
  const discardModal = () => {
    if (formik.dirty) {
      discardForm(() => {
        saveItemIdForUpdate(undefined)
        formik.resetForm()
      })
    } else {
      cancel()
    }
  }

  const editUserSchema = Yup.object().shape({
    email: Yup.string().email('Please enter a valid email').required('Email is required'),
    name: Yup.string()
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('Name is required'),
    status: Yup.string(),
    role: Yup.string(),
    retailerId: Yup.array()
      .of(Yup.string())
      .min(1, intl.formatMessage({id: 'SELECT.ONE.RETAILER'})),
  })

  const formik = useFormik({
    initialValues: userForEdit,
    validationSchema: editUserSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        const payload: any = {
          name: values.name,
          retailerId: values.retailerId.map((id) => parseInt(id)),
        }
        if (isEditing) {
          if (!isRolePath) {
            Object.assign(payload, {role: values.role})
          }
          if (values.status !== 'pending') {
            const statusParams = getStatusParams(values.status)
            Object.assign(payload, statusParams)
          }
          await update_user(auth?.accessToken as string, itemIdForUpdate!, payload)
        } else {
          Object.assign(payload, {
            email: values.email,
            role: values.role,
          })
          if (isRolePath && roleId) {
            Object.assign(payload, {role: roleId})
          }
          await create_user(auth?.accessToken as string, payload)
        }
        cancel(true)
      } catch {
      } finally {
        setSubmitting(false)
      }
    },
    validateOnChange: true,
    validateOnMount: true,
    enableReinitialize: true,
  })

  return (
    <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
      {/* begin::Scroll */}
      <div
        className='d-flex flex-column me-n7 ps-1 pe-7 mh-300px overflow-auto'
        id='kt_modal_add_user_scroll'
        data-kt-scroll='true'
        data-kt-scroll-activate='{default: false, lg: true}'
        data-kt-scroll-max-height='auto'
        data-kt-scroll-dependencies='#kt_modal_add_user_header'
        data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
        data-kt-scroll-offset='300px'
      >
        {/* begin::Input group */}
        <div className='fv-row mb-7'>
          {/* begin::Label */}
          <label className='required fw-bold fs-6 mb-2'>
            {intl.formatMessage({id: 'FULLNAME'})}
          </label>
          {/* end::Label */}

          {/* begin::Input */}
          <input
            placeholder='Full name'
            {...formik.getFieldProps('name')}
            type='text'
            name='name'
            className={clsx(
              'form-control form-control-solid mb-3 mb-lg-0',
              {'is-invalid': formik.touched.name && formik.errors.name},
              {
                'is-valid': formik.touched.name && !formik.errors.name,
              }
            )}
            autoComplete='off'
            disabled={formik.isSubmitting}
          />
          {formik.touched.name && formik.errors.name && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.name}</span>
              </div>
            </div>
          )}
          {/* end::Input */}
        </div>
        {/* end::Input group */}

        {!isEditing ? (
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>
              {intl.formatMessage({id: 'EMAIL'})}
            </label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder='Email'
              {...formik.getFieldProps('email')}
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.email && formik.errors.email},
                {
                  'is-valid': formik.touched.email && !formik.errors.email,
                }
              )}
              type='email'
              name='email'
              autoComplete='off'
              disabled={formik.isSubmitting}
            />
            {/* end::Input */}
            {formik.touched.email && formik.errors.email && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.email}</span>
                </div>
              </div>
            )}
          </div>
        ) : (
          <></>
        )}
        <div className='fv-row mb-7'>
          <label className='required fw-bold fs-6 mb-2'>
            {intl.formatMessage({id: 'RETAILER.ID'})}
          </label>
          <select
            {...formik.getFieldProps('retailerId')}
            className='form-select form-select-solid cursor-pointer'
            data-placeholder='Select option'
            name='retailerId'
            disabled={formik.isSubmitting || retailersState.loading}
            multiple
            size={retailersState.loading ? 1 : 3}
          >
            {retailersState.loading ? (
              <option>{intl.formatMessage({id: 'LOADING'})}...</option>
            ) : (
              retailersState.retailers.map((item, index) => {
                return (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                )
              })
            )}
          </select>
          {formik.touched.retailerId && formik.errors.retailerId && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.retailerId}</span>
              </div>
            </div>
          )}
        </div>

        {isEditing && formik.values.status !== 'pending' ? (
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>
              {intl.formatMessage({id: 'STATUS'})}
            </label>
            {/* end::Label */}
            <div className='position-relative'>
              <select
                {...formik.getFieldProps('status')}
                className='form-select form-select-solid cursor-pointer'
                data-allow-clear='true'
                data-kt-select2='true'
                data-placeholder='Select option'
                data-kt-user-table-filter='role'
                data-hide-search='true'
                name='status'
                disabled={formik.isSubmitting}
              >
                {statusArr.map((ele, index) => {
                  return (
                    <option key={`${index}-${ele}`} value={ele}>
                      {codeToTitle(ele)}
                    </option>
                  )
                })}
              </select>
            </div>
          </div>
        ) : (
          <></>
        )}

        {!isRolePath ? (
          <div className='mb-7'>
            <label className='required fw-bold fs-6 mb-5'>{intl.formatMessage({id: 'ROLE'})}</label>
            {loading ? (
              <div className='d-flex justify-content-center align-items-center'>
                <span className='me-2'> {intl.formatMessage({id: 'PLEASE.WAIT'})}</span>
                <span className='spinner-border spinner-border-md align-middle text-primary' />
              </div>
            ) : (
              roles.map((role, ind) => (
                <div key={role._id}>
                  <div className='d-flex fv-row'>
                    <div className='form-check form-check-custom form-check-solid'>
                      <input
                        className='form-check-input me-3'
                        {...formik.getFieldProps('role')}
                        name='role'
                        type='radio'
                        value={role._id}
                        checked={formik.values.role === role._id}
                        id={`kt_modal_update_role_option_${role._id}`}
                        disabled={formik.isSubmitting}
                      />
                      <label
                        className='form-check-label'
                        htmlFor={`kt_modal_update_role_option_${role._id}`}
                      >
                        <div className='fw-bold text-gray-800'>{codeToTitle(role.name ?? '')}</div>
                      </label>
                    </div>
                  </div>
                  {ind < roles.length - 1 ? (
                    <div className='separator separator-dashed my-5' />
                  ) : (
                    <></>
                  )}
                </div>
              ))
            )}
          </div>
        ) : (
          <></>
        )}
      </div>

      {/* end::Scroll */}

      {/* begin::Actions */}
      <div className='text-center pt-15'>
        <button
          type='button'
          onClick={discardModal}
          className='btn btn-light me-3'
          data-kt-users-modal-action='cancel'
          disabled={formik.isSubmitting}
        >
          {intl.formatMessage({id: 'DISCARD'})}
        </button>

        <button
          type='submit'
          className='btn btn-primary'
          data-kt-users-modal-action='submit'
          disabled={
            formik.isSubmitting ||
            !formik.isValid ||
            (Object.keys(formik.touched).length === 0 && !formik.dirty) ||
            (!isRolePath && formik.values.role === '')
          }
        >
          {formik.isSubmitting ? (
            <span>
              {intl.formatMessage({id: 'PLEASE.WAIT'})}{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          ) : (
            <span className='indicator-label'>{intl.formatMessage({id: 'SUBMIT'})}</span>
          )}
        </button>
      </div>
      {/* end::Actions */}
    </form>
  )
}

export {UserEditForm}
