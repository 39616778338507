import React from 'react'

const ListLoading = () => {
  const styles = {
    borderRadius: '0.475rem',
    boxShadow: '0 0 50px 0 rgb(82 63 105 / 15%)',
    backgroundColor: '#fff',
    fontWeight: '500',
    margin: '0',
    width: 'auto',
    padding: '1rem 2rem',
    top: 'calc(50% - 2rem)',
    left: 'calc(50% - 4rem)',
  }

  const disableStyles = {
    background: 'rgba(255, 255, 255, 0.5)',
  }

  return (
    <div className='w-100 h-100 position-absolute top-0 start-0' style={{...disableStyles}}>
      <div className='position-absolute text-center' style={{...styles}}>
        <span className='indicator-progress' style={{display: 'block'}}>
          Please wait...
          <span className='spinner-border spinner-border-md align-middle ms-2 text-primary'></span>
        </span>
      </div>
    </div>
  )
}

export default ListLoading
