import {FC, useState, createContext, useContext} from 'react'
import {WithChildren, QueryStateI, QueryRequestContextPropsI} from '../../../../../general/helpers'

const QueryRequestContext = createContext<QueryRequestContextPropsI>({
  state: {page: 1, size: 10},
  updateState: () => {},
  loading: false,
  toggleLoading: () => {},
})

const QueryRequestProvider: FC<WithChildren> = ({children}) => {
  const [state, setState] = useState<QueryStateI>({page: 1, size: 10})
  const [loading, setLoading] = useState<boolean>(true)

  const updateState = (updates: Partial<QueryStateI>, persistOldValues: boolean = true) => {
    let updatedState = {...state, ...updates} as QueryStateI & {[key: string]: string | number}
    if (!persistOldValues) {
      updatedState = {...updates} as QueryStateI & {[key: string]: string | number}
    }
    setState(updatedState)
  }

  const toggleLoading = () => setLoading((prev) => !prev)

  return (
    <QueryRequestContext.Provider value={{state, updateState, loading, toggleLoading}}>
      {children}
    </QueryRequestContext.Provider>
  )
}

const useQueryRequest = () => useContext(QueryRequestContext)
export {QueryRequestProvider, useQueryRequest}
