// @ts-nocheck
import {Column} from 'react-table'
import {User} from '../../core/_models'
import {UserInfoCell} from './UserInfoCell'
import {UserActionsCell} from './UserActionsCell'
import UserStatusCell from './UserStatusCell'
import {CreatedAtCell} from './CreatedAtCell'
import CustomHeader from '../../../components/header/CustomHeader'

const getUserColumns = (fetchUsers: () => void) => {
  const usersColumns: ReadonlyArray<Column<User>> = [
    {
      Header: (props) => <CustomHeader tableProps={props} title='Name' className='min-w-125px' />,
      id: 'name',
      Cell: ({...props}) => <UserInfoCell user={props.data[props.row.index]} />,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} title='Role' className='min-w-125px' />,
      accessor: 'role',
      id: 'role',
    },
    {
      Header: (props) => <CustomHeader tableProps={props} title='Status' className='min-w-125px' />,
      id: 'status',
      Cell: ({...props}) => <UserStatusCell user={props.data[props.row.index]} />,
    },
    {
      Header: (props) => (
        <CustomHeader tableProps={props} title='Created At' className='min-w-125px' />
      ),
      accessor: 'createdAt',
      Cell: ({...props}) => <CreatedAtCell createdAt={props.data[props.row.index].createdAt} />,
    },
    {
      Header: (props) => (
        <CustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
      ),
      id: 'actions',
      Cell: ({...props}) => (
        <UserActionsCell user={props.data[props.row.index]} fetchUsers={fetchUsers} />
      ),
    },
  ]
  return usersColumns
}

export {getUserColumns}
