/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from 'react'
import {KTIcon, QueryState, useDebounce} from '../../../../general/helpers'
import ClearCross from './ClearCross'
import {useIntl} from 'react-intl'

interface Props {
  updateState: (params: Partial<QueryState>, triggerLoading?: boolean) => void
  placeHolderId: string
  searchKeys?: string[]
}

const ListSearch = (props: Props) => {
  const {updateState, placeHolderId, searchKeys} = props
  const [searchTerm, setSearchTerm] = useState<string>()
  const intl = useIntl()
  const debouncedSearchTerm = useDebounce(searchTerm, 500)
  useEffect(() => {
    if (debouncedSearchTerm !== undefined && searchTerm !== undefined) {
      const searchObj = {}
      if (searchKeys && searchKeys?.length > 0) {
        searchKeys.forEach((key) => {
          Object.assign(searchObj, {[key]: debouncedSearchTerm})
        })
      } else {
        Object.assign(searchObj, {search: debouncedSearchTerm})
      }
      updateState({...searchObj, page: 1}, true)
    }
  }, [debouncedSearchTerm])

  return (
    <div className='d-flex align-items-center position-relative'>
      <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
      <input
        type='text'
        data-kt-user-table-filter='search'
        className='form-control form-control-solid w-250px ps-14'
        placeholder={intl.formatMessage({id: placeHolderId})}
        value={searchTerm ?? ''}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      {searchTerm && <ClearCross onClick={() => setSearchTerm('')} />}
    </div>
  )
}

export {ListSearch}
