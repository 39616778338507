import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {GET} from '../../core/_request'
import {useAuth} from '../../../auth'
import {fieldMapping} from './fieldMapping'
import DetailSection from './DetailSection'
import {KTCardBody} from '../../../../../general/helpers'
import {PageTitle} from '../../../../../general/layout/core'
import {getBreadcrumbs} from '../../../../routing/Breadcrumbs'
import {useIntl} from 'react-intl'

export default function TransactionDetails() {
  const transactionID = useParams()?.id
  const {auth} = useAuth()
  const [details, setDetails] = useState<any>()
  const [labels, setLabels] = useState<{
    [name: string]: string
  }>({})
  const [detailsLoader, setDetailsLoader] = useState<boolean>()
  const intl = useIntl()

  function fetchAllLabels(obj: any) {
    const result = {}
    function recurse(current, path) {
      for (const key in current) {
        const newPath = path ? `${path}.${key}` : key
        if (typeof current[key] === 'object') {
          recurse(current[key], newPath)
        } else {
          result[newPath] = current[key]
        }
      }
    }
    recurse(obj, '')
    return result
  }

  useEffect(() => {
    const detailsAPI = `transactions/${transactionID}/details`
    const labelsAPI = `transactions/labels`
    setDetailsLoader(true)
    GET(labelsAPI, auth?.accessToken ?? '').then((res) => {
      const {data} = res
      let tempLabels: any = data?.[0]?.['labels']
      setLabels(fetchAllLabels(tempLabels))
    })
    GET(detailsAPI, auth?.accessToken ?? '').then((res) => {
      const {data} = res
      setDetails(data?.[0] ?? data)
      setDetailsLoader(false)
    })
    //eslint-disable-next-line
  }, [])
  return (
    <>
      <PageTitle breadcrumbs={getBreadcrumbs(intl, 'viewTransaction')}>
        Transaction Details
      </PageTitle>
      <KTCardBody>
        <div className='row'>
          {Object.keys(fieldMapping).map((section: string, index: number) => {
            return (
              <div
                className='col-sm-12 col-xs-12 col-md-6 col-lg-6 mb-10 '
                key={`${index}-section`}
              >
                <DetailSection
                  details={details}
                  title={section}
                  fields={fieldMapping[section]}
                  labels={labels}
                  detailsLoader={detailsLoader}
                />
              </div>
            )
          })}
        </div>
      </KTCardBody>
    </>
  )
}
