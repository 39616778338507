import React from 'react'
import Flatpickr from 'react-flatpickr'
import clsx from 'clsx'
import moment from 'moment'
import {useIntl} from 'react-intl'
import {getDatePlaceholder, parseJwt} from '../core/helper'
import {useAuth} from '../../auth'

interface Props {
  onChangeFrom: (value: string) => void
  onChangeTo: (value: string) => void
  label: string
  valueFrom: string
  valueTo: string
}

const DatePicker = (props: Props) => {
  const {onChangeFrom, onChangeTo, label, valueFrom, valueTo} = props
  const intl = useIntl()
  const {auth} = useAuth()
  const {activeRetailer} = parseJwt(auth?.accessToken ?? '')
  const datePlaceHolder = getDatePlaceholder(activeRetailer?.locale, activeRetailer?.timeZone)

  return (
    <div className={`row align-items-end`}>
      <div className='col-md-6'>
        <label className='form-label fs-6  text-dark col-md-12'>{label}</label>
        <Flatpickr
          options={{
            enableTime: false,
            maxDate: valueTo ?? moment().endOf('day').toISOString(),
            dateFormat: datePlaceHolder.replace('MM', 'm').replace('DD', 'd').replace('YYYY', 'Y'),
          }}
          placeholder={intl.formatMessage({id: 'FROM'})}
          value={valueFrom ?? ''}
          onChange={([date]) => {
            if (date === undefined) {
              onChangeFrom?.('')
            } else {
              onChangeFrom?.(`${moment(date).format('YYYY-MM-DD')}T00:00:00Z`)
            }
          }}
          className={clsx('form-control form-control-lg form-control-solid')}
        />
      </div>
      <div className='col-md-6 pt-4'>
        <Flatpickr
          options={{
            enableTime: false,
            minDate: valueFrom,
            maxDate: moment().endOf('day').toISOString(),
            dateFormat: datePlaceHolder.replace('MM', 'm').replace('DD', 'd').replace('YYYY', 'Y'),
          }}
          placeholder={intl.formatMessage({id: 'TO'})}
          value={valueTo ?? ''}
          onChange={([date]) => {
            if (date === undefined) {
              onChangeTo?.('')
            } else {
              onChangeTo?.(`${moment(date).format('YYYY-MM-DD')}T11:59:59Z`)
            }
          }}
          className={clsx('form-control form-control-lg form-control-solid')}
        />
      </div>
    </div>
  )
}

export default DatePicker
