import React, {useState} from 'react'
import {useAuth} from '../../../app/modules/auth'
import {parseJwt, sortObjArr} from '../../../app/modules/panel/core/helper'
import {switch_retailer} from '../../../app/modules/panel/core/_request'
import {KTIcon} from '../../helpers'

const RetailerSwitch = () => {
  const {auth, saveAuth} = useAuth()
  const {assignedRetailers, activeRetailer} = parseJwt(auth?.accessToken ?? '')
  const [loading, setLoading] = useState(false)

  /**
   * initiates the call to get updated token with new retailer id
   * @param id retailer id
   */
  const updateAssignedRetailer = async (id: string) => {
    setLoading(true)
    try {
      const {accessToken} = await switch_retailer(id)
      saveAuth({...auth, accessToken})
      window.location.reload()
    } catch (e) {
    } finally {
      setLoading(false)
    }
  }
  return (
    <div>
      <button
        className='btn btn-light btn-active-light-primary btn-sm px-3'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        {activeRetailer?.name}
        {loading && <span className='spinner-border spinner-border-sm align-middle ms-2' />}
        <KTIcon iconName='down' className='fs-5 ms-2' />
      </button>
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-200px py-4'
        data-kt-menu='true'
      >
        {Array.isArray(assignedRetailers) &&
          sortObjArr(assignedRetailers, 'name').map((retailer) => (
            <div className='menu-item px-3' key={retailer.id}>
              <button
                className={`menu-link ${
                  retailer.id === activeRetailer.id ? 'active' : ''
                } px-3 btn btn-transparent btn-active-light-primary btn-sm w-100`}
                onClick={() => updateAssignedRetailer(retailer.id)}
              >
                {retailer.name}
              </button>
            </div>
          ))}
      </div>
    </div>
  )
}

export default RetailerSwitch
