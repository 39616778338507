import React from 'react'
import {QueryRequestProvider} from './core/PermissionsRequestProvider'
import {QueryResponseProvider} from './core/PermissionsResponseProvider'
import PermissionsLayout from './PermissionsLayout'
import {PermissionsProvider} from './core/Permissions'

const PermissionsWrapper = () => {
  return (
    <QueryRequestProvider>
      <QueryResponseProvider>
        <PermissionsProvider>
          <PermissionsLayout />
        </PermissionsProvider>
      </QueryResponseProvider>
    </QueryRequestProvider>
  )
}

export default PermissionsWrapper
