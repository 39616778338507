import {useEffect, useState} from 'react'
import {Outlet, useLocation} from 'react-router-dom'
import {AsideDefault} from '../aside/AsideDefault'

import {PageDataProvider} from '.'
import {MenuComponent} from '../../assets/ts/components'
import {Content} from '../Content'
import {HeaderWrapper} from '../header/HeaderWrapper'
import {Toolbar} from '../toolbar/Toolbar'
import {ScrollTop} from '../ScrollTop'
import {Footer} from '../Footer'
import NoInternet from '../../../app/modules/panel/components/NoInternet'

const MasterLayout = () => {
  const location = useLocation()
  const [online, setOnline] = useState<boolean>(navigator.onLine)

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [location.key])

  useEffect(() => {
    window.addEventListener('offline', (e) => {
      setOnline(false)
    })
    window.addEventListener('online', (e) => {
      setOnline(true)
    })
  }, [])
  return (
    <PageDataProvider>
      <div className='d-flex flex-column flex-root'>
        <div className='page d-flex flex-row flex-column-fluid'>
          <AsideDefault />
          <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
            <HeaderWrapper />
            {online ? <Toolbar /> : <></>}
            <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
              <Content>{online ? <Outlet /> : <NoInternet />}</Content>
            </div>
            <Footer />
          </div>
        </div>
      </div>
      <ScrollTop />
    </PageDataProvider>
  )
}

export {MasterLayout}
