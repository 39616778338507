import {UsersInit, UsersProvider} from './Users'
import UsersLayout from './UsersLayout'

const UsersWrapper = () => {
  return (
    <UsersProvider>
      <UsersInit>
        <UsersLayout />
      </UsersInit>
    </UsersProvider>
  )
}

export default UsersWrapper
