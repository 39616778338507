import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  Dispatch,
  SetStateAction,
  useRef,
} from 'react'
import {LayoutSplashScreen} from '../../../../general/layout/core'
import {AuthModel, UserModel} from './_models'
import * as authHelper from './AuthHelpers'
import {WithChildren} from '../../../../general/helpers'
import {getUserByToken, logoutUser} from './_requests'
import { parseJwt } from '../../panel/core/helper'

type AuthContextProps = {
  auth: AuthModel | undefined
  saveAuth: (auth: AuthModel | undefined) => void
  allowedPermissions: {[key: string]: {[key: string]: boolean}}
  getAllowedPermissionValue: (keys: string[]) => boolean
  currentUser: UserModel | undefined
  setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>
  logout: (deprecateToken?: boolean) => void
}

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  allowedPermissions: {},
  getAllowedPermissionValue: (keys: string[]) => false,
  currentUser: undefined,
  setCurrentUser: () => {},
  logout: (deprecateToken?: boolean) => {},
}
const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC<WithChildren> = ({children}) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())
  const [currentUser, setCurrentUser] = useState<UserModel | undefined>()
  const [allowedPermissions, setAllowedPermissions] = useState({})
  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth)
    if (auth) {
      authHelper.setAuth(auth)
    } else {
      authHelper.removeAuth()
    }
  }

  const savePermissions = (accessToken: string) => {
    const permissions: any[] = parseJwt(accessToken)?.role?.permissions
    const tempPermissions = permissions.reduce((prev, curr) => {
      const keys = Object.keys(curr)
      let perObj = {}
      curr[keys?.[0]]?.allowed?.forEach((permission) => {
        Object.assign(perObj, {[permission]: true})
      })
      return {...prev, [keys?.[0]]: {...perObj}}
    }, {})
    setAllowedPermissions(tempPermissions)
  }

  /**
   * checks whether the permission is allowed for array of keys
   * @param keys array of nested keys names
   * @returns if permission is allowed for the given nested keys
   */
  const getAllowedPermissionValue = (keys: string[]) => {
    let allowed: any
    let traversePermissions = JSON.parse(JSON.stringify(allowedPermissions))
    keys.forEach((key) => {
      allowed = traversePermissions?.[key]
      if (typeof allowed !== 'boolean') {
        traversePermissions = {...allowed}
      }
    })
    if (typeof allowed === 'boolean') {
      return allowed
    }
    return false
  }

  const logout = async (deprecateToken: boolean = false) => {
    if (deprecateToken) await logoutUser()
    saveAuth(undefined)
    setCurrentUser(undefined)
  }

  useEffect(() => {
    if (auth?.accessToken) {
      savePermissions(auth?.accessToken)
    }
    // eslint-disable-next-line
  }, [auth?.accessToken])

  return (
    <AuthContext.Provider
      value={{
        auth,
        saveAuth,
        allowedPermissions,
        getAllowedPermissionValue,
        currentUser,
        setCurrentUser,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC<WithChildren> = ({children}) => {
  const {auth, logout, setCurrentUser} = useAuth()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
  useEffect(() => {
    const requestUser = async (apiToken: string) => {
      try {
        if (!didRequest.current) {
          const {data} = await getUserByToken(apiToken)
          if (data) {
            setCurrentUser(data)
          }
        }
      } catch (error) {
        console.error(error)
        if (!didRequest.current) {
          logout()
        }
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }

    if (auth && auth.accessToken) {
      requestUser(auth.accessToken)
    } else {
      logout()
      setShowSplashScreen(false)
    }
    // eslint-disable-next-line
  }, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export {AuthProvider, AuthInit, useAuth}
