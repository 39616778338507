export const fieldMapping = {
  transaction: [
    'id',
    'operatorId',
    'originalTransactionId',
    'reversalTransactionId',
    'refundTransactionId',
    'createdAt',
    'completedAt',
    'reversedAt',
    'transactionResult',
    'transactionType',
    'errorCode',
    'errorMessage',
    'supplierErrorCode',
    'supplierErrorMessage',
    'refundReason',
  ],
  service: [
    'transactionProductModel.name',
    'transactionProductModel.supplierPartNumber',
    'transactionProductModel.retailerPartNumber',
    'transactionProductModel.skuCode',
    'transactionProductModel.skuName',
    'transactionProductModel.serialNumber',
    'transactionProductModel.totalAmount',
    'transactionProductModel.currency',
    'transactionProductModel.supplier',
    'transactionProductModel.brand',
    'transactionProductModel.subBrand',
    'transactionProductModel.category',
  ],
  articles: [
    'transactionProductModel.transactionProductSubItems.0.id',
    'transactionProductModel.transactionProductSubItems.0.name',
    'transactionProductModel.transactionProductSubItems.0.supplierPartNumber',
    'transactionProductModel.transactionProductSubItems.0.retailerPartNumber',
    'transactionProductModel.transactionProductSubItems.0.serialNumber',
    'transactionProductModel.transactionProductSubItems.0.amount',
    'transactionProductModel.transactionProductSubItems.0.currency',
    'transactionProductModel.transactionProductSubItems.0.quantity',
    'transactionProductModel.transactionProductSubItems.0.includeInProductPrice',
  ],
  store: [
    'store.refNumber',
    'store.name',
    'store.city',
    'store.postalCode',
    'store.countryName',
    'store.countryCode',
    'store.language',
    'store.storeFormat',
    'store.operatorModel',
  ],
  device: ['device.id', 'device.name', 'device.type', 'device.suspended'],
}
