import React, {useState} from 'react'
import {KTIcon, isNotEmpty} from '../../../../../general/helpers'
import {export_logistics} from '../../core/_request'
import {useLogisticsQueryResponse} from '../core/LogisticsResponseProvider'
import LogisticsFilters from './LogisticsFilters'
import {useParams} from 'react-router-dom'
import {useIntl} from 'react-intl'
import {ListSearch} from '../../components/ListSearch'
import {useLogisticsQueryRequest} from '../core/LogisticsRequestProvider'
import {toast} from 'react-toastify'
import {useAuth} from '../../../auth'
import {PERMISSION_ARRKEY} from '../../../auth/core/constant'

const LogisticsListHeader = () => {
  const [exportLoading, setExportLoading] = useState(false)
  const {query} = useLogisticsQueryResponse()
  const {updateState, state} = useLogisticsQueryRequest()
  const {provider} = useParams()
  const {getAllowedPermissionValue} = useAuth()
  const intl = useIntl()
  /**
   * starts export logistics data
   */
  const triggerStoresExport = async () => {
    const isFiltersEmpty =
      Object.entries(state).find((obj) => {
        return obj[0] !== 'page' && obj[0] !== 'size' && isNotEmpty(obj[1])
      }) === undefined
    if (isFiltersEmpty) {
      toast.error(intl.formatMessage({id: 'FILTER.SELECT'}))
      return
    }
    setExportLoading(true)
    try {
      await export_logistics(provider ?? '', query)
    } catch (e) {
    } finally {
      setExportLoading(false)
    }
  }
  return (
    <div className='card-header border-0 pt-6'>
      <div className='card-toolbar w-100 d-flex flex-wrap justify-content-between gap-2'>
        <div className='d-flex gap-2 flex-wrap'>
          <ListSearch
            placeHolderId='SEARCH.PARCELS'
            updateState={updateState}
            searchKeys={['parcelBarcode.contains']}
          />
        </div>
        <div className='d-flex justify-content-end align-items-center'>
          <LogisticsFilters />
          {getAllowedPermissionValue(PERMISSION_ARRKEY.EXPORT_LOGISTICS) && (
            <button
              disabled={exportLoading}
              type='button'
              className='btn btn-light-primary me-3'
              onClick={triggerStoresExport}
            >
              <KTIcon iconName='exit-up' className='fs-2' />
              {intl.formatMessage({id: 'EXPORT'})}
              {exportLoading && (
                <span className='spinner-border spinner-border-sm align-middle text-primary ms-2' />
              )}
            </button>
          )}
        </div>
      </div>
    </div>
  )
}

export default LogisticsListHeader
