/**
 * Selected Browser storage
 */
export const SELECTED_STORAGE = localStorage

/**
 * ALl the selected storage keys
 */
export const SELECTED_STORAGE_KEYS = {
  AUTH_LOCAL_STORAGE_KEY: 'kt-auth-react-v',
  LAST_ACTIVITY_LOCAL_STORAGE_KEY: 'kt-last-activity-react-v',
  STORES_FILTERS_KEY: 'kt-stores-filters',
  LOGISTICS_FILTERS_KEY: 'kt-logistics-filters',
}

export const PERMISSION_ARRKEY = {
  GET_USERS: ['users', 'GetAllUsers'],
  ADD_USER_BTN: ['users', 'InviteUser'],
  RESEND_INVITE: ['users', 'ResendUserInvite'],
  UPDATE_USER: ['users', 'UpdateUser'],
  DELETE_USER: ['users', 'DeleteUser'],
  GET_ROLES: ['roles', 'GetAllRoles'],
  ADD_ROLE: ['roles', 'WriteRole'],
  UPDATE_ROLE: ['roles', 'UpdateRole'],
  GET_PERMISSIONS: ['permissions', 'GetAllPermissions'],
  UPDATE_PERMISSIONS: ['permissions', 'UpdatePermission'],
  GET_TRANSACTIONS: ['transactions', 'GetAllTransactions'],
  EXPORT_TRANSACTIONS: ['reports', 'ExportTransactions'],
  GET_LOGISTICS: ['logistics', 'GetAllLogistics'],
  EXPORT_LOGISTICS: ['logistics', 'ExportLogistics'],
  LOGISTICS_PICKUP: ['logistics', 'LogisticsPickupAction'],
  LOGISTICS_RETURN: ['logistics', 'LogisticsReturnAction'],
  GET_STORES: ['stores', 'GetAllStores'],
  ADD_STORE_PROPERTY: ['stores', 'CreateStoreProperty'],
  UPDATE_STORE_PROPERTY: ['stores', 'UpdateStoreProperty'],
  DELETE_STORE_PROPERTY: ['stores', 'DeleteStoreProperty'],
  EXPORT_STORES: ['stores', 'ExportStores'],
  ADD_DEVICE: ['stores', 'CreateDevice'],
  UPDATE_DEVICE: ['stores', 'UpdateDevice'],
  DELETE_DEVICE: ['stores', 'DeleteDevice'],
  UPDATE_DEVICE_SECRET: ['stores', 'UpdateDeviceSecret'],
  DELETE_DEVICE_SECRET: ['stores', 'DeleteDeviceSecret'],
  GET_ACTIVITY_LOGS: ['activity-log', 'GetAllActivityLogs'],
  EXPORT_ACTIVITY_LOGS: ['activity-log', 'ExportActivityLogs'],
}
