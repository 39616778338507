import React from 'react'
// import {Store} from '../../../core/_models'
import {FormattedMessage} from 'react-intl'

interface Props {
  store: any
}

const StoreStatusCell = (props: Props) => {
  const {store} = props
  //status is dependent upon enabled key
  return store?.enabled ? (
    <div className='badge badge-light-success fw-bolder'>
      <FormattedMessage id='ENABLED' />
    </div>
  ) : (
    <div className='badge badge-light-error fw-bolder'>
      <FormattedMessage id='SUSPENDED' />
    </div>
  )
}

export default StoreStatusCell
