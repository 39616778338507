import React, {useEffect, useMemo} from 'react'
import Grid from '../components/Grid'
import {KTCard, KTCardBody} from '../../../../general/helpers'
import StoresListHeader from './components/StoresListHeader'
import {storeColumns} from './table/columns/_columns'
import CardLoading from '../components/CardLoading'
import {
  useStoresQueryResponse,
  useStoresQueryResponseData,
  useStoresQueryResponseTotalCount,
} from './core/StoresResponseProvider'
import ListLoading from '../dashboad/components/loading/ListLoading'
import {useStoresQueryRequest} from './core/StoresRequestProvider'
import Pagination from '../components/Pagination'
import {SELECTED_STORAGE_KEYS} from '../../auth/core/constant'
import {useNavigate} from 'react-router-dom'

const StoresLayout = () => {
  const {isLoading} = useStoresQueryResponse()
  const {state, updateState, loading} = useStoresQueryRequest()
  const totalCounts = useStoresQueryResponseTotalCount()
  const storesList = useStoresQueryResponseData()
  const data = useMemo(() => storesList, [storesList])
  const navigate = useNavigate()

  const columns = useMemo(() => {
    return storeColumns(navigate)
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    sessionStorage.removeItem(SELECTED_STORAGE_KEYS.STORES_FILTERS_KEY)
  }, [])
  return (
    <KTCard>
      <StoresListHeader />
      <KTCardBody className='py-4'>
        {loading ? (
          <CardLoading />
        ) : (
          <>
            <Grid columns={columns} data={data} />
            {isLoading ? <ListLoading /> : <></>}
            {totalCounts > 0 ? (
              <Pagination totalItems={totalCounts} state={state} updateState={updateState} />
            ) : (
              <></>
            )}
          </>
        )}
      </KTCardBody>
    </KTCard>
  )
}

export default StoresLayout
