import React, {useEffect, useState} from 'react'
import {Card} from 'react-bootstrap'
import {isRoleAdmin, codeToTitle} from '../../core/helper'
import {Link} from 'react-router-dom'
import {useRoles} from '../core/Roles'
import {Role} from '../../core/_models'
import {useIntl} from 'react-intl'
import {useAuth} from '../../../auth'
import {PERMISSION_ARRKEY} from '../../../auth/core/constant'

interface Props {
  role: Role
  detailView?: boolean
}

const RoleCard = (props: Props) => {
  const {saveItemIdForUpdate, defaultPermissions, defaultPermissionsLoading} = useRoles()
  const {getAllowedPermissionValue} = useAuth()
  const {role, detailView = true} = props
  const [descriptionArr, setDescriptionArr] = useState<Array<string>>([])
  const intl = useIntl()
  const numOfDescriptionPoints = 5
  useEffect(() => {
    if (role && isRoleAdmin(defaultPermissions, role.permissions)) {
      setDescriptionArr(['All Admin Controls'])
    } else if (role && Array.isArray(role.permissions)) {
      setDescriptionArr(
        role?.permissions?.map((item) => {
          const key = Object.keys(item)?.[0]
          return `Perform ${key} operations`
        })
      )
    }
    // eslint-disable-next-line
  }, [role, defaultPermissionsLoading])
  return (
    <Card className='min-h-406px h-100'>
      <Card.Header className='border-0'>
        <div className='card-title'>
          <h2>{codeToTitle(role?.name ?? '')}</h2>
        </div>
      </Card.Header>
      <Card.Body className='pt-1'>
        {detailView && (
          <div className='fw-bold text-gray-600 mb-5'>{`Total users with this role: ${role?.usersAssigned}`}</div>
        )}
        <div className='d-flex flex-column text-gray-600'>
          {descriptionArr.slice(0, numOfDescriptionPoints).map((item) => (
            <div key={item} className='d-flex align-items-center py-2'>
              <span className='bullet bg-primary me-3'></span>
              {item}
            </div>
          ))}
          {descriptionArr.length > numOfDescriptionPoints ? (
            <div className='d-flex align-items-center py-2'>
              <span className='bullet bg-primary me-3'></span>{' '}
              <em>and {descriptionArr.length - numOfDescriptionPoints} more...</em>
            </div>
          ) : (
            <></>
          )}
        </div>
      </Card.Body>
      <Card.Footer className='border-0 pt-0'>
        {detailView && (
          <Link
            type='button'
            className='btn btn-light btn-active-primary my-1 me-2'
            to={`view/${role?._id}`}
            state={{from :'roles'}}
          >
            {intl.formatMessage({id: 'ROLE.VIEW'})}
          </Link>
        )}
        {getAllowedPermissionValue(PERMISSION_ARRKEY.UPDATE_ROLE) && (
          <button
            type='button'
            className='btn btn-light btn-active-light-primary my-1'
            onClick={() => {
              saveItemIdForUpdate(role?._id)
            }}
          >
            {intl.formatMessage({id: 'ROLE.EDIT'})}
          </button>
        )}
      </Card.Footer>
    </Card>
  )
}

export default RoleCard
