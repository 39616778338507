import React, {FC, PropsWithChildren} from 'react'
import {HeaderProps} from 'react-table'
import {useIntl} from 'react-intl'

type Props = {
  className?: string
  title?: string
  titleId?: string
  tableProps: PropsWithChildren<HeaderProps<any>>
}

const CustomHeader: FC<Props> = ({className, title, titleId, tableProps}) => {
  const intl = useIntl()
  return (
    <th {...tableProps.column.getHeaderProps()} className={className}>
      {titleId ? intl.formatMessage({id: titleId}) : title}
    </th>
  )
}

export default CustomHeader
