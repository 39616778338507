import React, {useState} from 'react'
import {useStoresContext} from '../core/Stores'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {useIntl} from 'react-intl'
import {codeToTitle, discardForm} from '../../core/helper'
import Input from '../../../auth/components/Input'
import {isNotEmpty} from '../../../../../general/helpers'
import {useParams} from 'react-router-dom'
import {StoreDevice} from '../../core/_models'
import Select from '../../../auth/components/Select'
import {
  create_device,
  get_store_devicessecret,
  update_store_device,
  update_store_devicesecret,
} from '../../core/_request'

const editDeviceSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  type: Yup.string().required('Select a type'),
  deviceSecret: Yup.string().required('Device Secret is required'),
  suspended: Yup.string().required('Suspended is required'),
})

const EditDevicesForm = ({fetchDevices}) => {
  const {itemDetailsForUpdate, saveItemDetailsForUpdate} = useStoresContext()
  const intl = useIntl()
  const {storeId} = useParams()
  const isEditing = itemDetailsForUpdate?.values !== undefined
  const detailsObj = {...itemDetailsForUpdate?.values} as StoreDevice

  const [propertyForEdit] = useState({
    name: detailsObj?.name,
    type: detailsObj?.type,
    deviceSecret: '',
    suspended: detailsObj?.suspended ? 'Yes' : 'No',
  })

  const formik = useFormik({
    initialValues: propertyForEdit,
    validationSchema: editDeviceSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        const payload = {
          ...values,
          suspended: values.suspended === 'Yes',
          store: storeId ?? '',
        }
        if (isEditing) {
          if (itemDetailsForUpdate?.name === 'deviceSecret') {
            const {id, deviceId} = await get_store_devicessecret(detailsObj?.name)
            const editSecretPayload = {
              id: id?.toString(),
              deviceName: payload?.name,
              deviceSecret: payload?.deviceSecret,
              deviceId: deviceId?.toString(),
              enabled: !payload?.suspended,
            }
            await update_store_devicesecret(editSecretPayload)
          } else {
            const {store, deviceSecret, ...editPayload} = payload
            await update_store_device(detailsObj?.id?.toString(), editPayload)
          }
        } else {
          await create_device(payload)
        }
        cancel(true)
      } catch {
      } finally {
        setSubmitting(false)
      }
    },
    validateOnChange: true,
    validateOnMount: true,
    enableReinitialize: true,
  })

  /**
   * handles discard action for form
   */
  const discardModal = () => {
    if (formik.dirty) {
      discardForm(() => {
        saveItemDetailsForUpdate(null)
        formik.resetForm()
      })
    } else {
      saveItemDetailsForUpdate(null)
    }
  }

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      fetchDevices()
    }
    saveItemDetailsForUpdate(null)
  }

  const typeOptions = ['POS', 'WEBPOS']

  const suspendedOptions = [
    {label: 'Yes', value: true},
    {label: 'No', value: false},
  ]

  return (
    <form
      id='kt_modal_add_role_form'
      className='form'
      noValidate
      onChange={formik.handleChange}
      onSubmit={formik.handleSubmit}
    >
      <div
        className='d-flex flex-column scroll-y me-n7 ps-1 pe-7 mh-430px'
        id='kt_modal_add_role_scroll'
        data-kt-scroll='true'
        data-kt-scroll-activate='{default: false, lg: true}'
        data-kt-scroll-max-height='auto'
        data-kt-scroll-offset='300px'
      >
        {itemDetailsForUpdate?.name !== 'deviceSecret' && (
          <>
            <div className='fv-row mb-10'>
              <Input
                labelId='NAME'
                attrs={formik.getFieldProps('name')}
                errorStrId={formik.errors.name}
                disabled={formik.isSubmitting}
                placeHolderId='PLACEHOLDER.DEVICE.NAME'
                onChange={(value, e) => formik.handleChange(e)}
                isInvalid={isNotEmpty(formik.touched.name) && isNotEmpty(formik.errors.name)}
                isValid={isNotEmpty(formik.touched.name) && !formik.errors.name}
                required
              />
            </div>
            <div className='fv-row mb-10'>
              <Select
                labelId='TYPE'
                attrs={formik.getFieldProps('type')}
                errorStrId={formik.errors.type}
                disabled={formik.isSubmitting}
                placeHolderId='PLACEHOLDER.TYPE'
                onChange={(e) => formik.handleChange(e)}
                isInvalid={isNotEmpty(formik.touched.type) && isNotEmpty(formik.errors.type)}
                isValid={isNotEmpty(formik.touched.type) && !formik.errors.type}
                options={typeOptions.map((option) => ({label: option, value: option}))}
                required
              />
            </div>
            <div className='mb-7'>
              <label className='required fw-bold fs-6 mb-5'>
                {intl.formatMessage({id: 'SUSPENDED'})}
              </label>
              {suspendedOptions.map((item, ind) => (
                <div key={item.label}>
                  <div className='d-flex fv-row mb-2'>
                    <div className='form-check form-check-custom form-check-solid'>
                      <input
                        className='form-check-input me-3'
                        {...formik.getFieldProps('suspended')}
                        name='suspended'
                        type='radio'
                        value={item.label}
                        checked={formik.values.suspended === item.label}
                        disabled={formik.isSubmitting}
                        onChange={formik.handleChange}
                      />
                      <label
                        className='form-check-label'
                        htmlFor={`kt_modal_update_role_option_${item}`}
                      >
                        <div className='fw-bold text-gray-800'>{codeToTitle(item.label)}</div>
                      </label>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
        {(!isEditing || itemDetailsForUpdate?.name === 'deviceSecret') && (
          <div className='fv-row mb-10'>
            <Input
              labelId='DEVICE.SECRET'
              attrs={formik.getFieldProps('deviceSecret')}
              errorStrId={formik.errors.deviceSecret}
              disabled={formik.isSubmitting}
              placeHolder={intl.formatMessage(
                {id: 'PLACEHOLDER.DEFAULT'},
                {placeholder: 'device secret'}
              )}
              onChange={(value, e) => formik.handleChange(e)}
              isInvalid={
                isNotEmpty(formik.touched.deviceSecret) && isNotEmpty(formik.errors.deviceSecret)
              }
              isValid={isNotEmpty(formik.touched.deviceSecret) && !formik.errors.deviceSecret}
              required
            />
          </div>
        )}
      </div>
      <div className='text-center pt-15'>
        <button
          type='button'
          className='btn btn-light me-3'
          data-kt-roles-modal-action='cancel'
          onClick={discardModal}
        >
          {intl.formatMessage({id: 'DISCARD'})}
        </button>
        <button
          type='submit'
          className='btn btn-primary'
          data-kt-roles-modal-action='submit'
          disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}
        >
          {formik.isSubmitting ? (
            <span>
              {intl.formatMessage({id: 'PLEASE.WAIT'})}{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          ) : (
            <span className='indicator-label'>{intl.formatMessage({id: 'SUBMIT'})}</span>
          )}
        </button>
      </div>
    </form>
  )
}

export default EditDevicesForm
