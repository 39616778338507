import {useMemo} from 'react'
import {useTable} from 'react-table'
import {CustomHeaderColumn} from './columns/CustomHeaderColumn'
import {CustomRow} from './columns/CustomRow'
import {useUsers} from '../Users'
import {useIntl} from 'react-intl'
import {useLocation} from 'react-router-dom'
import {getUserColumns} from './columns/_columns'
import {useAuth} from '../../../auth'
import {PERMISSION_ARRKEY} from '../../../auth/core/constant'

interface Props {
  fetchUsers: () => void
}

const UsersTable = (props: Props) => {
  const {fetchUsers} = props
  const {state} = useLocation()
  const {usersList, usersLoading} = useUsers()
  const {getAllowedPermissionValue} = useAuth()
  const columns = useMemo(() => {
    let usersColumns = getUserColumns(fetchUsers)
    if (
      !getAllowedPermissionValue(PERMISSION_ARRKEY.UPDATE_USER) &&
      !getAllowedPermissionValue(PERMISSION_ARRKEY.DELETE_USER) &&
      !getAllowedPermissionValue(PERMISSION_ARRKEY.RESEND_INVITE)
    ) {
      usersColumns = usersColumns.filter((col) => col.id !== 'actions')
    }
    const locationState=state as any
    if (locationState?.from === 'roles') return usersColumns.filter((col) => col.id !== 'role')
    return usersColumns
    //eslint-disable-next-line
  }, [state])

  const data = useMemo(() => usersList, [usersList])
  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  })
  const intl = useIntl()

  return (
    <>
      <div className='table-responsive'>
        <table
          id='kt_table_users'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
          {...getTableProps()}
        >
          <thead>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
              {headers.map((column) => (
                <CustomHeaderColumn key={column.id} column={column} />
              ))}
            </tr>
          </thead>
          <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
            {rows.length > 0 && !usersLoading ? (
              rows.map((row, i) => {
                prepareRow(row)
                return <CustomRow row={row} key={`row-${i}-${row.id}`} />
              })
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    {intl.formatMessage({id: 'RESULT.EMPTY'})}
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  )
}

export {UsersTable}
