import Dashboard from '../modules/panel/dashboad'
import TransactionDetails from '../modules/panel/dashboad/TransactionDetails'
import RolesWrapper from '../modules/panel/roles/RolesWrapper'
import PermissionsPage from '../modules/panel/permissions/PermissionsPage'
import UsersPage from '../modules/panel/users/UsersPage'
import {Outlet} from 'react-router-dom'
import ViewRoleWrapper from '../modules/panel/roles/ViewRoleWrapper'
import {IntlShape} from 'react-intl'
import StoresPage from '../modules/panel/stores/StoresPage'
import ViewStoreWrapper from '../modules/panel/stores/ViewStoreWrapper'
import LogisticsPage from '../modules/panel/logistics/LogisticsPage'
import ViewLogistics from '../modules/panel/logistics/ViewLogistics'
import ActivitiesPage from '../modules/panel/activities/ActivitiesPage'

export const getRoutes = (intl: IntlShape) => [
  {
    path: 'transactions',
    element: <Outlet />,
    children: [
      {
        index: true,
        title: intl.formatMessage({id: 'FILTERS'}),
        element: <Dashboard />,
      },
      {
        path: ':id/details',
        title: intl.formatMessage({id: 'TRANSACTION.DETAILS'}),
        element: <TransactionDetails />,
      },
    ],
  },
  {
    path: 'logistics/:provider',
    element: <Outlet />,
    children: [
      {
        index: true,
        title: intl.formatMessage({id: 'PARCEL.LIST'}),
        element: <LogisticsPage />,
      },
      {
        path: 'view/:logisticId',
        title: intl.formatMessage({id: 'PARCEL.DETAILS'}),
        element: <ViewLogistics />,
      },
    ],
  },
  {
    path: 'users-management',
    element: <Outlet />,
    children: [
      {
        path: 'users',
        title: intl.formatMessage({id: 'USERS.LIST'}),
        element: <UsersPage />,
      },
      {
        path: 'roles',
        title: intl.formatMessage({id: 'ROLES.LIST'}),
        element: <RolesWrapper />,
      },
      {
        path: 'roles/view/:roleId',
        title: intl.formatMessage({id: 'ROLE.DETAILS'}),
        element: <ViewRoleWrapper />,
      },
      {
        path: 'permissions',
        title: intl.formatMessage({id: 'PERMISSIONS.LIST'}),
        children: [],
        element: <PermissionsPage />,
      },
      {
        path: 'activities',
        title: intl.formatMessage({id: 'PERMISSIONS.LIST'}),
        children: [],
        element: <ActivitiesPage />,
      },
    ],
  },
  {
    path: 'stores-management',
    element: <Outlet />,
    children: [
      {
        index: true,
        title: intl.formatMessage({id: 'STORES.LIST'}),
        element: <StoresPage />,
      },
      {
        path: 'view/:storeId',
        title: intl.formatMessage({id: 'STORE.DETAILS'}),
        element: <ViewStoreWrapper />,
      },
    ],
  },
]
