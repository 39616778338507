import React from 'react'
import {ActivitiesQueryRequestProvider} from './core/ActivitiesRequestProvider'
import {ActivitiesQueryResponseProvider} from './core/ActivitiesResponseProvider'
import ActivitiesLayout from './ActivitiesLayout'

const ActivitiesWrapper = () => {
  return (
    <ActivitiesQueryRequestProvider>
      <ActivitiesQueryResponseProvider>
        <ActivitiesLayout />
      </ActivitiesQueryResponseProvider>
    </ActivitiesQueryRequestProvider>
  )
}

export default ActivitiesWrapper
