import {toAbsoluteUrl} from '../../../../general/helpers'
import {useNavigate} from 'react-router-dom'

function TokenExpire() {
  const navigate = useNavigate()
  return (
    <div
      className='d-flex flex-column flex-root'
      style={{backgroundImage: `url(${toAbsoluteUrl('/media/auth/bg3.jpg')})`}}
    >
      <div className='d-flex flex-column flex-center flex-column-fluid'>
        <div className='d-flex flex-column flex-center text-center p-10'>
          <div className='card card-flush w-lg-650px py-5'>
            <div className='card-body py-15 py-lg-20'>
              <div className='mb-14'>
                <img alt='Logo' src={toAbsoluteUrl('/media/logos/logo.png')} className='h-40px' />
              </div>
              <h1 className='fw-bolder text-gray-900 mb-5'>The link has expired</h1>
              <div className='fw-semibold fs-6 text-gray-500 mb-8'>
                We apologize for the inconvenience. The link you tried to access has expired.
                <br />
                Please try generating a new link again. Thank you for your patience.
              </div>
              <div className='mb-11'>
                <button
                  type='submit'
                  className='btn btn-primary'
                  id='go_to_signin'
                  onClick={() => navigate('/auth')}
                >
                  Sign In
                </button>
              </div>
              <div className='mb-0'>
                <img
                  src={toAbsoluteUrl('/media/auth/membership.png')}
                  className='mw-100 mh-300px theme-light-show'
                  alt=''
                />
                <img
                  src={toAbsoluteUrl('/media/auth/membership-dark.png')}
                  className='mw-100 mh-300px theme-dark-show'
                  alt=''
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TokenExpire
