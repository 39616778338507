import React, {useEffect, useMemo} from 'react'
import {logisticsColumns} from './table/columns/_columns'
import {KTCard, KTCardBody} from '../../../../general/helpers'
import CardLoading from '../components/CardLoading'
import Grid from '../components/Grid'
import ListLoading from '../dashboad/components/loading/ListLoading'
import Pagination from '../components/Pagination'
import LogisticsListHeader from './components/LogisticsListHeader'
import { useLogisticsQueryResponse,useLogisticsQueryResponseTotalCount,useLogisticsQueryResponseData } from './core/LogisticsResponseProvider'
import { useLogisticsQueryRequest } from './core/LogisticsRequestProvider'
import { SELECTED_STORAGE_KEYS } from '../../auth/core/constant'

const LogisticsLayout = () => {
  const {isLoading} = useLogisticsQueryResponse()
  const {state, updateState, loading} = useLogisticsQueryRequest()
  const totalCounts = useLogisticsQueryResponseTotalCount()
  const logisticsList = useLogisticsQueryResponseData()
  const data = useMemo(() => logisticsList, [logisticsList])

  const columns = useMemo(() => logisticsColumns, [])

  useEffect(()=>{
    sessionStorage.removeItem(SELECTED_STORAGE_KEYS.LOGISTICS_FILTERS_KEY)
  },[])

  return (
    <KTCard>
      <LogisticsListHeader />
      <KTCardBody className='py-4'>
        {loading ? (
          <CardLoading />
        ) : (
          <>
            <Grid columns={columns} data={data} />
            {isLoading ? <ListLoading /> : <></>}
            {totalCounts > 0 ? (
              <Pagination totalItems={totalCounts} state={state} updateState={updateState} />
            ) : (
              <></>
            )}
          </>
        )}
      </KTCardBody>
    </KTCard>
  )
}

export default LogisticsLayout
