import React from 'react'
import ViewStore from './ViewStore'
import {StoresContextProvider} from './core/Stores'

const ViewStoreWrapper = () => {
  return (
    <StoresContextProvider>
      <ViewStore />
    </StoresContextProvider>
  )
}

export default ViewStoreWrapper
